/* eslint-disable no-unused-vars */
import axios, { AxiosError } from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { store } from '../store';
import { logoutAuth } from '../store/modules/user/actions';

// publication
/*
    https://development.puzliapi.dotcoding.com.br
    https://staging.puzliapi.dotcoding.com.br
    https://beta.api.puzli.com.br
    https://api.puzli.com.br
*/
let baseURL = 'https://development.puzliapi.dotcoding.com.br';
if (Platform.OS === 'web') {
    const isDevelopment = window.location.hostname.includes('localhost') || window.location.hostname.includes('development') ? 'development' : null;
    const isStaging = window.location.hostname.includes('staging') ? 'staging' : null;
    const environment = isDevelopment || isStaging;
    const databaseDotcoding = `https://${environment}.puzliapi.dotcoding.com.br`;
    const databasePuzli = 'https://api.puzli.com.br';
    baseURL = environment ? databaseDotcoding : databasePuzli;
}

export const api = axios.create({
    baseURL,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            AsyncStorage.multiGet(['@PuzliApp:session', '@PuzliApp:refresh_token', '@PuzliApp:type']).then((data) => {
                const refresh = JSON.parse(String(data[1][1]));
                const type = JSON.parse(String(data[2][1]));
                const session = JSON.parse(String(data[0][1]));

                axios.post(`${baseURL}/clients/refreshToken`, {
                    refresh,
                    type,
                    session,
                }).then((response) => {
                    AsyncStorage.setItem('@PuzliApp:client', JSON.stringify(response.data.result.client));
                    AsyncStorage.setItem('@PuzliApp:type', JSON.stringify(type));
                    AsyncStorage.setItem('@PuzliApp:token', JSON.stringify(response.data.result.token));
                    AsyncStorage.setItem('@PuzliApp:refresh_token', JSON.stringify(response.data.result.refreshToken));
                    AsyncStorage.setItem('@PuzliApp:session', JSON.stringify(response.data.result.client.session));
                    api.defaults.headers['x-access-token'] = response.data.result.token;
                }).catch(() => {
                    // @ts-ignore
                    store.dispatch(logoutAuth());
                });
            });
        } else {
            return error.response;
        }
        return error;
    },
);
