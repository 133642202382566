import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import Modal from 'react-native-modal';
import { Feather, Ionicons, SimpleLineIcons } from '@expo/vector-icons/';

import { Platform, StatusBar, Image, View, Dimensions } from 'react-native';
import { BlurView } from 'expo-blur';
import { ScrollView } from 'react-native-gesture-handler';
import { defaultTheme as theme } from '../../../styles/theme';

const windowWidth = Dimensions.get('window').width;
interface BackContainer {
    top: number;
}

const isWeb = Platform.OS === 'web';

export const Main = styled.View`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Container = styled(isWeb && windowWidth > 650 ? ScrollView : View)`
    padding: 0px;
    flex: 1;
`;

export const ModalContainer = styled.View`
    align-self: center;
    background-color: transparent;
    height: 70%;
    padding: 20px;
    align-items: center;
    max-width: ${Platform.OS === 'web' ? '300px' : '80%'};
    justify-content: center;
`;

export const ContentModal = styled.View`
    background-color: #fff;
    max-height: 450px;
    min-height: 450px;
    border-radius: 4px ;
    padding: 20px;
    max-width: 400px;
    min-width: 400;
    width: 100%;
    position: absolute;
`;

export const ModalArea = styled(Modal)`
    align-items: center;
    justify-content: center;
`;

export const TouchableOpacity = styled.TouchableOpacity`
    height: 50px;
    border-radius: 10px;
    margin-top: 8px;
    background-color: ${theme.colors.gray};
    justify-content: center;
    align-items: center;
`;

export const Text = styled.Text`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.white};
    font-family: Montserrat_400Regular;
`;

export const TextInfo = styled.Text`
    margin-top: 4px;
    font-size: ${theme.font.sizes.xxsmall};
    color: ${theme.colors.white};
    font-family: Montserrat_400Regular;
`;

export const TextSelect = styled.Text`
    margin-top: 4px;
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.gray};
    font-family: Montserrat_400Regular;
`;

export const ContainerButton = styled.View`
    margin-top: ${isWeb ? '30px' : '40px'};
    margin-bottom: ${isWeb && windowWidth > 470 ? '0' : '50px'};
    ${isWeb && 'width: 324px; align-self:center'}
`;

export const Content = styled.ScrollView`
    padding: ${windowWidth > 650 ? '0px' : '25px 25px'};
    width: ${windowWidth < 1004 ? '100%' : '1004px'};
    align-self: center;
`;

export const EventBoxTitle = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    ${windowWidth > 650
    && `background-color: #fff;
    padding: 26px 30px;
    border-radius: 10px;
    border: solid 1px ${theme.colors.grayLight}`}
`;

export const ContentEvent = styled.View`
    margin-bottom: 40px;
    ${windowWidth > 650
    && `background-color: #fff;
    padding: 60px 30px;
    border-radius: 10px;
    border: solid 1px ${theme.colors.grayLight}`};
`;

export const ContentDescription = styled.View`
    margin-bottom: 40px;
    ${isWeb && windowWidth > 650 && 'align-items: center'}
`;

export const ContentLink = styled.View`
    margin-bottom: 40px;
    ${isWeb && windowWidth > 650 && 'align-items: center'}
`;

export const ContentInvite = styled.View`
    /* margin-bottom: ${isWeb ? '40px' : '100px'}; */
    ${isWeb && windowWidth > 650 && 'align-items: center'}
`;

export const BoxHeadingTitle = styled.View`
    max-width: 70%;
`;

export const EventBoxButtons = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const ButtonUser = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    margin-left: 14px;
    margin-right: 8px;
    /* margin-right: 8px; */
`;

export const ButtonUserIcon = styled(Image)`
    height: 16px;
    width: 16px;
`;

export const ButtonOptions = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
`;

export const ButtonOptionsIcon = styled(SimpleLineIcons)`
    font-size: 16px;
    color: ${theme.colors.grayLight};
`;

export const BoxContainer = styled.ScrollView`
    margin-top: -50px;
    background-color: ${isWeb && windowWidth > 650
        ? 'transparent'
        : `${theme.colors.white}`};
    /* z-index: ${theme.layers.overlay2}; */
    border-radius: ${isWeb && windowWidth > 650 ? '0px' : '20px'};
`;

export const TopContainer = styled.View`
    flex-direction: row;
    justify-content: space-between;
`;

export const PhotoContainer = styled.ImageBackground`
    z-index: ${theme.layers.base};
    width: 100%;
    ${isWeb && windowWidth > 650 && 'height: 576px; width:100%'};
`;

export const PhotoContainerBlur = styled(BlurView)`
    ${isWeb
    && windowWidth > 650
    && 'height: 576px; width:100%; padding: 3.125rem 0px 5.437rem;position: absolute; '};
    overflow: hidden;
`;

export const Photo = styled.Image`
    width: 100%;
    height: ${isWeb && windowWidth > 650 ? '27.393rem' : `${RFValue(270)}px`};
    ${isWeb
    && windowWidth > 650
    && 'border-radius: 20px; max-width: 62.75rem; align-self: center'};
`;

export const BackContainer = styled.TouchableOpacity<BackContainer>`
    position: absolute;
    top: ${({ top }) => (isWeb ? '16px' : `${top}px`)};
    left: 16px;
    width: 45px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    display: flex;
`;

export const BlurGoBackButton = styled(BlurView)`
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 40px;
    overflow: hidden;
    border-radius: 4px;
`;

export const BackIcon = styled(Ionicons)`
    color: ${theme.colors.black};
    font-size: 26px;
`;

export const StarIcon = styled(Ionicons)`
    color: ${theme.colors.yellow};
    font-size: 25px;
`;

export const ContainerOptions = styled.TouchableOpacity`
    width: 34px;
    height: 28px;
    border-radius: 5px;
    border: 2px solid #b2bec3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
`;

export const OptionsIcon = styled(SimpleLineIcons)`
    color: #b2bec3;
    font-size: 18px;
`;

export const BoxField = styled.View`
    width: 100%;
    background-color: ${theme.colors.lightGray};
    box-shadow: 0px 0px 5px #00000029;
    border-radius: 10px;
    padding: 15px;
    margin-top: ${theme.spacings.small};
`;

export const BoxDate = styled.View`
    flex-direction: row;
    margin-bottom: 40px;
`;

export const TextDate = styled.Text`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.gray};
    font-family: Montserrat_400Regular;
    margin-top: 8px;
`;

export const TextHours = styled.Text`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.gray};
    font-family: Montserrat_400Regular;
    margin-top: 8px;
    margin-left: 20px;
`;

export const TextField = styled.Text`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.gray};
    font-family: Montserrat_400Regular;
    margin-top: ${isWeb && windowWidth > 650 ? '24px' : '15px'};
    text-align: justify;
`;

export const BottomImage = styled.View`
    width: 100%;
`;

export const InvitePhotoContainer = styled.View``;

export const InvitePhoto = styled.Image`
    height: ${isWeb && windowWidth > 650 ? '30.625rem' : `${RFValue(350)}px`};
    width: ${isWeb && windowWidth > 650 ? '22.5rem' : `${RFValue(250)}px`};
    border-radius: ${isWeb && windowWidth > 650 ? '1px' : '10px'};
    align-self: center;
    margin-top: 40px;
`;

export const ButtonChart = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
`;

export const ChartIcon = styled(Feather)`
    font-size: 18px;
    /* margin-right: 12px; */
    color: ${theme.colors.purpleDark};
`;
