import React from 'react';
import * as S from './styles';

interface ListEmptyProps {
  message: string
}

export function ListEmpty({ message }:ListEmptyProps) {
    return (
        <S.Container>
            <S.Text>{message}</S.Text>
        </S.Container>
    );
}
