/* eslint-disable no-nested-ternary */
import { FlatList, Platform, Dimensions, StatusBar } from 'react-native';
import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';

import { defaultTheme as theme } from '../../../styles/theme';
import { HostProps } from './components/CardHost';

const windowWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === 'web';

export const Safe = styled.SafeAreaView`
    flex: 1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Header = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;
`;

export const Container = styled.View`
    padding: ${isWeb && windowWidth > 650 ? '4.875rem 8.265rem 1rem 8.265rem' : '40px 0px 10px 37px'} ;
    flex:1;
`;

export const HostList = styled(FlatList as new () => FlatList<HostProps>).attrs(
    Platform.OS === 'web' && windowWidth > 650 ? {
        showsVerticalScrollIndicator: false,
        numColumns: 2,
        contentContainerStyle: {
            paddingRight: RFValue(37),
        },
        columnWrapperStyle: {
            flex: 1,
            justifyContent: 'space-between',
        },
    } : {
        showsVerticalScrollIndicator: false,
        numColumns: 1,
        contentContainerStyle: {
            paddingLeft: RFValue(-37),
            paddingRight: RFValue(37),
        },
    },
)``;

export const AddButton = styled.TouchableOpacity`
    position: absolute;
    right: ${isWeb && windowWidth > 650 ? '140px' : '37px'};
    bottom: ${isWeb && windowWidth > 650 ? '62px' : '20px'};
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: ${theme.colors.purpleDark};
    display: flex;
    justify-content: center;
    align-items: center;
    elevation: 3;
`;

export const Title = styled.Text`
    font-size: ${RFValue(20)}px;
    font-family: ${theme.font.bold};
    color: ${theme.colors.purpleDark};
    margin-bottom:${RFValue(26)}px;
    margin-top: ${Platform.OS === 'ios' ? `${RFValue(10)}px` : Platform.OS === 'android' ? `${RFValue(20)}px` : `${RFValue(5)}px`}
`;
