import React from 'react';

import * as S from './styles';

interface ButtonFavorite {
    star?: boolean,
    size?: number,
    onPress?: () => void
}

export function ButtonFavorite({ star, size, ...rest }: ButtonFavorite) {
    return (
        <S.ButtonStar {...rest}>
            <S.Icon
                name={star ? 'star' : 'staro'}
                size={size}
                color={star ? '#ED8A19' : '#B2BEC3'}
                star={star}
            />
        </S.ButtonStar>
    );
}
