import React from 'react';
import { ActivityIndicator } from 'react-native';
import { useFonts } from 'expo-font';
import * as S from './styles';

export type ButtonProps = {
    disabled?: boolean;
    title?: string;
    onClick?: () => void;
    loading?: boolean
} & React.ComponentProps<'button'>;

export function Button({
    title,
    disabled = false,
    onClick,
    loading,
}: ButtonProps) {
    const [fontsLoaded] = useFonts({
        'Montserrat-Regular': require('../../../assets/fonts/Montserrat-Regular.ttf'),
        'Montserrat-Bold': require('../../../assets/fonts/Montserrat-Bold.ttf'),
    });

    if (!fontsLoaded) {
        return null;
    }

    return (
        <S.Wrapper
            disabled={disabled}
            onPress={onClick}
            opacity={disabled ? 0.5 : 1}
        >
            {loading ? <ActivityIndicator color='#fff' size='small' /> : <S.Text>{title}</S.Text> }
        </S.Wrapper>
    );
}
