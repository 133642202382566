import { TouchableOpacityProps } from 'react-native';
import styled from 'styled-components/native';
import { defaultTheme as theme } from '../../styles/theme';

type WrapperProps = {
    opacity?: number;
} & TouchableOpacityProps;

export const Wrapper = styled.TouchableOpacity<WrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: 'Montserrat-Regular';
    padding: ${theme.spacings.small};
    border-radius: ${theme.spacings.xxsmall};
    background-color: ${theme.colors.purple};
    opacity: ${({ opacity }) => opacity || 1};
`;

export const Text = styled.Text`
    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.small};
`;
