import styled from 'styled-components/native';
import { Platform, StatusBar, Dimensions } from 'react-native';
import { MaterialCommunityIcons, AntDesign, Feather } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';

import { defaultTheme as theme } from '../../../styles/theme';

const windowWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === 'web';

interface IconCanProps {
    withImage: boolean
}

interface ButtonPickerProps {
    errors?: boolean
}

export type ContainerButtonProps = {
    isFinal?: boolean
}

interface FilterProps {
    selected: boolean
}

export const Safe = styled.SafeAreaView`
    flex:1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Scroll = styled.ScrollView`
    margin-bottom: 20px;
`;

export const Header = styled.View`
    padding: 18px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #00000005;
`;

export const ButtonGoback = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
`;

export const ButtonGobackIcon = styled(MaterialCommunityIcons)`
    font-size: ${isWeb && windowWidth > 650 ? '0.9375rem' : `${RFValue(25)}px`};
    color: ${theme.colors.gray};
`;

export const NamePage = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : `${RFValue(12)}px`};
    margin-left: 12px;
    font-family: ${theme.font.regular};
    color: ${theme.colors.gray};
`;

export const ButtonSave = styled.TouchableOpacity``;

export const ButtonSaveText = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : `${RFValue(12)}px`};
    margin-left: 12px;
    font-family: ${theme.font.regular};
    color: ${theme.colors.purpleDark};
`;

export const ButtomImageHost = styled.TouchableOpacity`
    height: ${isWeb && windowWidth > 650 ? '16.875rem' : `${RFValue(140)}px`};
    width: ${isWeb && windowWidth > 650 ? '23.437rem' : `${RFValue(140)}px`};
    margin-top: 31px;
    align-self: center;
`;

export const IconCan = styled(Feather)<IconCanProps>`
    font-size: 18px;
    color: ${({ withImage }) => (withImage ? theme.colors.grayLight : theme.colors.black)};
    position: absolute;
    bottom: 12px;
    right: 12px;
`;

export const ImageHost = styled.Image`
    height: 100%;
    width: 100%;
    border-radius: 5px;
`;

export const Form = styled.View`
    margin-top: ${RFValue(28)}px;
    padding: 0px ${RFValue(20)}px;
    width: ${isWeb && windowWidth > 650 ? '30.875rem' : '100%'};
    align-self: center;
`;

export const ButtonPicker = styled.View<ButtonPickerProps>`
    border: 1px solid ${({ errors }) => (errors ? theme.colors.red : theme.colors.grayLight)};
    border-radius: 4px;
    margin-top: 12px;
`;

export const ButtonPickerHeader = styled.TouchableOpacity`
    padding: 17px 12px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

export const ButtonPickerIcon = styled(AntDesign)`
    font-size: 16px;
    color: ${theme.colors.grayLight};
`;

export const ButtonPickerText = styled.Text`
    font-family: ${theme.font.bold};
    color: ${theme.colors.gray};
    font-size: 12px;
`;

export const EventsSelectedBox = styled.ScrollView.attrs(
    {
        contentContainerStyle: {
            flexWrap: 'wrap',
            flexDirection: 'row',
            paddingHorizontal: 10,
        },
    },
)`
    max-height: 100px;
`;

export const ButtonPhotoImg = styled.Image`
    height: 100%;
    width: 100%;
    border-radius: 5px;
`;

export const ButtomPhoto = styled.TouchableOpacity`
    height: ${isWeb ? '203px' : `${RFValue(150)}px`};
    width: ${isWeb && windowWidth > 477 ? '409px' : '100%'};
    margin-top: 50px;
    align-self: center;
`;
