import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { HeadingProps } from './index';
import { defaultTheme as theme } from '../../styles/theme';


const isWeb = Platform.OS === 'web';

export const Wrapper = styled.Text<HeadingProps>`
    ${({ color, size = 'medium', marginBottom, marginTop, marginRight }) => css`
        color: ${theme.colors[color || 'black']};
        font-family: 'Montserrat-Bold';
        margin-bottom: ${marginBottom}px;
        margin-top: ${marginTop}px;
        margin-right: ${marginRight}px;
        display: flex;
        ${isWeb ? `font-size: ${theme.font.sizes[size]}` : `font-size: ${theme.font.sizes[size]}`}
    `}
`;
