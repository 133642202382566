import React, { useEffect, useState } from 'react';
import { Platform, Dimensions } from 'react-native';
import * as Linking from 'expo-linking';
import * as Progress from 'react-native-progress';
import * as ImagePicker from 'expo-image-picker';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as S from './styles';
import { Heading } from '../../../components/Heading';
import { Header } from '../../../components/Header';
import { Button } from '../../../components/Button';
import { uploadFile } from '../../../services/toolsApi';
import { defaultTheme as theme } from '../../../styles/theme';
import userPhoto from '../../../assets/images/user/user-photo.png';
import { registerGuest } from '../../../services/events';
import { LoadingPhoto } from '../../../components/LoadingPhoto';


const isWeb = Platform.OS === 'web';

export function FormPhotoIndividualScreen({ navigation, route }) {
    const [progress, setProgress] = useState(0.5);
    const [isVacination, setIsVacination] = useState(false);
    const [openModalNotification, setOpenModalNotification] = useState(false);
    const [titleModalNotification, setTitleModalNotification] = useState('');
    const [messageModalNotification, setMessageModalNotification] = useState('');
    const [guetsPhoto, setGuestPhoto] = useState(null);
    const [guetsPhotoUrl, setGuestPhotoUrl] = useState(null);
    const [guetsPhotoLoading, setGuestPhotoLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const windowWidth = Dimensions.get('window').width;

    const handleOpenModalNotification = () => {
        setOpenModalNotification(!openModalNotification);
    };

    const { dataInvite } = route.params;


    const handleNavigationForm = () => {
        Linking.openURL('http://confirma.puzli.com.br/ok');
    };

    async function handleContinue() {
        if (isVacination) {
            navigation.navigate('FormVaccineCard', { ...dataInvite, guetsPhotoUrl });
        } else {
            setLoading(true);
            const { hash } = dataInvite.event;
            const data = {
                ...dataInvite,
                photo: guetsPhotoUrl,
            };
            delete data.event;
            try {
                const response = await registerGuest([data], hash);
                if (response.status === 200) {
                    setLoading(false);
                    setTitleModalNotification('Convidado cadastrado!');
                    setMessageModalNotification('Aguarde, pois em breve você receberá o seu QR Code individual no whatsapp cadastrado. Obrigado!');
                    handleOpenModalNotification();
                } else {
                    setLoading(false);
                    setTitleModalNotification('Erro ao cadastrar!');
                    setMessageModalNotification(response.message);
                    handleOpenModalNotification();
                }

            } catch (e) {
                setLoading(false);
                setTitleModalNotification('Erro ao cadastrar!');
                setMessageModalNotification(e.message);
                handleOpenModalNotification();
                return e;
            }
        }
        return false;
    }


    useEffect(() => {
        setIsVacination(dataInvite.event?.is_vaccination);
        dataInvite.event?.is_vaccination ? setProgress(0.6) : setProgress(1);
    });

    useEffect(() => {
        navigation.setOptions({
            title: 'Foto do convidado',
        });
    }, [navigation]);

    const goBack = () => {
        navigation.navigate('FormScreen', { event: dataInvite.event.hash });
    };

    const pickImageHostImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
            base64: true,
        });

        if (!result.cancelled) {
            setGuestPhoto(result.uri);
            setGuestPhotoLoading(true);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                setGuestPhotoUrl(response.result);
                setGuestPhotoLoading(false);
                return true;
            }
            return false;

        }
        return '';
    };

    return (
        <>
            <S.Safe>
                <S.Container>
                    <Header title={`Formulário - ${dataInvite.event?.name}`} navigation={navigation} marginBottom={16} handleGoback={goBack} />
                    <S.ProgressContainer>
                        <Progress.Bar progress={progress} color={theme.colors.purpleDark} height={6} borderRadius={10} width={null} />
                    </S.ProgressContainer>
                    <S.Box>
                        <S.Content>
                            <S.BoxPhoto>
                                <Heading color='gray' size='intermediate'>Foto de perfil</Heading>
                                <S.ButtomPhoto disabled={guetsPhotoLoading} onPress={pickImageHostImage}>
                                    <S.ButtonPhotoImg source={guetsPhoto ? { uri: guetsPhoto } : userPhoto} />
                                    <S.IconCan name='camera' />
                                    {guetsPhotoLoading && <LoadingPhoto />}
                                </S.ButtomPhoto>
                                <S.ButtonLabel>Insira uma foto</S.ButtonLabel>
                            </S.BoxPhoto>
                            {isWeb && windowWidth > 470 && (
                                <S.ContainerButton>
                                    <Button disabled={guetsPhoto === null} onClick={handleContinue} title={isVacination ? 'CONTINUAR' : 'FINALIZAR'} />
                                </S.ContainerButton>
                            )}
                        </S.Content>
                    </S.Box>
                </S.Container>
                {isWeb && windowWidth < 470 && (
                    <S.ContainerButton>
                        <Button loading={loading} disabled={guetsPhotoUrl === null} onClick={handleContinue} title={isVacination ? 'CONTINUAR' : 'FINALIZAR'} />
                    </S.ContainerButton>
                )}
            </S.Safe>
            <AwesomeAlert
                show={openModalNotification}
                title={titleModalNotification}
                message={messageModalNotification}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='OK'
                confirmButtonColor='#98C828'
                onConfirmPressed={handleNavigationForm}
            />
        </>
    );
}
