import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import * as Progress from 'react-native-progress';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as Linking from 'expo-linking';

import { Header } from '../../../components/Header';
import { Loading } from '../../../components/Loading';
import { FormIndividual } from './components/FormIndividual';
import { FormFamily } from './components/FormFamily';
import { ButtonSelected } from './components/ButtonSelected';
import { ListEmpty } from '../../../components/ListEmpty';
import { getEvent } from '../../../services/events';

import * as S from './style';
import { defaultTheme as theme } from '../../../styles/theme';

const isWeb = Platform.OS === 'web';

export function FormScreen({ route, navigation }) {
    const [progress, setProgress] = useState(0);
    const [option, setOption] = useState('individual');
    const [eventData, setEventData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [messageModalNotification, setMessageModalNotification] = useState('');
    const [titleModalNotification, setTitleModalNotification] = useState('');
    const [openModalNotification, setOpenModalNotification] = useState(false);

    const isGroup = route.path.includes('/convites');
    const { event } = route.params;

    function handleIndividual() {
        setOption('individual');
    }

    function handleFamily() {
        setOption('family');
    }

    const handleOpenModalNotification = () => {
        setOpenModalNotification(!openModalNotification);
    };

    function navigationForPageConfirmation() {
        Linking.openURL('http://confirma.puzli.com.br/ok');
    }

    async function getUniqueEvent(eventHash: string) {
        setLoading(true);
        try {
            const response = await getEvent(eventHash);
            setOption(response?.result?.dual_link && isGroup ? 'family' : 'individual');
            setEventData(response.result);
                response.result?.is_vaccination
                    ? setProgress(0.3)
                    : setProgress(0.5);

                if (response.result.blocked_at !== null) {
                    setMessage(`O evento ${response.result.name}, não está disponível para novos convidados.`);
                }
                setLoading(false);
                return response.result;
        } catch (e) {
            setMessage('Desculpa! Não conseguimos encontrar um evento.');
            setLoading(false);
            return e;
        }
    }

    useEffect(() => {
        getUniqueEvent(event);
    }, []);

    useEffect(() => {
        navigation.setOptions({
            title: 'Formulário de convidado',
        });
    }, [navigation]);

    return (
        <>
            {loading ? <Loading />
                : (
                    <S.Safe>
                        {message !== '' ? <ListEmpty message={message} /> : (
                            <S.Container>
                                <Header iconIsVisible title={`Formulário - ${eventData.name}`} navigation={navigation} marginBottom={16} />
                                <S.ProgressContainer>
                                    <Progress.Bar
                                        progress={progress}
                                        color={theme.colors.purpleDark}
                                        height={6}
                                        borderRadius={10}
                                        width={null}
                                    />
                                </S.ProgressContainer>
                                <S.Box>
                                    <S.Content>
                                        {eventData?.dual_link ? null : (
                                            <S.BoxButtons>
                                                <ButtonSelected
                                                    onPress={handleIndividual}
                                                    title={
                                                        isWeb
                                                            ? 'Convite Individual'
                                                            : 'Convite \n Individual'
                                                    }
                                                    icon='user'
                                                    selected={option === 'individual'}
                                                />
                                                {!eventData.is_guest_group ? null : (
                                                    <ButtonSelected
                                                        onPress={handleFamily}
                                                        title='Com acompanhante(s)'
                                                        icon='users'
                                                        selected={option === 'family'}
                                                    />
                                                )}
                                            </S.BoxButtons>
                                        )}
                                        <S.Options>
                                            {option === 'individual' ? (
                                                <FormIndividual
                                                    event={eventData}
                                                    navigation={navigation}
                                                    eventId={event}
                                                    setTitleModalNotification={setTitleModalNotification}
                                                    setMessageModalNotification={setMessageModalNotification}
                                                    handleOpenModalNotification={handleOpenModalNotification}
                                                />
                                            ) : (
                                                <FormFamily
                                                    navigation={navigation}
                                                    event={eventData}
                                                    setTitleModalNotification={setTitleModalNotification}
                                                    setMessageModalNotification={setMessageModalNotification}
                                                    handleOpenModalNotification={handleOpenModalNotification}
                                                />
                                            )}
                                        </S.Options>
                                    </S.Content>
                                </S.Box>
                            </S.Container>
                        )}
                    </S.Safe>
                )
            }
            <AwesomeAlert
                show={openModalNotification}
                title={titleModalNotification}
                message={messageModalNotification}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='OK'
                confirmButtonColor='#98C828'
                onConfirmPressed={navigationForPageConfirmation}
            />
        </>

    );
}
