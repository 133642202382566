import { Alert, Platform } from 'react-native';

export function ErrorAlert(title: string, message: string) {

    function alertMessage() {
        if (Platform.OS === 'web') {
            alert(`${title} ${message}`);
        } else {
            Alert.alert(title, message);
        }
    }

    return alertMessage();
}
