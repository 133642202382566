import styled from 'styled-components/native';

import { RFValue } from 'react-native-responsive-fontsize';
import { Platform } from 'react-native';
import { defaultTheme as theme } from '../../../../../styles/theme';

const isWeb = Platform.OS === 'web';

export const Container = styled.View`
    background-color: #fff;
    border-radius: 4px;
    padding: 9px 12px;
    margin-top: 12px;
    box-shadow: 0px 3px 6px #00000029;
    justify-content: center;
`;
export const Title = styled.Text`
    color: ${theme.colors.gray};
    font-family: ${theme.font.bold};
    font-size: ${isWeb ? '0.75rem' : `${RFValue(12)}px`};
`;
export const Info = styled.Text`
    font-family: ${theme.font.regular};
    color: ${theme.colors.gray};
    font-size: ${isWeb ? '0.75rem' : `${RFValue(12)}px`};
    margin-top: 2px;
`;
