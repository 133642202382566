import React, { useState, useEffect } from 'react';
import { FlatList } from 'react-native';
import * as Progress from 'react-native-progress';
import * as ImagePicker from 'expo-image-picker';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as Linking from 'expo-linking';

import * as S from './styles';
import { Heading } from '../../../components/Heading';
import { Header } from '../../../components/Header';
import { Button } from '../../../components/Button';
import { uploadFile } from '../../../services/toolsApi';
import { registerGuest } from '../../../services/events';
import vaccineCard from '../../../assets/images/event/webInvite.png';
import { defaultTheme as theme } from '../../../styles/theme';
import { LoadingPhoto } from '../../../components/LoadingPhoto';

interface DataProps {
    id: number,
    name: string,
    telephone: string,
    email: string,
    photo?: string,
    vaccinationCard?: string
}

export function FormVaccineFamily({ route, navigation }) {

    // eslint-disable-next-line no-unused-vars
    const [progress, setProgress] = useState(1);
    const [guests, setGuests] = useState<DataProps[]>([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [openModalNotification, setOpenModalNotification] = useState(false);
    const [titleModalNotification, setTitleModalNotification] = useState('');
    const [messageModalNotification, setMessageModalNotification] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const data = route.params;

    const handleOpenModalNotification = () => {
        setOpenModalNotification(!openModalNotification);
    };

    function insertUrlImage(id: number, url: string, loading: boolean) {
        // eslint-disable-next-line no-shadow
        const newList = guests.map((guests) => {
            return guests.id === id ? { ...guests, vaccinationCard: url, loading } : guests;
        });
        setLoadingImage(loading);
        setGuests(newList);
    }


    async function pickImageHostImage(idGuest: number) {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
            base64: true,
        });

        if (!result.cancelled) {
            insertUrlImage(idGuest, result.uri, true);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                // setGuestPhoto(response.result)
                insertUrlImage(idGuest, response.result, false);
                return response.result;
            }
            return false;
        }
        return '';
    }

    const handleNavigationForm = () => {
        Linking.openURL('http://confirma.puzli.com.br/ok');
    };

    async function handleFinish() {
        try {
            setLoading(true);
            const response = await registerGuest(guests, data.event.hash);


            if (response.status === 200) {
                setLoading(false);
                setTitleModalNotification('Convidados cadastrados com sucesso!!');
                handleOpenModalNotification();
            } else {
                setLoading(false);
                setTitleModalNotification('Erro ao cadastrar!');
                setMessageModalNotification(response.message);
                handleOpenModalNotification();
            }

        } catch (e) {
            setLoading(false);
            setTitleModalNotification('Erro ao cadastrar!');
            setMessageModalNotification(e.message);
            handleOpenModalNotification();
            return e;
        }
        return true;
    }

    useEffect(() => {
        // setGuests(data.guests);
        if (guests.length === 0) {
            setGuests(data.guests);
        } else {
            const newList = data.guests.map((el) => {
                const guest = guests.find((e) => {
                    return e.id === el.id;
                });
                return {
                    ...el,
                    vaccinationCard: guest?.vaccinationCard,
                };
            });
            setGuests(newList);
        }
    }, [data]);

    useEffect(() => {
        navigation.setOptions({
            title: 'Cateiras de vacinação',
        });
    }, [navigation]);

    useEffect(() => {
        const haveVaccinationCard = guests.every((guest) => !!guest.vaccinationCard);
        setButtonDisabled(!haveVaccinationCard);
    }, [data, guests]);

    const goBack = () => {
        navigation.navigate('FormPhotoFamily', { guestsData: data.guests, event: data.event });
    };

    function formsVacination(guest) {
        return (
            <S.BoxPhoto>
                <Heading color='gray' size='intermediate'>
                    {guest.item.name}
                </Heading>
                <S.ButtomPhoto
                    disabled={loadingImage}
                    onPress={() => {
                        pickImageHostImage(guest.item.id);
                    }}
                >
                    <S.ButtonPhotoImg source={guest.item.vaccinationCard ? { uri: guest.item.vaccinationCard } : vaccineCard} />
                    <S.IconCan name='camera' />
                    {guest.item.loading && <LoadingPhoto />}
                </S.ButtomPhoto>
                <S.ButtonLabel>Insira uma foto</S.ButtonLabel>
            </S.BoxPhoto>
        );
    }

    return (
        <>
            <S.Safe>
                <S.Container>
                    <Header title={`Formulário - ${data.event?.name}`} navigation={navigation} marginBottom={16} handleGoback={goBack} />
                    <S.ProgressContainer>
                        <Progress.Bar progress={progress} color={theme.colors.purpleDark} height={6} borderRadius={10} width={null} />
                    </S.ProgressContainer>
                    <S.Box>
                        <S.Content>
                            <Heading color='gray' size='intermediate' marginBottom={16}>Cartão de vacinação</Heading>

                            <FlatList keyExtractor={(item) => item.id.toString()} renderItem={formsVacination} data={guests} />
                            <S.ButtonContainer>
                                <Button loading={loading} title='FINALIZAR' onClick={handleFinish} disabled={buttonDisabled} />
                            </S.ButtonContainer>

                        </S.Content>
                    </S.Box>
                </S.Container>
            </S.Safe>
            <AwesomeAlert
                show={openModalNotification}
                title={titleModalNotification}
                message={messageModalNotification}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='OK'
                confirmButtonColor='#98C828'
                onConfirmPressed={handleNavigationForm}
            />
        </>
    );
}
