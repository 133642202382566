import React, { useRef, useState } from 'react';
import { ScrollView, Platform, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { favoriteEvent } from '../../services/events';
import * as S from './styles';
import { Event } from './components/Event';

export type Event = {
    id: number;
    title: string;
    description: string;
    date: Date | string;
    time: string;
    color: string;
    image: string;
    invitation: string;
    link_group_users: string;
    favorite: boolean;
    name: string;
    front_cover: string;
    link_unique_user: string;
    dual_link: string;
    blocked_at: boolean;
};

export type EventsGalleryProps = {
    events?: any;
    navigation: any;
    isFavoritesList?: boolean;
    getEvents: () => void;
    allEventsList?: any;
};

export function EventsGallery({
    events,
    navigation,
    getEvents,
    allEventsList,
}: EventsGalleryProps) {
    const scroll = useRef(null) as any;
    const { user } = useSelector((state: any) => state.user.user);
    const [layoutWidth, setLayoutWidth] = useState(0);
    const [position, setPosition] = useState(0);
    const [buttonsVisible, setButtonVisible] = useState(false);
    const { width } = useWindowDimensions();
    const scrollStyle = {
        paddingLeft: width > 650 ? 132 : 37,
        paddingRight: width > 650 ? 50 : 37,
    };

    const isFavoriteInCategory = (item: Event) => {
        const itemExistsInFavorites = allEventsList?.main.find(
            (event: Event) => event.id === item.id,
        );
        return itemExistsInFavorites;
    };

    const onFavorite = async (event: Event) => {
        await favoriteEvent({
            type: user.type,
            idReference: user.id,
            idEvent: event.id,
            isFavorite: !isFavoriteInCategory(event),
        });
        getEvents();
    };

    const renderEvents = () => {
        return events?.map((event) => (
            <Event
                key={event.id}
                navigation={navigation}
                event={event}
                onFavorite={onFavorite}
                isFavoriteInCategory={isFavoriteInCategory}
            />
        ));
    };

    const scrollMove = (option: 'left' | 'right') => {
        if (option === 'right') {
            scroll.current.scrollTo({
                x: position + 250,
                y: 0,
                animated: true,
            });
        } else if (option === 'left' && position > 0) {
            scroll.current.scrollTo({
                x: position - 250,
                y: 0,
                animated: true,
            });
        }
    };

    const getLayout = (e) => {
        setLayoutWidth(e.nativeEvent.layout.width);
    };

    const getContentSizeChange = (e) => {
        setButtonVisible(e > layoutWidth);
    };

    const getPosition = (e) => {
        setPosition(e.nativeEvent.contentOffset.x);
    };

    const renderButtonScroll = (option: 'left' | 'right') => {
        return (
            <S.ButtonScroll
                onPress={() => {
                    scrollMove(option);
                }}
                position={option}
            >
                <S.ButtonScrollIcon name={option} />
            </S.ButtonScroll>
        );
    };

    return (
        <S.Container>
            <ScrollView
                onContentSizeChange={getContentSizeChange}
                onLayout={getLayout}
                onScroll={getPosition}
                ref={scroll}
                scrollsToTop
                horizontal
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={scrollStyle}
            >
                {renderEvents()}
            </ScrollView>

            {buttonsVisible && Platform.OS === 'web' && (
                <>
                    {position > 0 && renderButtonScroll('left')}
                    {renderButtonScroll('right')}
                </>
            )}
        </S.Container>
    );
}
