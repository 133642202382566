import React from 'react';

import { Heading } from '../Heading';
import { ButtonDrawer } from '../ButtonDrawer';
import * as S from './styles';

interface HeaderProps {
    title: string,
    navigation: any,
    marginBottom?: number,
    handleGoback?: ()=>void,
    iconIsVisible?: boolean
}

export function Header({ title, navigation, marginBottom, handleGoback, iconIsVisible }:HeaderProps) {

    function renderButtonDrawer() {
        if (iconIsVisible) {
            return null;
        }

        return (
            <ButtonDrawer
                type='goback'
                navigation={navigation}
                handleGoBack={handleGoback}
            />
        );
    }

    return (
        <S.Header marginBottom={marginBottom}>
            {renderButtonDrawer()}
            <Heading color='purpleDark' size='large'>
                {title}
            </Heading>
        </S.Header>
    );
}
