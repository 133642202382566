import styled from 'styled-components/native';
import { AntDesign } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../styles/theme';

interface SlideProps {
    blocked: boolean;
}

interface ButtonScroll {
    position: 'left' | 'right';
}

export const Container = styled.View`
    display: flex;
    flex-direction: row;
    max-height: 200px;
    margin-bottom: ${theme.spacings.medium};
`;

export const Slide = styled.TouchableOpacity<SlideProps>`
    margin-right: 20px;
    ${({ blocked }) => blocked !== null && 'opacity: 0.5'}
`;

export const ImageContainer = styled.View`
    position: relative;
    text-align: center;
    color: white;
    width: 200px;
    height: 100px;
`;

export const Image = styled.Image`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export const Heart = styled.TouchableOpacity`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: ${theme.colors.white};
    border-radius: 100px;
    justify-content: center;
    align-items: center;
`;

export const HeadingContainer = styled.View`
    max-width: 200px;
`;

export const EventInfos = styled.Text`
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.xxsmall};
    margin-top: 6px;
`;

export const ButtonScroll = styled.TouchableOpacity<ButtonScroll>`
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 0px;
    border-radius: 5px;
    margin-top: 20px;
    ${({ position }) => (position === 'left' ? 'left:10px' : 'right:20px')}
`;

export const ButtonScrollIcon = styled(AntDesign)`
    color: ${theme.colors.purpleDark};
    font-size: 30px;
`;
