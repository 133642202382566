/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';

// eslint-disable-next-line no-unused-vars
import { TouchableOpacityProps } from 'react-native';
import * as S from './styles';

export type TextFieldProps = {
    onInput?: (value: string) => void;
    placeholder?: string;
    initialValue?: string;
    type?: 'email' | 'number' | 'password';
    validationRules?: {
        email?: boolean;
        password?: boolean;
    };
    errorMessage?: string;
    showErrors?: boolean;
};

export default function TextField({
    placeholder,
    initialValue,
    type,
    onInput,
    showErrors = false,
}: TextFieldProps & TouchableOpacityProps) {
    const [value, setValue] = useState(initialValue || '');
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false);

    const validate = () => {
        if (showErrors && type === 'email') {
            // setar mensagem somente quanto parar de digitar
            setError(true);
            setErrorMessage('* Verifique se seu e-mail está correto.');
            return false;
        }
        if (showErrors && type === 'password') {
            setError(true);
            setErrorMessage('* Verifique se sua senha está correta.');
            return false;
        }
        setError(false);
        setErrorMessage('');
        return true;
    };

    useEffect(() => {
        validate();
    }, [showErrors]);

    const onChangeText = (text: string) => {
        setValue(text);
        !!onInput && onInput(text);
    };

    return (
        <S.Container>
            <S.Wrapper
                placeholder={placeholder}
                value={value}
                keyboardType={
                    type === 'email'
                        ? 'email-address'
                        : type === 'number'
                            ? 'number-pad'
                            : 'default'
                }
                secureTextEntry={type === 'password'}
                errorMessage={showErrors === true ? errorMessage : undefined}
                placeholderTextColor={(showErrors && error) === true ? '#FF7171' : '#636E72'}
                onChangeText={onChangeText}
            />
            {(showErrors && !!errorMessage) && <S.Error>{errorMessage}</S.Error>}
        </S.Container>
    );
}
