import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonFavorite } from '../../../../../components/ButtonFavorite';
import { PlaceLoader } from '../../../../../components/PlaceLoader';
import * as S from './styles';

import userPhoto from '../../../../../assets/images/user/user-photo.png';

export interface HostProps {
    id: number;
    name: string;
    photo: string;
    email: string;
    phone: string;
    telephone: string;
    is_favorite: boolean;
    blocked_at: string | null
}

interface Host {
    host: any;
    onPress: () => void,
    favoriteHost: (id: number, favorited: boolean) => void
}

export function CardHost({ host, favoriteHost, ...rest }: Host) {
    const user = useSelector((state: any) => state?.user.user);
    const { type } = user.user;
    const [imageIsLoading, setImageIsLoading] = useState(false);
    const handleFavorite = () => {
        if (host.is_favorite === true) {
            favoriteHost(host.id, false);
        } else {
            favoriteHost(host.id, true);
        }
    };

    const showImage = () => {
        setImageIsLoading(true);
    };

    return (
        <S.Container style={S.Shadow} {...rest} blocked={host.blocked_at}>
            <S.Box>
                <PlaceLoader
                    visible={imageIsLoading}
                    width={46}
                    height={46}
                    mr={12}
                    border={4}
                >
                    <S.Image onLoad={showImage} source={host.photo ? { uri: host.photo } : userPhoto} />
                </PlaceLoader>
                <S.Content>
                    <S.Name numberOfLines={1}>{host.name}</S.Name>
                    <S.Email numberOfLines={1}>{host.email}</S.Email>
                    <S.Phone>{host.telephone}</S.Phone>
                </S.Content>
            </S.Box>
            {type === 'adm' ? (
                <ButtonFavorite onPress={handleFavorite} star={host.is_favorite} size={14} />
            ) : null}
        </S.Container>
    );
}
