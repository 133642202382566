import styled from 'styled-components/native';
import { defaultTheme as theme } from '../../styles/theme';

interface ProgressProps {
    width: number
}

export const ModalContainer = styled.View`
    background-color: rgba(0,0,0, 0.7);
    flex:1;
    padding: 20px;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.View`
    background-color: #fff;
    max-height: 350px;
    border-radius: 4px ;
    padding: 20px;
    max-width: 300px;  
    width: 100%;
    position: absolute;
`;

export const BackgroundProgress = styled.View`
    background-color: ${theme.colors.lightGray};
    height: 10px;
    border-radius: 5px;
    margin-top: 20px;
`;

export const Progress = styled.View<ProgressProps>`
    background-color: ${theme.colors.purpleDark};
    height: 10px;
    border-radius: 5px;
    width: ${(props) => props.width * 100}%;
`;
