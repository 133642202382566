import React from 'react';
import { ActivityIndicator } from 'react-native';
import * as S from './styles';

export function LoadingPhoto() {
    return (
        <S.Container intensity={90} tint='light'>
            <ActivityIndicator color='#5209A8' size='small' />
        </S.Container>
    );
}
