import * as React from 'react';
import Svg, { SvgProps, G, Rect, Path } from 'react-native-svg';

interface CalendarProps {
    props?: SvgProps
    color: string
}

const SvgComponent = ({ props, color }:CalendarProps) => (
    <Svg xmlns='http://www.w3.org/2000/svg' width={24} height={23} {...props}>
        <G
            transform='translate(1 .81)'
            fill='none'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
        >
            <Rect
                data-name='Ret\xE2ngulo 147600'
                width={22}
                height={19}
                rx={2}
                transform='translate(0 2.19)'
            />
            <Path data-name='Linha 162' d='M16 .19v4' />
            <Path data-name='Linha 163' d='M6 .19v4' />
            <Path data-name='Linha 164' d='M0 8.19h22' />
        </G>
    </Svg>
);

export default SvgComponent;
