import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { periodUtils } from './utils/utils';
import { Button } from '../../components/Button';

interface FilterProps {
    closeModal: ()=> void;
    changeFilter:(value:string)=> void;
    changePeriod: (value:string)=> void;
    changeSearchTxt: (value: string) => void;
    handleChangeFilter: ()=>void;
    currentFilter: string,
    currentPeriod: string,
    currentSearchText: string,
    categories: []
}

export function FiltersModal({ closeModal, changeFilter, changePeriod, handleChangeFilter, currentFilter, currentPeriod, changeSearchTxt, currentSearchText, categories }: FilterProps) {

    const [filtersSelectedCategories, setFiltersSelectedCategories] = useState<Array[number]>([]);
    const [period, setPeriod] = useState('');
    const [, setSearchText] = useState('');

    const handleGoBack = () => {
        closeModal();
    };

    function handleSelectedFilterCategories(filter:number) {
        if (!filtersSelectedCategories.includes(filter)) {
            setFiltersSelectedCategories([...filtersSelectedCategories, filter]);
        } else {
            setFiltersSelectedCategories((oldState) => oldState.filter((e) => e !== filter));
        }
    }

    function handleSelectedFilterPeriod(filter:string) {
        if (filter === period) {
            setPeriod('');
            changePeriod('');
        } else {
            setPeriod(filter);
            changePeriod(filter);
        }
    }

    function handleSearchText(txt:string) {
        setSearchText(txt);
        changeSearchTxt(txt);
    }

    function handleFilter() {
        handleChangeFilter();
        closeModal();
    }

    function cleanFilters() {
        setFiltersSelectedCategories([]);
        setPeriod('');
        changePeriod('');
        changeFilter('');
        changeSearchTxt('');
    }

    useEffect(() => {
        changeFilter(filtersSelectedCategories.toString());
    },
    [filtersSelectedCategories, period]);

    useEffect(() => {
        setPeriod(currentPeriod);
        currentFilter && setFiltersSelectedCategories(currentFilter.split(','));
    }, []);

    return (
        <S.Safe>
            <S.Box>
                <S.Container>
                    <S.Header>
                        <S.BoxGoBack>
                            <S.GoBackButton onPress={handleGoBack}>
                                <S.GoBack name='arrow-back' />
                            </S.GoBackButton>
                            <S.Title>Filtros</S.Title>
                        </S.BoxGoBack>
                        <S.CleanButton onPress={cleanFilters}>
                            <S.CleanButtonText>Limpar</S.CleanButtonText>
                        </S.CleanButton>
                    </S.Header>
                    <S.SearchBox>
                        <S.SearchIcon name='search' />
                        <S.SearchInput onChangeText={handleSearchText} value={currentSearchText} />
                    </S.SearchBox>
                    <S.Filters>
                        <S.FilterTitle>Categorias</S.FilterTitle>
                        <S.FilterBox>
                            {categories.map((e) => (
                                <S.Filter
                                    key={e.value}
                                    onPress={() => {
                                        handleSelectedFilterCategories(e.value);
                                    }}
                                    selected={filtersSelectedCategories.includes(e.value)}
                                >
                                    <S.FilterText selected={filtersSelectedCategories.includes(e.value)}>{e.label}</S.FilterText>
                                </S.Filter>
                            ))}
                        </S.FilterBox>
                        <S.FilterTitle>Período</S.FilterTitle>
                        <S.FilterBox>
                            {periodUtils.map((e:any) => (
                                <S.Filter
                                    key={e.value}
                                    onPress={() => {
                                        handleSelectedFilterPeriod(e.value);
                                    }}
                                    selected={period === e.value}
                                >
                                    <S.FilterText selected={period === e.value}>{e.label}</S.FilterText>
                                </S.Filter>
                            ))}
                        </S.FilterBox>
                    </S.Filters>
                </S.Container>
                <S.ContainerButtonFilter>
                    <Button title='FILTRAR' onClick={handleFilter} />
                </S.ContainerButtonFilter>
            </S.Box>
        </S.Safe>
    );
}
