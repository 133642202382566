import styled from 'styled-components/native';
import { Platform, StatusBar, Dimensions } from 'react-native';

import { MaterialCommunityIcons, AntDesign, Feather } from '@expo/vector-icons';

import { RFValue } from 'react-native-responsive-fontsize';
import { defaultTheme as theme } from '../../../styles/theme';


const windowWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === 'web';

interface IconCanProps {
    withImage: boolean
}
interface ButtonPickerProps {
    errors?: boolean
}

export type ContainerButtonProps = {
    isFinal?: boolean
}

interface FilterProps {
    selected: boolean
}

export const Safe = styled.SafeAreaView`
    flex:1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;


export const Scroll = styled.ScrollView`
    margin-bottom: 20px;
`;

export const Header = styled.View`
    padding: 18px 20px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #00000005;
`;

export const ButtonGoback = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
`;

export const ButtonGobackIcon = styled(MaterialCommunityIcons)`
    font-size: ${isWeb && windowWidth > 650 ? '0.9375rem' : `${RFValue(25)}px`};
    color: ${theme.colors.gray};
`;

export const NamePage = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : `${RFValue(12)}px`};
    margin-left: 12px;
    font-family: ${theme.font.regular};
    color: ${theme.colors.gray};
`;

export const ButtonSave = styled.TouchableOpacity``;

export const ButtonSaveText = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : `${RFValue(12)}px`};
    margin-left: 12px;
    font-family: ${theme.font.regular};
    color: ${theme.colors.purpleDark};
`;

export const ButtomImageHost = styled.TouchableOpacity`
    height: ${isWeb && windowWidth > 650 ? '16.875rem' : `${RFValue(140)}px`};
    width: ${isWeb && windowWidth > 650 ? '23.437rem' : `${RFValue(140)}px`};
    margin-top: 31px;
    align-self: center;
`;

export const IconCan = styled(Feather) <IconCanProps>`
    font-size: 18px;
    color: ${({ withImage }) => (withImage ? theme.colors.grayLight : theme.colors.black)};
    position: absolute;
    bottom: 12px;
    right: 12px;
`;

export const ImageHost = styled.Image`
    height: 100%;
    width: 100%;
    border-radius: 5px;
`;

export const Form = styled.View`
    margin-top: ${RFValue(28)}px;
    padding: 0px ${RFValue(20)}px;
    width: ${isWeb && windowWidth > 650 ? '30.875rem' : '100%'};
    align-self: center;
`;

export const ButtonPicker = styled.View<ButtonPickerProps>`

    border: 1px solid ${({ errors }) => (errors ? theme.colors.red : theme.colors.grayLight)};
    border-radius: 4px;
    margin-top: 12px;
`;

export const ButtonPickerHeader = styled.TouchableOpacity`
padding: 17px 12px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    /* margin-bottom: 10px; */
`;

export const ButtonPickerIcon = styled(AntDesign)`
    font-size: 16px;
    color: ${theme.colors.grayLight};
`;

export const ButtonPickerText = styled.Text`
    font-family: ${theme.font.bold};
    color: ${theme.colors.gray};
    font-size: 12px;
`;

export const EventsSelectedBox = styled.ScrollView.attrs(
    {
        contentContainerStyle: {
            // marginTop:10,
            // paddingBottom: 20,
            flexWrap: 'wrap',
            flexDirection: 'row',
            // marginBottom: 36,
            // maxHeight:100
            paddingHorizontal: 10,
        },
    },
)`
    max-height: 100px;
`;


export const ModalContainer = styled.View`
    background-color: rgba(0,0,0, 0.7);
    flex:1;
    padding: 20px;
    align-items: center;
    justify-content: center;
`;

export const ModalContent = styled.View`
    background-color: #fff;
    max-height: 350px;
    min-height: 300px;
    border-radius: 4px ;
    padding: 10px;
    max-width: 330px;
    width: 100%;
    position: absolute;
`;

export const FilterBox = styled.ScrollView.attrs(
    {
        contentContainerStyle: {
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginBottom: 10,
        },
    },
)``;
export const Filter = styled.TouchableOpacity<FilterProps>`
    border: 1px solid ${theme.colors.grayLight};
    padding: 6px 20px;
    border-radius: 15px;
    height: ${isWeb ? 'max-content' : '35px'};
    margin: 6px 2px;
    background-color: ${({ selected }) => (selected ? 'rgba(82, 9, 168, 0.5)' : 'transparent')};
`;


export const FilterForm = styled.TouchableOpacity<FilterProps>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${theme.colors.grayLight};
    padding: 6px 5px;
    border-radius: 15px;
    margin: 6px 2px;
    background-color: ${({ selected }) => (selected ? 'rgba(82, 9, 168, 0.5)' : 'transparent')};
`;
export const FilterFormIconClose = styled(AntDesign)`
    font-size: 15px;
    margin-left: 5px;
    color: ${theme.colors.white};
`;

export const FilterText = styled.Text<FilterProps>`
    font-family: ${theme.font.regular};
    font-size: ${isWeb && windowWidth > 650 ? '14px' : '14px'};
    color: ${({ selected }) => (selected ? theme.colors.purpleDark : theme.colors.gray)};
`;

export const ContainerButtonModal = styled.View<ContainerButtonProps>`
    ${isWeb && 'width: 200px; align-self:center'}
    margin-bottom: ${(props) => (props.isFinal ? `${RFValue(40)}px` : `${RFValue(10)}px`)};
    padding: ${isWeb && windowWidth > 650 ? '0px' : `0px ${RFValue(36)}px`};
    margin-top: ${RFValue(22)}px;
`;
