import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import uuid from 'react-native-uuid';

import { Heading } from '../Heading';
import { Button } from '../Button';
import TextField from './utils/TextField';

import { api } from '../../services/api';
import { loginAuth } from '../../store/modules/user/actions';

import * as S from './styles';

export interface SingInFormProps {
    navigation: {
        navigation: any
    }
}

export function SingInForm({ navigation: { navigation } }: SingInFormProps) {
    const user = useSelector((state: any) => state?.user.user);
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showErrorsEmail, setShowErrorsEmail] = useState(false);
    const [showErrorsPassword, setShowErrorsPassword] = useState(false);

    const onChangeEmail = (value: string) => {
        setEmail(value);
        setShowErrorsEmail(false);
    };

    const onChangePassword = (value: string) => {
        setPassword(value);
        setShowErrorsPassword(false);
    };

    const handleLogin = () => {
        const sessionId = uuid.v4();
        api.post('/clients/token', {
            email,
            password,
            session: sessionId,
        }).then((result: any) => {
            const response = result?.response || result;
            const messageError = response.data.message;
            if (['Digite corretamente seu email.', 'Email inválido'].includes(messageError)) {
                setShowErrorsEmail(true);
            } else if (['Senha inválida.', 'Digite corretamente sua senha de pelo menos 6 dígitos.'].includes(messageError)) {
                setShowErrorsPassword(true);
            }

            const object = {
                token: response.data.result.token,
                refreshToken: response.data.result.tokenRefresh,
                user: response.data.result.user,
            };
            // @ts-ignore
            dispatch(loginAuth(object));
            AsyncStorage.setItem('@PuzliApp:client', JSON.stringify(response.data.result.user.client || ''));
            AsyncStorage.setItem('@PuzliApp:type', JSON.stringify(response.data.result.user.type));
            AsyncStorage.setItem('@PuzliApp:token', JSON.stringify(object.token));
            AsyncStorage.setItem('@PuzliApp:session', JSON.stringify(response.data.result.user.client[0].session));
            AsyncStorage.setItem('@PuzliApp:refresh_token', JSON.stringify(object.refreshToken)).then(() => {
                navigation.navigate('Home');
            });
        }).catch((error) => {
            const message = error?.response?.data?.message;
            if (message === 'Email inválido' || message === 'Digite corretamente seu email.') {
                setShowErrorsEmail(true);
            } else if (message === 'Senha inválida.' || message === 'Digite corretamente sua senha de pelo menos 6 dígitos.') {
                setShowErrorsPassword(true);
            }
        });
    };

    useEffect(() => {
        if (user?.token) {
            navigation.reset({
                index: 0,
                routes: [{ name: 'Home' }],
            });
        }
    }, []);

    return (
        <S.Container>
            <S.Content>
                <Heading color='purpleDark' size='large'>
                    Bem-vindo
                </Heading>
                <S.Form>
                    <TextField
                        placeholder='Email'
                        type='email'
                        onInput={onChangeEmail}
                        showErrors={showErrorsEmail}
                    />
                    <TextField
                        placeholder='Senha'
                        type='password'
                        onInput={onChangePassword}
                        showErrors={showErrorsPassword}
                    />
                </S.Form>
                <Button
                    onClick={
                        handleLogin
                    }
                    disabled={!(email && password)}
                    title='ENTRAR'
                />
            </S.Content>
        </S.Container>
    );
}
