import React, { useState, useEffect } from 'react';
import { Platform, Modal, TouchableWithoutFeedback } from 'react-native';
import { useSelector } from 'react-redux';
import AwesomeAlert from 'react-native-awesome-alerts';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import { useIsFocused } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import * as Permissions from 'expo-permissions';
import { Loading } from '../../../components/Loading';
import QrCodeItem from '../../../components/QrCodeItem';
import { verifyCode, historyCodes } from '../../../services/qrCode';
import { getAllEvents } from '../../../services/events';
import * as S from './styles';
import qrCode from './assets/qr-code.png';
import qrCodeSmall from './assets/qr-code-small.png';
import { ButtonDrawer } from '../../../components/ButtonDrawer';
import { InputSelect } from '../../../components/InputSelect';
import { ListEmpty } from '../../../components/ListEmpty';
import { Errors } from '../../../components/Errors';

const isWeb = Platform.OS === 'web';

export type QrCodeScreenProps = {
    navigation: any;
    route: any;
};

export function QrCodeScreen({ navigation, route }: QrCodeScreenProps) {
    const [camera, setCamera] = useState({
        hasCameraPermission: null,
    });
    const { params } = route;
    const { user } = useSelector((state: any) => state.user.user);
    const isFocused = useIsFocused();
    const [viewQrCode, setViewQrCode] = useState(false);
    const [events, setEvents] = useState<any[]>([]);
    const [historyCode, setHistoryCode] = useState([]);
    const [idEvent, setIdEvent] = useState(0);
    const [dataQrCode, setDataQrCode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');

    const {
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm();

    const getEvents = async () => {
        setLoading(true);
        await getAllEvents(user.id, user.type).then(async (response) => {
            delete response.result.list.main;
            const values = Object.values(response.result.list).flat();
            const filter = values.filter((e: any) => {
                return e.blocked_at === null;
            });
            const formatted = filter.map((e: any) => ({
                label: e.name,
                value: e.id,
            }));
            setEvents(formatted);

            await getHistoryQrCodes();
        });
    };

    const openModalDelete = () => {
        setOpenModalConfirm(!openModalConfirm);
    };

    const editingStringForSearch = (text: string) => {
        const newText = text
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        return newText;
    };

    function filterQrCode(e: any) {
        const search = editingStringForSearch(searchTxt);
        const adm = editingStringForSearch(e.admEmail || '');
        const guest = editingStringForSearch(e.guestName || '');

        const isIncludeAdm = adm.includes(search);
        const isIncludeGuestName = guest.includes(search);

        if (isIncludeAdm || isIncludeGuestName) {
            return e;
        }
        return null;
    }

    async function getHistoryQrCodes() {
        setLoading(true);
        setHistoryCode([]);
        historyCodes(idEvent).then((response) => {
            const sort = response.result?.list.sort(
                (a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime(),
            );

            if (searchTxt) {
                const filter = sort.filter(filterQrCode);
                setHistoryCode(filter);
            } else {
                setHistoryCode(sort);
            }
        });
        setLoading(false);
    }

    const handleCloseModalQrCode = () => {
        setViewQrCode(false);
    };

    const handleBarCodeScanned = async ({ data }) => {
        const hashCode = data.split('/')[4];
        setViewQrCode(false);
        setDataQrCode(true);
        await verifyCode(Number(idEvent), hashCode, user.id).then((response) => {
            if (response.status === 403) {
                openModalDelete();
                getHistoryQrCodes();
                setDataQrCode(false);
            } else if (response.result) {
                navigation.navigate('QrCodeResultScreen', {
                    email: response.result.email,
                    idEvent: response.result.id_event,
                    name: response.result.name,
                    photo: response.result.photo,
                    telephone: response.result.telephone,
                    event: response.result.event,
                });
            }
        });

    };

    const handleClickTextQrCode = () => {
        if (Number(idEvent) === 0) {
            setError('idEvent', {
                type: 'validate',
                message: 'Selecione um evento para fazer a leitura.',
            });
        } else {
            setViewQrCode(true);
        }
    };

    const cardEvent = ({ item }: any) => (
        <QrCodeItem qrCodeInfo={item} />
    );

    const handleInputSelect = async (id) => {
        const idConverted = Number(id);
        setHistoryCode([]);
        setIdEvent(idConverted);
        setViewQrCode(false);
    };

    function ErrorsComponent({ message }) {
        return <Errors message={message} />;
    }

    function renderHeader() {
        if (!isWeb) {
            return null;
        }

        return (
            <S.Header>
                <ButtonDrawer navigation={navigation} type='drawer' />
            </S.Header>
        );
    }

    function renderInputs() {
        if (events.length === 0) {
            return null;
        }

        const initialValue = params?.eventIdScan || idEvent;
        return (
            <S.InputContainerSelect>
                <InputSelect
                    initialValue={initialValue}
                    list={events}
                    setProps={handleInputSelect}
                    label='Todos os eventos'
                    errors={errors.idEvent}
                />
                <ErrorMessage
                    errors={errors}
                    name='idEvent'
                    render={ErrorsComponent}
                />
                <S.InputSearch
                    placeholder='Pesquisar'
                    onChangeText={setSearchTxt}
                    value={searchTxt}
                />
            </S.InputContainerSelect>
        );
    }

    useEffect(() => {
        setLoading(true);
        setViewQrCode(false);
        setHistoryCode([]);
        setIdEvent(0);
        setDataQrCode(false);
        if (isFocused) {
            getEvents();
            setIdEvent(0);
            setViewQrCode(false);
        }
    }, [isFocused]);

    useEffect(async () => {
        const { status } = await Permissions.askAsync(Permissions.CAMERA);

        setCamera((prevState) => ({
            ...prevState,
            hasCameraPermission: status === 'granted',
        }));
    }, []);

    useEffect(() => {
        clearErrors();
        getHistoryQrCodes();
    }, [idEvent, searchTxt]);

    return (
        <>
            <S.Safe>
                {loading && <Loading />}
                {!loading
                    && historyCode.length === 0
                    && viewQrCode === false && (
                    <ListEmpty message='Este evento não possui histórico de leituras!' />
                )}
                <S.Container>
                    {renderHeader()}
                    <S.Top onPress={handleSubmit(handleClickTextQrCode)}>
                        <S.QrCode source={qrCode} />
                        <S.ReadQrCode>
                            <S.SmallQrCode source={qrCodeSmall} />
                            <S.ReadQrCodeText>Ler QR Code</S.ReadQrCodeText>
                        </S.ReadQrCode>
                    </S.Top>
                    {renderInputs()}
                    <Modal
                        animationType='fade'
                        onRequestClose={handleCloseModalQrCode}
                        transparent
                        visible={viewQrCode}
                    >
                        <TouchableWithoutFeedback
                            onPress={handleCloseModalQrCode}
                        >
                            <S.ModalContainer>
                                <S.BarCodeContainer>
                                    <S.QrCodeBox>
                                        {camera.hasCameraPermission && (
                                            <Camera
                                                style={S.barCode}
                                                onBarCodeScanned={
                                                    dataQrCode ? undefined : handleBarCodeScanned
                                                }
                                                barCodeScannerSettings={{
                                                    barCodeTypes: [
                                                        BarCodeScanner.Constants
                                                            .BarCodeType.qr,
                                                    ],
                                                }}
                                            />
                                        )}
                                    </S.QrCodeBox>
                                </S.BarCodeContainer>
                            </S.ModalContainer>
                        </TouchableWithoutFeedback>
                    </Modal>

                    <S.EventsList
                        data={historyCode}
                        renderItem={cardEvent}
                        keyExtractor={(item, index) => index.toString()}
                    />
                </S.Container>
                <AwesomeAlert
                    show={openModalConfirm}
                    title='Atenção!'
                    message='Este QrCode já foi lido!'
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showConfirmButton
                    confirmText='OK'
                    confirmButtonColor='#FF7171'
                    onConfirmPressed={openModalDelete}
                />
            </S.Safe>
        </>
    );
}
