import { api } from './api';
import { getToken } from './functions';

export async function clientLogin(email: string, password: string) {
    try {
        const { data } = await api.post('/clients/token', {
            email,
            password,
        });
        return data;
    } catch (e) {
        return e;
    }
}

export async function checkWhatsapp() {
    const token = await getToken();
    try {
        const { data } = await api.get('/whatsapp/check', {
            headers: {
                'x-access-token': token,
            },
        });
        return data;
    } catch (e) {
        return e;
    }
}
