import { useFonts } from 'expo-font';
import * as S from './styles';

type Colors = 'purple' | 'purpleDark' | 'white' | 'orange' | 'black' | 'gray' | 'grayLight';
type Sizes = 'small' | 'medium' | 'large' | 'intermediate' | 'xsmall';

export type HeadingProps = {
    children: React.ReactNode;
    color?: Colors;
    size?: Sizes;
    marginBottom?: number;
    marginTop?: number;
    marginRight?:number
};

export function Heading({
    children,
    color = 'black',
    size = 'medium',
    marginBottom = 0,
    marginTop = 0,
    marginRight = 0,
}: HeadingProps) {
    const [fontsLoaded] = useFonts({
        'Montserrat-Regular': require('../../../assets/fonts/Montserrat-Regular.ttf'),
        'Montserrat-Bold': require('../../../assets/fonts/Montserrat-Bold.ttf'),
    });

    if (!fontsLoaded) {
        return null;
    }

    return (
        <S.Wrapper marginRight={marginRight} color={color} size={size} marginBottom={marginBottom} marginTop={marginTop}>
            {children}
        </S.Wrapper>
    );
}
