import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

import * as S from './styles';

interface QrCodeProps {
    status: 'success' | 'error';
    date: string;
    id_event: number;
    id_guest: string;
    id_adm: string;
    eventName: string;
    admEmail: string;
    guestName: string;
}

export type QrCodeItemProps = {
    qrCodeInfo: QrCodeProps;
};

export default function QrCodeItem(props: QrCodeItemProps) {
    const { qrCodeInfo } = props;
    const date = format(new Date(qrCodeInfo.date), 'dd/MM/yyyy HH:m', {
        locale: ptBr,
    });

    function renderQrCodeIcon() {
        if (qrCodeInfo.status === 'success') {
            return (
                <S.BoxImage>
                    <S.ImageQrCode
                        source={require('./assets/qr-code-green.png')}
                    />
                </S.BoxImage>
            );
        }
        return (
            <S.BoxImage>
                <S.ImageQrCode source={require('./assets/qr-code-red.png')} />
            </S.BoxImage>
        );
    }

    return (
        <S.EventBox style={S.Shadow}>
            {renderQrCodeIcon()}
            <S.Content>
                <S.Title status={qrCodeInfo.status}>
                    {qrCodeInfo.eventName}
                </S.Title>
                <S.Footer>
                    <S.Date>
                        Convidado:
                        {qrCodeInfo.guestName}
                    </S.Date>
                    <S.Date>
                        Leitor:
                        {qrCodeInfo.admEmail}
                    </S.Date>
                    <S.Date>{date}</S.Date>
                </S.Footer>
            </S.Content>
        </S.EventBox>
    );
}
