import { api } from './api';
import { getToken } from './functions';

export async function registerAdministrators(body: any) {
    const token = await getToken();
    try {
        const { data } = await api.post('adm', body, {
            headers: {
                'x-access-token': token,
            },
        });
        return data;
    } catch (error: any) {
        return error;
    }
}

export async function editAdministrators(
    permission: string,
    idAdm: number,
    body: any,
) {
    const token = await getToken();

    try {
        const { data } = await api.put(
            `adm?permission=${permission}&idAdm=${idAdm}`,
            body,
            {
                headers: {
                    'x-access-token': token,
                },
            },
        );
        return data;
    } catch (error: any) {
        return error;
    }
}

export async function getAllAdministrators(idAmd: number) {
    const token = await getToken();
    try {
        const { data } = await api.get(`adm?idAdm=${idAmd}`, {
            headers: {
                'x-access-token': token,
            },
        });
        return data;
    } catch (e) {
        return e;
    }
}
