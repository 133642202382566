import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import AwesomeAlert from 'react-native-awesome-alerts';

import * as S from './styles';
import { Loading } from '../../../components/Loading';
import { InputHookForm } from '../../../components/InputHookform';
import { Errors } from '../../../components/Errors';
import { LoadingPhoto } from '../../../components/LoadingPhoto';
import { uploadFile } from '../../../services/toolsApi';
import vaccineCard from '../../../assets/images/event/webInvite.png';
import photoUserEmpty from '../../../assets/images/user/user-photo-hosts.png';

import { guestEdit } from '../../../services/guests';

export type ListSelectProps = {};

interface HostsDetailsScreen {
    route: RouteProp<{
        params: {
            guest: any;
            event: any;
        };
    }>;
    navigation: any;
}

export function GuestEditScreen({ route, navigation }: HostsDetailsScreen) {
    const isWeb = Platform.OS === 'web';
    const { guest, event } = route.params;
    const vaccinationPhoto = String(guest.vaccination_card);
    const imgVaccineCard = {
        uri: vaccinationPhoto || vaccineCard,
    };
    const photo = String(guest.photo);
    const imgHostCard = {
        uri: photo || photoUserEmpty,
    };

    const [imgVaccine, setImgVaccine] = useState(imgVaccineCard);
    const [hostImage, setHostImage] = useState(imgHostCard);
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [loadingVaccine, setLoadingVaccine] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);

    const {
        control,
        formState: { errors },
        clearErrors,
        handleSubmit,
    } = useForm();

    async function pickImageHostImage(type: 'profile' | 'vaccineCard') {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            if (type === 'profile') {
                setHostImage({ uri: result.uri });
                setLoadingPhoto(true);
            } else {
                setImgVaccine({ uri: result.uri });
                setLoadingVaccine(true);
            }

            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                if (type === 'profile') {
                    setHostImage({ uri: response.result });
                    setLoadingPhoto(false);
                } else {
                    setImgVaccine({ uri: response.result });
                    setLoadingVaccine(false);
                }
            }
        }
    }

    function handleGoBack() {
        const routes = [
            {
                name: 'GuestsDetailsScreen',
                params: {
                    guest,
                    event,
                },
            } as never,
        ];
        const reset = {
            index: 0,
            routes,
        };
        const params = {
            reload: true,
            guest,
            event,
        };
        if (isWeb) {
            navigation.reset(reset);
        } else {
            navigation.navigate('GuestsDetailsScreen', params);
        }
    }

    function ErrorsComponent({ message }) {
        return (
            <Errors message={message} />
        );
    }

    async function handleSave(data) {
        const vaccine = imgVaccine.uri.includes('http')
            ? imgVaccine.uri
            : guest.vaccination_card;
        const body = {
            ...data,
            photo: hostImage.uri,
            vaccinationCard: vaccine,
        };
        const response = await guestEdit(guest.id, body);
        if (response.status === 200) {
            setOpenModalSuccess(true);
        }
    }

    function renderLoading(loading: boolean) {
        if (!loading) {
            return null;
        }

        return (
            <LoadingPhoto />
        );
    }

    function handleNavigationHost() {
        setOpenModalSuccess(false);
        const routes = [
            {
                name: 'GuestsScreen',
                params: {
                    idEvent: event.id,
                    event,
                },
            } as never,
        ];
        const reset = {
            index: 0,
            routes,
        };
        navigation.reset(reset);
    }

    function pickerImageProfile() {
        pickImageHostImage('profile');
    }

    function pickerImageVacine() {
        pickImageHostImage('vaccineCard');
    }

    useEffect(() => {
        setLoading(false);
        clearErrors('idEvents');
    }, []);

    useEffect(() => {
        const titleScreen = {
            title: `Editar Convidado | ${guest.name}`,
        };
        navigation.setOptions(titleScreen);
    }, [navigation, route]);

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <S.Safe>
            <S.Header>
                <S.ButtonGoback onPress={handleGoBack}>
                    <S.ButtonGobackIcon name='close' />
                    <S.NamePage>Editar</S.NamePage>
                </S.ButtonGoback>
                <S.ButtonSave
                    // disabled={loadingPhoto}
                    onPress={handleSubmit(handleSave)}
                >
                    <S.ButtonSaveText>Salvar</S.ButtonSaveText>
                </S.ButtonSave>
            </S.Header>
            <S.Scroll>
                <S.ButtomImageHost
                    onPress={pickerImageProfile}
                >
                    <S.ImageHost source={hostImage} resizeMode='cover' />
                    {renderLoading(loadingPhoto)}
                    <S.IconCan name='camera' withImage={hostImage} />
                </S.ButtomImageHost>
                <S.Form>
                    <InputHookForm
                        errors={errors.name}
                        name='name'
                        control={control}
                        label='Nome'
                        type='text'
                        placeholder='Digite o nome do convidado'
                        valueInicial={guest.name}
                    />
                    <ErrorMessage
                        errors={errors}
                        name='name'
                        render={ErrorsComponent}
                    />
                    <InputHookForm
                        errors={errors.telephone}
                        name='telephone'
                        control={control}
                        label='Telefone'
                        type='phone'
                        placeholder='(12) 12345-6789'
                        valueInicial={guest.telephone}
                    />
                    <ErrorMessage
                        errors={errors}
                        name='telephone'
                        render={ErrorsComponent}
                    />
                    <InputHookForm
                        // errors={errors.email}
                        optional
                        name='email'
                        control={control}
                        label='Email'
                        type='email'
                        placeholder='Digite seu email'
                        valueInicial={guest.email || ''}
                    />
                    {/* <ErrorMessage
                        errors={errors}
                        name='email'
                        render={ErrorsComponent}
                    /> */}
                    <InputHookForm
                        // errors={errors.rg}
                        optional
                        name='rg'
                        control={control}
                        label='RG'
                        type='number'
                        placeholder='Digite seu RG'
                        valueInicial={guest.rg || ''}
                    />
                    {/* <ErrorMessage
                        errors={errors}
                        name='rg'
                        render={ErrorsComponent}
                    /> */}
                    <InputHookForm
                        // errors={errors.cpf}
                        optional
                        name='cpf'
                        control={control}
                        label='CPF'
                        type='cpf'
                        placeholder='Digite seu CPF'
                        valueInicial={guest.cpf || ''}
                    />
                    {/* <ErrorMessage
                        errors={errors}
                        name='cpf'
                        render={ErrorsComponent}
                    /> */}
                    <InputHookForm
                        // errors={errors.address}
                        optional
                        name='address'
                        control={control}
                        label='Endereço'
                        type='text'
                        placeholder='Digite seu endereço'
                        valueInicial={guest.address || ''}
                    />
                    {/* <ErrorMessage
                        errors={errors}
                        name='address'
                        render={ErrorsComponent}
                    /> */}
                    <InputHookForm
                        // errors={errors.numberShoe}
                        optional
                        name='numberShoe'
                        control={control}
                        label='Número da sandália'
                        type='number'
                        placeholder='Digite o número da sandália'
                        valueInicial={guest.numberShoe || ''}
                    />
                    {/* <ErrorMessage
                        errors={errors}
                        name='numberShoe'
                        render={ErrorsComponent}
                    /> */}
                    <InputHookForm
                        // errors={errors.profession}
                        optional
                        name='profession'
                        control={control}
                        label='Profissão'
                        type='text'
                        placeholder='Qual sua profissão?'
                        valueInicial={guest.profession || ''}
                    />
                    {/* <ErrorMessage
                        errors={errors}
                        name='profession'
                        render={ErrorsComponent}
                    /> */}
                    <InputHookForm
                        // errors={errors.office}
                        optional
                        name='office'
                        control={control}
                        label='Cargo'
                        type='text'
                        placeholder='Qual seu cargo?'
                        valueInicial={guest.office || ''}
                    />
                    {/* <ErrorMessage
                        errors={errors}
                        name='office'
                        render={ErrorsComponent}
                    /> */}
                    <InputHookForm
                        // errors={errors.business}
                        optional
                        name='business'
                        control={control}
                        label='Nome da empresa'
                        type='text'
                        placeholder='Qual nome da empresa?'
                        valueInicial={guest.business || ''}
                    />
                    {/* <ErrorMessage
                        errors={errors}
                        name='business'
                        render={ErrorsComponent}
                    /> */}
                    <S.ButtomPhoto
                        onPress={pickerImageVacine}
                    >
                        {renderLoading(loadingVaccine)}
                        <S.ButtonPhotoImg source={imgVaccine} />
                        <S.IconCan name='camera' />
                    </S.ButtomPhoto>
                </S.Form>
            </S.Scroll>
            <AwesomeAlert
                show={openModalSuccess}
                title='Convidado editado'
                message='Convidado editado com sucesso.'
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='OK'
                confirmButtonColor='#98C828'
                onConfirmPressed={handleNavigationHost}
            />
        </S.Safe>
    );
}
