import styled from 'styled-components/native';
import { BlurView } from 'expo-blur';

export const Container = styled(BlurView)`
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
`;
