import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { CardHost, HostProps } from './components/CardHost';
import { Loading } from '../../../components/Loading';
import { Heading } from '../../../components/Heading';
import { hostsList, hostsFavorite, hostsListNoAdm } from '../../../services/hosts';
import { ButtonDrawer } from '../../../components/ButtonDrawer';
import { ListEmpty } from '../../../components/ListEmpty';
import { Can } from '../../../components/Can';
import { permissions } from '../../../utils/permissions';
import * as S from './styles';

interface HostsScreenProps {
    navigation: any
}

const isWeb = Platform.OS === 'web';

export function HostsScreen({ navigation }: HostsScreenProps) {
    const user = useSelector((state: any) => state?.user.user);

    const [hosts, setHosts] = useState<HostProps[]>([]);
    const [, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(true);
    const isFocused = useIsFocused();

    const { permissionMaster } = permissions;

    const handleNavigationForDetails = (host: any) => {
        navigation.navigate('HostsDetailsScreen', { host });
    };

    // eslint-disable-next-line consistent-return
    const getHosts = async () => {
        setRefreshing(true);
        setLoading(true);
        const { type } = user.user;
        try {
            const response = type === 'adm' ? await hostsList(user.user.id) : await hostsListNoAdm();
            setHosts(response.result.list);
            setLoading(false);
            setRefreshing(false);
        } catch (e) {
            setLoading(false);
            setRefreshing(false);
            return e;
        }

    };

    const favoriteHost = async (id: number, is_favorited: boolean) => {
        await hostsFavorite(id, is_favorited, user.user.id);
        getHosts();
    };

    const cardHost = (host) => {
        return (
            <CardHost
                favoriteHost={favoriteHost}
                host={host.item}
                onPress={() => {
                    handleNavigationForDetails(host.item);
                }}
            />
        );
    };

    const handleClickAddButton = () => {
        navigation.navigate('AddHostScreen');
    };

    useEffect(() => {
        setLoading(true);
        setHosts([]);
        if (isFocused) {
            getHosts();
        }
    }, [isFocused]);

    return (
        <S.Safe>
            {loading && <Loading /> }
            {!loading && hosts.length === 0 && <ListEmpty message='Nenhum anfitrião cadastrado' />}
            <S.Container>
                <S.Header>
                    {isWeb ? (<ButtonDrawer />) : null}
                    <Heading color='purpleDark' size='large'>
                        Anfitriões
                    </Heading>
                </S.Header>
                {!loading
                    && (
                        <S.HostList
                            data={hosts}
                            renderItem={cardHost}
                            // ListEmptyComponent={}
                        />
                    )}

                <Can role={permissionMaster}>
                    <S.AddButton onPress={handleClickAddButton}>
                        <FontAwesome5 name='plus' size={24} color='white' />
                    </S.AddButton>
                </Can>
            </S.Container>
        </S.Safe>
    );
}
