import React from 'react';
import * as S from './styles';

interface ErrosProps {
    message: String
}

export function Errors({ message }:ErrosProps) {
    return <S.Error accessible={false}>{message}</S.Error>;
}
