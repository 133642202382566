import * as React from 'react';
/* eslint-disable-next-line */
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
    <Svg
        xmlns='http://www.w3.org/2000/svg'
        width={18.414}
        height={15.925}
        {...props}
    >
        <G
            fill='none'
            stroke='#00e1ff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
        >
            <Path data-name='Caminho 83234' d='M1.414 1.899v5.2h5.2' />
            <Path data-name='Caminho 83235' d='M17.005 14.03v-5.2h-5.2' />
            <Path
                data-name='Caminho 83236'
                d='M15.222 5.653a6.587 6.587 0 0 0-4.6-4.447 6.045 6.045 0 0 0-5.917 1.86L1.414 6.423M17 9.502l-3.286 3.357a6.045 6.045 0 0 1-5.917 1.86 6.587 6.587 0 0 1-4.6-4.447'
            />
        </G>
    </Svg>
);

export default SvgComponent;
