import React, { useEffect, useState } from 'react';
import { TouchableWithoutFeedback, Platform, Modal } from 'react-native';
import { NavigationProp } from '@react-navigation/native';
import { ErrorMessage } from '@hookform/error-message';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as Progress from 'react-native-progress';

import * as S from './styles';
import { defaultTheme as theme } from '../../../styles/theme';

import { InputHookForm } from '../../../components/InputHookform';
import { InputSelect } from '../../../components/InputSelect';
import { Loading } from '../../../components/Loading';
import { Heading } from '../../../components/Heading';
import { Button } from '../../../components/Button';
import { Header } from '../../../components/Header';
import { Errors } from '../../../components/Errors';

import {
    registerAdministrators,
    editAdministrators,
} from '../../../services/administrators';
import { getAllEvents } from '../../../services/events';
import { admAllTypes } from '../utils/utils';

const isWeb = Platform.OS === 'web';

interface AddHostScreenProps {
    navigation: NavigationProp<{}>;
    route: any;
}

type EventListProps = {
    label: string;
    value: number;
};

export function AddAdministratorsScreen({
    navigation,
    route,
}: AddHostScreenProps) {
    const { user } = useSelector((state: any) => state.user.user);
    const { id, email, permission, id_events } = route.params.item;

    const [filtersSelectedCategories, setFiltersSelectedCategories] = useState(
        id_events || [],
    );
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [events, setEvents] = useState<EventListProps[]>([]);
    const [openModalError, setOpenModalError] = useState(false);
    const [modalEvents, setModalEvents] = useState(false);
    const [loading, setLoading] = useState(true);
    const [admType, setAdmType] = useState(permission || '');
    const [modalErrorMessage, setModalErrorMessage] = useState('');

    const isEditing = email;
    const typePassword = isEditing ? 'passwordNotRequired' : 'password';

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm();

    const handleNavigationHost = () => {
        const routes = [{ name: 'AdministratorsScreen' } as never];
        const reset = {
            index: 0,
            routes,
        };
        const reload = { reload: true };
        if (isWeb) {
            navigation.reset(reset);
        } else {
            navigation.navigate('AdministratorsScreen', reload);
        }
    };

    const handleCreateHost = async (data) => {
        const admTypeValidate = {
            type: 'validate',
            message: 'Selecione uma premissão para este adm',
        };
        const idEventsValidate = {
            type: 'validate',
            message: 'Selecione pelos menos um evento',
        };
        const passwordConfirmValidate = {
            type: 'validate',
            message: 'As senhas não conferem',
        };
        const events = admType === 'master' ? [] : filtersSelectedCategories;
        const dataConverted = {
            email: data.email,
            password: data.password,
            permission: admType,
            idEvents: events,
        };
        if (!admType) {
            setError('admType', admTypeValidate);
        } else if (filtersSelectedCategories.length === 0 && admType === 'receptionist') {
            setError('idEvents', idEventsValidate);
        } else if (data.passwordConfirm !== data.password) {
            setError('passwordConfirm', passwordConfirmValidate);
        } else {
            setLoading(true);
            if (isEditing) {
                editAdministrators(admType, id, dataConverted)
                    .then((response) => {
                        if (response.status === 200) {
                            setOpenModalSuccess(true);
                        } else {
                            setModalErrorMessage(response.message);
                            setOpenModalError(true);
                        }
                    })
                    .catch(() => {
                        setOpenModalError(true);
                    });
            } else {
                registerAdministrators(dataConverted)
                    .then((response) => {
                        if (response.status === 201) {
                            setOpenModalSuccess(true);
                        } else {
                            setModalErrorMessage(response.message);
                            setOpenModalError(true);
                        }
                    })
                    .catch(() => {
                        setOpenModalError(true);
                    });
            }
            setLoading(false);
        }
    };

    const getEvents = async () => {
        getAllEvents(user.id, user.type).then((response) => {
            delete response.result.list.main;
            const values = Object.values(response.result.list).flat();
            const formatted = values.map((e: any) => ({
                label: e.name,
                value: e.id,
            })) as EventListProps[];
            setEvents(formatted);
            setLoading(false);
        });
    };

    const handleOpenModal = () => {
        setModalEvents(!modalEvents);
    };

    function handleSelectedFilterCategories(filter: number) {
        if (!filtersSelectedCategories.includes(filter)) {
            const newFilterSelected = [
                ...filtersSelectedCategories,
                filter,
            ];
            setFiltersSelectedCategories(newFilterSelected);
        } else {
            setFiltersSelectedCategories((oldState) => oldState.filter((e) => e !== filter));
        }
    }

    function ErrorsComponent({ message }) {
        return (
            <Errors message={message} />
        );
    }

    function renderSelectBox() {
        return events.map((e: EventListProps) => {
            if (!filtersSelectedCategories.includes(e.value)) {
                return null;
            }

            const value = Number(e.value);
            const label = String(e.label);

            function handleFilter() {
                handleSelectedFilterCategories(value);
            }

            return (
                <S.FilterForm
                    key={value}
                    onPress={handleFilter}
                    selected={filtersSelectedCategories.includes(value)}
                >
                    <S.FilterText
                        selected={filtersSelectedCategories.includes(value)}
                    >
                        {label}
                    </S.FilterText>
                    <S.FilterFormIconClose name='close' />
                </S.FilterForm>
            );
        });
    }

    function renderModalFilterBox() {
        return events.map((e: EventListProps) => {
            const value = Number(e.value);
            const label = String(e.label);

            function handleFilter() {
                handleSelectedFilterCategories(value);
            }

            return (
                <S.Filter
                    key={value}
                    onPress={handleFilter}
                    selected={filtersSelectedCategories.includes(value)}
                >
                    <S.FilterText
                        selected={filtersSelectedCategories.includes(value)}
                    >
                        {label}
                    </S.FilterText>
                </S.Filter>
            );
        });
    }

    function renderEventsPicker() {
        if (admType !== 'receptionist') {
            return null;
        }
        return (
            <>
                <S.ButtonPicker errors={errors.idEvents}>
                    <S.ButtonPickerHeader onPress={handleOpenModal}>
                        <S.ButtonPickerText>Eventos</S.ButtonPickerText>
                        <S.ButtonPickerIcon name='down' />
                    </S.ButtonPickerHeader>
                    <S.EventsSelectedBox>
                        {renderSelectBox()}
                    </S.EventsSelectedBox>
                </S.ButtonPicker>
                <ErrorMessage
                    errors={errors}
                    name='idEvents'
                    render={ErrorsComponent}
                />
            </>
        );
    }

    function handleCloseModalError() {
        setOpenModalError(!openModalError);
    }

    useEffect(() => {
        clearErrors('idEvents');
        clearErrors('admType');
    }, [filtersSelectedCategories, admType]);

    useEffect(() => {
        navigation.setOptions({
            title: 'Adicionar administrador',
        });
        getEvents();
    }, []);

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <S.Safe>
            <S.Container>
                <Header
                    title='Criar Administrador'
                    navigation={navigation}
                    handleGoback={handleNavigationHost}
                />
                <S.ProgressContainer>
                    <Progress.Bar
                        progress={0.5}
                        color={theme.colors.purpleDark}
                        height={6}
                        borderRadius={10}
                        width={null}
                    />
                </S.ProgressContainer>
                <S.Content>
                    <Heading color='gray' size='intermediate'>
                        Informações do Administrador
                    </Heading>
                    <S.Form>
                        <InputHookForm
                            valueInicial={email}
                            errors={errors.email}
                            name='email'
                            control={control}
                            label='E-mail'
                            type='email'
                            placeholder='Digite o email do administrador'
                        />
                        <ErrorMessage
                            errors={errors}
                            name='email'
                            render={ErrorsComponent}
                        />
                        <InputHookForm
                            errors={errors.password}
                            name='password'
                            control={control}
                            label='Senha'
                            type={typePassword}
                            placeholder='Digite a senha do administrador'
                        />
                        <ErrorMessage
                            errors={errors}
                            name='password'
                            render={ErrorsComponent}
                        />
                        <InputHookForm
                            errors={errors.passwordConfirm}
                            name='passwordConfirm'
                            control={control}
                            label='Confirmar senha'
                            type={typePassword}
                            placeholder='Confirme a senha do administrador'
                        />
                        <ErrorMessage
                            errors={errors}
                            name='passwordConfirm'
                            render={ErrorsComponent}
                        />
                        <InputSelect
                            list={admAllTypes}
                            setProps={setAdmType}
                            initialValue={admType}
                            label='Permissões'
                            errors={errors.idCategory}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='admType'
                            render={ErrorsComponent}
                        />
                        {renderEventsPicker()}
                        <S.ContainerButton>
                            <Button
                                title='FINALIZAR'
                                onClick={handleSubmit(handleCreateHost)}
                            />
                        </S.ContainerButton>
                    </S.Form>
                </S.Content>
            </S.Container>
            <Modal
                animationType='slide'
                transparent
                visible={modalEvents}
                onRequestClose={handleOpenModal}
            >
                <TouchableWithoutFeedback onPress={handleOpenModal}>
                    <S.ModalContainer>
                        <S.ModalContent>
                            <S.FilterBox>{renderModalFilterBox()}</S.FilterBox>
                            <S.ContainerButtonModal>
                                <Button
                                    title='CONTINUAR'
                                    onClick={handleOpenModal}
                                    loading={loading}
                                />
                            </S.ContainerButtonModal>
                        </S.ModalContent>
                    </S.ModalContainer>
                </TouchableWithoutFeedback>
            </Modal>
            <AwesomeAlert
                show={openModalError}
                title='Erro ao criar Administrador'
                message={modalErrorMessage}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='OK'
                confirmButtonColor='#98C828'
                onConfirmPressed={handleCloseModalError}
            />
            <AwesomeAlert
                show={openModalSuccess}
                title='Administrador cadastrado'
                message='Administrador cadastrado com sucesso.'
                closeOnHardwareBackPress={false}
                closeOnTouchOutside={false}
                showConfirmButton
                confirmText='OK'
                confirmButtonColor='#98C828'
                onConfirmPressed={handleNavigationHost}
            />
        </S.Safe>
    );
}
