/* eslint-disable */
import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

interface QrProps{
  props?: SvgProps;
  color: string
}

const SvgComponent = ({props, color}: QrProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} {...props}>
    <G data-name="Componente 76 \u2013 2">
      <Path
        data-name="maximize"
        d="M7.667 1h-4A2.667 2.667 0 0 0 1 3.667v4m24 0v-4A2.667 2.667 0 0 0 22.333 1h-4m0 24h4A2.667 2.667 0 0 0 25 22.333v-4m-24 0v4A2.667 2.667 0 0 0 3.667 25h4"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <G
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <Path data-name="Ret\xE2ngulo 147608" d="M5 5h6v6H5z" />
        <Path data-name="Ret\xE2ngulo 147609" d="M15 5h6v6h-6z" />
        <Path data-name="Ret\xE2ngulo 147610" d="M15 15h6v6h-6z" />
        <Path data-name="Ret\xE2ngulo 147611" d="M5 15h6v6H5z" />
      </G>
    </G>
  </Svg>
)

export default SvgComponent
