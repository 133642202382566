import styled from 'styled-components/native';
import { StyleSheet, Platform } from 'react-native';

import { RFValue } from 'react-native-responsive-fontsize';

import { Entypo } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../styles/theme';


const isWeb = Platform.OS === 'web';

interface ContainerProps {
    marginRight?: number,
    width?: string,
    errors: any,
    description?: boolean,
    alignItems: string | undefined
}

interface LabelProps {
    errors: any
}

export const InputMask = StyleSheet.create(
    {
        input: {
            width: '100%',
            fontFamily: `${theme.font.regular}`,
            color: `${theme.colors.gray}`,
            marginTop: 2,
            outlineWidth: 0,
            textAlignVertical: 'top',
        },
    },
);

export const Container = styled.View<ContainerProps>`
    border: 1px ${(props) => (props.errors ? `${theme.colors.red}` : `${theme.colors.grayLight}`)};
    border-radius: 4px;
    padding: 9px 12px;
    margin-top: 12px;
    margin-right: ${(props) => (props.marginRight ? props.marginRight : 0)};
    flex-direction: row;
    align-items: ${({ alignItems }) => (alignItems === undefined ? 'center' : alignItems)};
    justify-content: space-between;
    width: ${(props) => (props.width ? props.width : '100%')};
    ${(props) => props.description && 'height: 100px'};
`;

export const Field = styled.View`
    margin-right: 8px;
    flex: 1;
`;

export const Label = styled.Text<LabelProps>`
    color: ${(props) => (props.errors ? `${theme.colors.red}` : `${theme.colors.gray}`)};
    font-family: ${theme.font.bold};
    font-size: ${isWeb ? '0.75rem' : '12px'};
`;

interface InputProps {
    isLarge?: boolean
}

export const Input = styled.TextInput<InputProps>`
    width: 100%;
    height: ${(props) => (props.isLarge && !isWeb ? '80px' : '20px')};
    font-family: ${theme.font.regular};
    color: ${theme.colors.gray};
    font-size: ${isWeb ? '0.75rem' : `${RFValue(12)}px`};
    margin-top: 2px;
    outline-width:0px ;
`;


export const PasswordButton = styled.TouchableOpacity`
`;


export const PasswordIconButton = styled(Entypo)`
    font-size: 15px;
    color: ${theme.colors.grayLight};
`;
