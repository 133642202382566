import { Platform } from 'react-native';
import { getToken } from './functions';
import { api } from './api';

const uploadFile = async (file: any) => {
    const fileName = file.name;
    const fileType = file.type;
    const { uri } = file;
    const data: FormData = new FormData();

    if (Platform.OS === 'web') {
        const byteString = atob(uri.split(',')[1]);

        const ab = new ArrayBuffer(byteString.length);
        const arr = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) arr[i] = byteString.charCodeAt(i);

        const blob = new Blob([arr], {
            type: fileType || 'image/png',
        });

        const newFile = new File([blob], `${fileName}`);

        data.append('file', newFile);
    } else {
        data.append('file', file);
    }

    const response = await api.post('/uploads', data, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

const uploadCsv = async (file: any, idEvent: number) => {
    const token = await getToken();
    const { uri } = file;
    const data = {
        base64: uri,
        idEvent: Number(idEvent),
    };
    const response = await api.post('/guests/import', data, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': token,
        },
    });
    return response.data;
};

export { uploadFile, uploadCsv };
