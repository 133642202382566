import styled from 'styled-components/native'

import { defaultTheme as theme } from '../../styles/theme'

import LogoSvg from '../../assets/images/svgs/logoSvg'

export const Safe = styled.SafeAreaView`
    flex: 1;
`

export const Container = styled.View`
    padding: 50px 40px;
    background-color: #fff;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`


export const Logo = styled(LogoSvg)`
    align-self: center;
    margin-bottom: 62.27px;
`

export const Navigation = styled.View`
    
`




export const DrawerItem = {
    backgroundColor: "#fff"
}

export const labelStyle = {
    fontFamily: `${theme.font.regular}`,
    fontSize: 14,
}

