import { MotiImage, MotiView } from 'moti';
import { SingInForm } from '../../../components/SingInForm';
import { motiStyles } from '../motiStyles';

import logoText from '../../../assets/images/icon-text.png';
import qrCode from '../../../assets/images/qr-code.png';
import topImage from '../../../assets/images/qr-codes-desktop-top.png';
import bottomImage from '../../../assets/images/qr-codes-desktop-bottom.png';

import * as S from '../styles';

export type DesktopViewProps = {
    navigation: any
}

export function DesktopView({ navigation }: DesktopViewProps) {
    return (
        <>
            <S.Wrapper>
                <MotiImage
                    resizeMode='cover'
                    source={topImage}
                    style={motiStyles.topImageDesktop}
                />
                <MotiView
                    style={motiStyles.containerLogoDesktop}
                    from={{
                        opacity: 1,
                    }}
                    animate={{
                        opacity: 0,
                    }}
                    transition={{
                        type: 'timing',
                        duration: 100,
                        delay: 800,
                    }}
                >
                    <S.TextLogo source={qrCode} />
                    <MotiImage
                        style={motiStyles.logo}
                        source={logoText}
                    />
                </MotiView>
                <MotiImage
                    resizeMode='cover'
                    source={bottomImage}
                    style={motiStyles.bottomImageDesktop}
                    from={{
                        top: '85%',
                    }}
                    animate={{
                        top: '12%',
                    }}
                    transition={{
                        type: 'timing',
                        duration: 2000,
                    }}
                />
            </S.Wrapper>
            <MotiView>
                <MotiView
                    style={motiStyles.singInFormDesktopContainer}
                    from={{
                        top: 1500,
                    }}
                    animate={{
                        top: 300,
                    }}
                    transition={{
                        type: 'timing',
                        duration: 2000,
                    }}
                >
                    <SingInForm navigation={navigation} />
                </MotiView>
            </MotiView>
        </>
    );
}
