/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import * as Progress from 'react-native-progress';
import * as ImagePicker from 'expo-image-picker';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as Linking from 'expo-linking';
import { FlatList } from 'react-native';
import * as S from './styles';
import { Header } from '../../../components/Header';
import { Heading } from '../../../components/Heading';
import { defaultTheme as theme } from '../../../styles/theme';
import { uploadFile } from '../../../services/toolsApi';
import userPhoto from '../../../assets/images/user/user-photo.png';
import { LoadingPhoto } from '../../../components/LoadingPhoto';
import { Button } from '../../../components/Button';
import { registerGuest } from '../../../services/events';

interface DataProps {
    id: number,
    name: string,
    telephone: string,
    email: string,
    photo?: string
}

export function FormPhotoFamily({ navigation, route }) {

    const [progress, setProgress] = useState(0.5);
    const [guests, setGuests] = useState<DataProps[]>([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [openModalNotification, setOpenModalNotification] = useState(false);
    const [titleModalNotification, setTitleModalNotification] = useState('');
    const [messageModalNotification, setMessageModalNotification] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const data = route.params;

    const handleOpenModalNotification = () => {
        setOpenModalNotification(!openModalNotification);
    };

    function insertUrlImage(id: number, url: string, loading: boolean) {
        // eslint-disable-next-line no-shadow
        const newList = guests.map((guests) => {
            return guests.id === id ? { ...guests, photo: url, loading } : guests;
        });
        setLoadingImage(loading);
        setGuests(newList);
    }

    async function pickImageHostImage(idGuest: number) {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
            base64: true,
        });

        if (!result.cancelled) {
            insertUrlImage(idGuest, result.uri, true);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                insertUrlImage(idGuest, response.result, false);
            } else {
                return false;
            }
        }
        return '';
    }


    const handleNavigationForm = () => {
        Linking.openURL('http://confirma.puzli.com.br/ok');
    };

    async function handleContinue() {
        if (data.event.is_vaccination) {
            navigation.navigate('FormVaccineFamily', { guests, event: data.event });
        } else {
            try {
                setLoading(true);
                const response = await registerGuest(guests, data.event.hash);

                if (response.status === 200) {
                    setLoading(false);
                    setTitleModalNotification('Convidados cadastrados com sucesso!');
                    handleOpenModalNotification();
                } else {
                    setLoading(false);
                    setTitleModalNotification('Erro ao cadastrar!');
                    setMessageModalNotification(response.message);
                    handleOpenModalNotification();
                }
            } catch (e) {
                setLoading(false);
                setTitleModalNotification('Erro ao cadastrar!');
                setMessageModalNotification(e.message);
                handleOpenModalNotification();
                return false;
            }

        }
        return false;
    }


    useEffect(() => {
        if (guests.length === 0) {
            setGuests(data.guestsData);
        } else {
            const newList = data.guestsData.map((el) => {
                const guest = guests.find((e) => {
                    return e.id === el.id;
                });
                return {
                    ...el,
                    photo: guest?.photo,
                };
            });
            setGuests(newList);
        }
        data.event.is_vaccination ? setProgress(0.6) : setProgress(1);
    }, [data]);

    useEffect(() => {
        const havePictures = guests.every((guest) => !!guest.photo);
        setButtonDisabled(!havePictures);
    }, [data, guests]);


    const goBack = () => {
        navigation.navigate('FormScreen', { event: data.event.hash });
    };

    useEffect(() => {
        navigation.setOptions({
            title: 'Foto dos convidados',
        });
    }, [navigation]);

    function formsPhoto(guest) {

        return (
            <S.BoxPhoto>
                <Heading color='gray' size='intermediate'>
                    {guest.item.name}
                </Heading>
                <S.ButtomPhoto
                    disabled={loadingImage}
                    onPress={() => {
                        pickImageHostImage(guest.item.id);
                    }}
                >
                    <S.ButtonPhotoImg source={guest.item.photo ? { uri: guest.item.photo } : userPhoto} />
                    <S.IconCan name='camera' />
                    {guest.item.loading && <LoadingPhoto />}
                </S.ButtomPhoto>
                <S.ButtonLabel>Insira uma foto</S.ButtonLabel>
            </S.BoxPhoto>
        );
    }

    return (
        <>
            <S.Safe>
                <S.Container>
                    <Header title={`Formulário - ${data.event?.name}`} navigation={navigation} marginBottom={16} handleGoback={goBack} />
                    <S.ProgressContainer>
                        <Progress.Bar progress={progress} color={theme.colors.purpleDark} height={6} borderRadius={10} width={null} />
                    </S.ProgressContainer>
                    <S.Box>
                        <S.Content>
                            <Heading color='gray' size='intermediate'>Foto de perfil</Heading>

                            <FlatList keyExtractor={(item) => String(item.id)} renderItem={formsPhoto} data={guests} />

                            <S.ButtonContainer>
                                <Button loading={loading} title={data.event?.is_vaccination ? 'CONTINUAR' : 'FINALIZAR'} onClick={handleContinue} disabled={buttonDisabled} />
                            </S.ButtonContainer>

                        </S.Content>
                    </S.Box>
                </S.Container>
            </S.Safe>
            <AwesomeAlert
                show={openModalNotification}
                title={titleModalNotification}
                message={messageModalNotification}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='OK'
                confirmButtonColor='#98C828'
                onConfirmPressed={handleNavigationForm}
            />
        </>
    );
}
