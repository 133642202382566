import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { CardHost, HostProps } from './components/CardHost';
import { Loading } from '../../../components/Loading';
import { Heading } from '../../../components/Heading';
import { getAllAdministrators } from '../../../services/administrators';
import { ButtonDrawer } from '../../../components/ButtonDrawer';
import { ListEmpty } from '../../../components/ListEmpty';
import { Can } from '../../../components/Can';
import { permissions } from '../../../utils/permissions';
import * as S from './styles';

interface HostsScreenProps {
    navigation: any;
}

const isWeb = Platform.OS === 'web';

export function AdministratorsScreen({ navigation }: HostsScreenProps) {
    const { user } = useSelector((state: any) => state.user.user);
    const [hosts, setHosts] = useState<HostProps[]>([]);
    const [loading, setLoading] = useState(true);
    const isFocused = useIsFocused();

    const { permissionMaster } = permissions;

    const getHosts = async () => {
        getAllAdministrators(user.id).then((response) => {
            setHosts(response.result.list);
            setLoading(false);
        });
    };

    const cardHost = (items: any) => {
        const { item } = items;

        function handleClick() {
            handleClickAddButton(item);
        }

        return (
            <CardHost host={item} onPress={handleClick} />
        );
    };

    const handleClickAddButton = (item: any) => {
        navigation.navigate('AddAdministratorsScreen', { item });
    };

    const renderLoading = () => {
        if (!loading && hosts.length !== 0) {
            return null;
        }
        if (loading) {
            return (
                <Loading />
            );
        }
        return (
            <ListEmpty message='Nenhum Administrador cadastrado' />
        );
    };

    const renderButtonDrawer = () => {
        if (!isWeb) {
            return null;
        }
        return (
            <ButtonDrawer type='drawer' />
        );
    };

    const renderList = () => {
        if (loading) {
            return null;
        }
        return (
            <S.HostList data={hosts} renderItem={cardHost} />
        );
    };

    useEffect(() => {
        const options = {
            title: 'Administradores',
        };
        navigation.setOptions(options);
        setLoading(true);
        setHosts([]);
        if (isFocused) {
            getHosts();
        }
    }, [isFocused]);

    return (
        <S.Safe>
            {renderLoading()}
            <S.Container>
                <S.Header>
                    {renderButtonDrawer()}
                    <Heading color='purpleDark' size='large'>
                        Administradores
                    </Heading>
                </S.Header>
                {renderList()}
                <Can role={permissionMaster}>
                    <S.AddButton onPress={handleClickAddButton}>
                        <FontAwesome5 name='plus' size={24} color='white' />
                    </S.AddButton>
                </Can>
            </S.Container>
        </S.Safe>
    );
}
