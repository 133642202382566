import { StyleSheet } from 'react-native';
import { defaultTheme as theme } from '../../styles/theme';

export const motiStyles = StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100%',
    },
    containerDesktop: {},
    containerLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '7%',
        width: '100%',
        height: '90%',
        backgroundColor: '#FAFAFA',
    },
    containerLogoDesktop: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        bottom: 225,
    },
    logo: {
        width: 200,
        height: 200,
    },
    squad: {
        width: 250,
        height: 250,
        backgroundColor: 'transparent',
        opacity: 0,
    },
    bottomImageStyles: {
        width: '100%',
        backgroundColor: '#FAFAFA',
        backfaceVisibility: 'visible',
    },
    containerBottomImage: {
        width: '100%',
        height: '10%',
        backgroundColor: '#FAFAFA',
    },
    bottomImageDesktop: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: '85%',
        width: '100vw',
        height: '20%',
        backgroundColor: '#FAFAFA',
        zIndex: theme.layers.base,
    },
    topImageDesktop: {
        width: '100%',
        height: '15%',
        zIndex: theme.layers.overlay,
    },
    topImageStyle: {
        position: 'relative',
        zIndex: theme.layers.overlay2,
        // backgroundColor: theme.colors.white,
    },
    singInForm: {
        position: 'absolute',
        bottom: -1000,
        width: '100%',
        height: '100%',
        backgroundColor: '#FAFAFA',
    },
    singInFormDesktopContainer: {
        // position: 'absolute',
        // bottom: 0,
        // top: '100%',
        // left: '35%',
        // right: 0,
        alignSelf: 'center',
        zIndex: theme.layers.overlay4,
    },
});
