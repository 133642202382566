import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { ModalChartInfo } from '../../../../../components/ModalChartInfo';

interface AllInfoChartProps {
    name: string,
}

interface ChartInfosProps {
    percentage?: any;
    title: string;
    secondInfo?: boolean;
    color: string;
    thirdInfo?: boolean,
    allInfoItem?: Array<AllInfoChartProps>,
    total: number
}

export function ChartInfos({ percentage, title, secondInfo, color, thirdInfo, allInfoItem, total }: ChartInfosProps) {
    const [, setCurrentPosition] = useState(0); // currentPosition
    const [modalVisible, setModalVisible] = useState(false);

    const totalValue = total || 0;
    const messagePercentage = `${percentage > 0 ? percentage : 0}% (${totalValue})`;
    const percentageFormatted = percentage > 0 ? percentage / 100 : 0;

    useEffect(() => {
        if (percentageFormatted >= 0 && percentageFormatted <= 0.25) {
            setCurrentPosition(1);
        } else if (percentageFormatted > 0.25 && percentageFormatted <= 0.5) {
            setCurrentPosition(2);
        } else if (percentageFormatted > 0.5 && percentageFormatted <= 0.75) {
            setCurrentPosition(3);
        } else if (percentageFormatted > 0.75 && percentageFormatted <= 1) {
            setCurrentPosition(4);
        }
    }, [percentage]);

    function handleTitlePress() {
        setModalVisible(!modalVisible);
    }

    return (
        <S.Container position={1} secondInfo={secondInfo} thirdInfo={thirdInfo}>
            <S.ContainerInfosPointer>
                <S.ContainerPointer>
                    <S.Pointer color={color} />
                </S.ContainerPointer>
                <S.ContainerInfos>
                    <S.LabelButton disabled={!allInfoItem?.length} onPress={handleTitlePress}>
                        <S.Title>{title}</S.Title>
                    </S.LabelButton>
                    <S.Label>{messagePercentage}</S.Label>
                </S.ContainerInfos>
            </S.ContainerInfosPointer>
            <ModalChartInfo
                isOpen={modalVisible}
                allInfoChart={allInfoItem}
                setIsOpen={setModalVisible}
            />
        </S.Container>
    );
}
