import React, { useState, useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';

import { Heading } from '../../../../../components/Heading';
import { Button } from '../../../../../components/Button';
import { InputHookForm } from '../../../../../components/InputHookform';
import { InputSelect } from '../../../../../components/InputSelect';
import { Errors } from '../../../../../components/Errors';

import { numberOfGuests, numberOfGuestsWithDualLink } from '../../utils/utils';
import { registerGuest } from '../../../../../services/events';

import * as S from './styles';

interface FormFamily {
    navigation: any;
    event: any;
    handleOpenModalNotification: () => void;
    setTitleModalNotification: (text: string) => void;
    setMessageModalNotification: (text: string) => void;
}

export function FormFamily(props: FormFamily) {
    const { navigation, event, handleOpenModalNotification, setTitleModalNotification, setMessageModalNotification } = props;

    const [guestsNumber, setGuestsNumber] = useState(2);
    const [amountOfGuests, setAmountOfGuests] = useState([]);
    const [loading, setLoading] = useState(false);

    const eventData = event;
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    async function saveGuests(data: any) {
        setLoading(true);
        try {
            const response = await registerGuest(data, event.hash);
            if (response.status === 200) {
                setLoading(false);
                setTitleModalNotification('Convidado cadastrado!');
                setMessageModalNotification('Aguarde, pois em breve você receberá o seu QR Code individual no whatsapp cadastrado. Obrigado!');
                handleOpenModalNotification();
                reset();
            } else {
                setLoading(false);
                setTitleModalNotification('Erro ao cadastrar!');
                setMessageModalNotification(response.message);
                handleOpenModalNotification();
                reset();
            }
        } catch (e: any) {
            setLoading(false);
            setTitleModalNotification('Erro ao cadastrar!');
            setMessageModalNotification(e.message);
            handleOpenModalNotification();
            reset();
        }
    }

    function handleNavigation(data) {
        const guestsData = [] as any[];
        const size = guestsNumber;
        for (let i = 0; i < size; i++) {
            const isMain = i === 0 || null;
            guestsData.push({
                id: i + 1,
                name: data[`name${i + 1}`],
                telephone: data[`telephone${i + 1}`],
                email: data[`email${i + 1}`],
                rg: data[`rg${i + 1}`],
                cpf: data[`cpf${i + 1}`],
                address: data[`address${i + 1}`],
                numberShoe: data[`numberShoe${i + 1}`],
                profession: data[`profession${i + 1}`],
                office: data[`office${i + 1}`],
                business: data[`business${i + 1}`],
                isMain,
                photo: null,
                vaccinationCard: null,
            });
        }
        if (event.is_photo_guests) {
            navigation.navigate('FormPhotoFamily', {
                guestsData,
                event: eventData,
            });
        } else if (!event.is_photo_guests && event.is_vaccination) {
            navigation.navigate('FormVaccineFamily', {
                guests: guestsData,
                event: eventData,
            });
        } else {
            saveGuests(guestsData);
        }
    }

    useEffect(() => {
        const guestsForm: any = [];
        const size = guestsNumber;
        for (let i = 0; i < size; i++) {
            guestsForm.push({
                id: i + 1,
            });
        }
        setAmountOfGuests(guestsForm);
    }, [guestsNumber]);

    function ErrorsComponent({ message }) {
        return <Errors message={message} />;
    }

    function returnStringHeaders(id: number) {
        if (id === 1) {
            return 'Convite principal';
        }

        return `Convite ${id}`;
    }

    function renderFormList() {
        return amountOfGuests.map((e: any) => {
            const header = returnStringHeaders(e.id);

            return (
                <S.Form key={e.id}>
                    <Heading color='gray' size='intermediate'>
                        {header}
                    </Heading>
                    <S.Inputs>
                        <InputHookForm
                            errors={errors[`name${e.id}`]}
                            name={`name${e.id}`}
                            control={control}
                            label='Nome Completo'
                            type='text'
                            placeholder='Digite seu nome completo'
                        />
                        <ErrorMessage
                            errors={errors}
                            name={`name${e.id}`}
                            render={ErrorsComponent}
                        />
                        <InputHookForm
                            errors={errors[`telephone${e.id}`]}
                            name={`telephone${e.id}`}
                            control={control}
                            label='Whatsapp'
                            type='phone'
                            placeholder='Digite seu número'
                        />
                        <ErrorMessage
                            errors={errors}
                            name={`telephone${e.id}`}
                            render={ErrorsComponent}
                        />
                        {event.is_email ? (
                            <>
                                <InputHookForm
                                    errors={errors[`email${e.id}`]}
                                    name={`email${e.id}`}
                                    control={control}
                                    label='Email'
                                    type='email'
                                    placeholder='Digite seu email'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`email${e.id}`}
                                    render={ErrorsComponent}
                                />
                            </>
                        ) : null}
                        {event.is_rg ? (
                            <>
                                <InputHookForm
                                    errors={errors[`rg${e.id}`]}
                                    name={`rg${e.id}`}
                                    control={control}
                                    label='RG'
                                    type='number'
                                    placeholder='Digite seu RG'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`rg${e.id}`}
                                    render={ErrorsComponent}
                                />
                            </>
                        ) : null}
                        {event.is_cpf ? (
                            <>
                                <InputHookForm
                                    errors={errors[`cpf${e.id}`]}
                                    name={`cpf${e.id}`}
                                    control={control}
                                    label='CPF'
                                    type='cpf'
                                    placeholder='Digite seu CPF'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`cpf${e.id}`}
                                    render={ErrorsComponent}
                                />
                            </>
                        ) : null}
                        {event.is_address ? (
                            <>
                                <InputHookForm
                                    errors={errors[`address${e.id}`]}
                                    name={`address${e.id}`}
                                    control={control}
                                    label='Endereço'
                                    type='text'
                                    placeholder='Digite seu endereço'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`address${e.id}`}
                                    render={ErrorsComponent}
                                />
                            </>
                        ) : null}
                        {event.is_number_shoe ? (
                            <>
                                <InputHookForm
                                    errors={errors[`numberShoe${e.id}`]}
                                    name={`numberShoe${e.id}`}
                                    control={control}
                                    label='Número da sandália'
                                    type='number'
                                    placeholder='Digite o número da sandália'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`numberShoe${e.id}`}
                                    render={ErrorsComponent}
                                />
                            </>
                        ) : null}
                        {event.is_profession ? (
                            <>
                                <InputHookForm
                                    errors={errors[`profession${e.id}`]}
                                    name={`profession${e.id}`}
                                    control={control}
                                    label='Profissão'
                                    type='text'
                                    placeholder='Qual sua profissão?'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`profession${e.id}`}
                                    render={ErrorsComponent}
                                />
                            </>
                        ) : null}
                        {event.is_office ? (
                            <>
                                <InputHookForm
                                    errors={errors[`office${e.id}`]}
                                    name={`office${e.id}`}
                                    control={control}
                                    label='Cargo'
                                    type='text'
                                    placeholder='Qual seu cargo?'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`office${e.id}`}
                                    render={ErrorsComponent}
                                />
                            </>
                        ) : null}
                        {event.is_business ? (
                            <>
                                <InputHookForm
                                    errors={errors[`business${e.id}`]}
                                    name={`business${e.id}`}
                                    control={control}
                                    label='Nome da empresa'
                                    type='text'
                                    placeholder='Qual nome da empresa?'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name={`business${e.id}`}
                                    render={ErrorsComponent}
                                />
                            </>
                        ) : null}
                    </S.Inputs>
                </S.Form>
            );
        });
    }

    return (
        <S.Container>
            <InputSelect
                list={eventData.dual_link ? numberOfGuestsWithDualLink : numberOfGuests}
                setProps={setGuestsNumber}
                initialValue={eventData.dual_link ? 1 : 2}
            />
            <S.Forms>
                <Heading color='gray' size='intermediate'>
                    Informações Pessoais
                </Heading>
                {renderFormList()}
                <S.CheckboxContent>
                    {/* <S.CheckboxText>
                        Ao clicar no botão "Continuar" abaixo, você aceita os Termos e Condições e reconhece que seus dados serão utilizados conforme descrito na
                        <S.CheckboxLink
                            href='https://www.puzli.com.br/lgpd'
                            target='_blank'
                        >
                            {' Política de Privacidade '}
                        </S.CheckboxLink>
                        da Puzli.
                    </S.CheckboxText> */}
                    <S.CheckboxText>
                        Ao clicar no botão "Continuar" abaixo, declaro a veracidade das informações prestadas e tenho ciência dos termos e condições na
                        <S.CheckboxLink
                            href='https://puzli.com.br/lgpd'
                            target='_blank'
                        >
                            {' Política de Privacidade '}
                        </S.CheckboxLink>
                        da Puzli, prestando meu consentimento inequívoco em conformidade com o teor da Lei n 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados).
                    </S.CheckboxText>
                </S.CheckboxContent>
                <ErrorMessage
                    errors={errors}
                    name='checkbox'
                    render={ErrorsComponent}
                />
                <S.ContainerButton>
                    <Button
                        loading={loading}
                        title='CONTINUAR'
                        onClick={handleSubmit(handleNavigation)}
                    />
                </S.ContainerButton>
            </S.Forms>
        </S.Container>
    );
}
