import styled from 'styled-components/native';
import { Platform, Dimensions } from 'react-native';

import { BlurView } from 'expo-blur';

import { RFValue, RFPercentage } from 'react-native-responsive-fontsize';

import { Ionicons, AntDesign, FontAwesome } from '@expo/vector-icons';

import { defaultTheme as theme } from '../../../styles/theme';

const windowWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === 'web';

interface GoBackButtonProps {
    top: number
}

export const Safe = styled.View`
    flex:1;
`;

export const Scroll = styled.ScrollView`
    padding-bottom: ${RFPercentage(5)}px;
    margin-bottom: 40px;
`;

export const HeaderWeb = styled.View`
    background-color: #00000005;
    padding: 1rem 1.25rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1.875rem;
`;

export const HeaderWebButton = styled.TouchableOpacity`

`;

export const EventItem = styled.View`
    align-items: center;
    justify-content: center;
    padding: 6px 20px;
    border-radius: 15px;
    margin: 6px 5px;
    background-color: rgba(82, 9, 168, 0.5);
`;

export const ContainerEvents = styled.ScrollView.attrs(
    {
        contentContainerStyle: {
            // flex: 1,
            // justifyContent: 'space-between',
            // paddingBottom: 20,
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: 10,

        },
    },
)`
    max-height: 120px;
`;

export const EventItemTitle = styled.Text`
    color: #FAFAFA;
`;

export const ButtonOptionsTrigger = styled.View`
    border: 1px solid ${theme.colors.grayLight};
    border-radius: 4px;
    height: 32px;
    width: ${isWeb ? '2.125rem' : `${RFValue(34)}px`};
    align-items: center;
    justify-content: center;
`;

export const HeaderWebButtonIcon = styled(AntDesign)`
    color: ${theme.colors.gray};
    font-size: 1.25rem;
`;

export const Header = styled.ImageBackground`
    height: ${isWeb && windowWidth > 650 ? '16.875rem' : '270px'};
    ${isWeb && windowWidth > 650 && 'width: 23.5rem; align-self: center'}
    background-color: #B2BEC3;
`;

export const IconUserEmpty = styled(FontAwesome)`
    font-size: ${RFValue(150)}px;
    color: ${theme.colors.white};
    position: absolute;
    top:25%;
    left: 35%;
`;
export const UserPhoto = styled.Image`
    height: ${isWeb && windowWidth > 650 ? '16.875rem' : '270px'};
    ${isWeb && windowWidth > 650 && 'width: 23.5rem'};
`;

export const GoBackButton = styled.TouchableOpacity<GoBackButtonProps>`
    position: absolute;
    top: ${({ top }) => `${top + 12}px`};
    margin-left: ${RFValue(12)}px;
    width: 45px;
    height: 40px;
`;

export const BlurGoBackButton = styled(BlurView)`
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 40px;
    overflow: hidden;
    border-radius: 4px;
`;

export const GoBackButtonIcon = styled(Ionicons)`
    font-size: ${RFValue(26)}px;
    color: ${theme.colors.black};
`;

export const HostNameDivider = styled.View`
    flex-direction:row;
    align-items: center;
`;

export const BoxHostName = styled.View`
    height: ${isWeb && windowWidth > 650 ? '4.25rem' : `${RFPercentage(10)}px`};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px ${isWeb && windowWidth > 650 ? '59' : '23'}px;
    border-bottom-width: 0.3px;
    border-bottom-color: ${theme.colors.lightGray};
    ${isWeb && windowWidth > 650 && 'width: 30.875rem; align-self: center; margin-top: 10px'}
`;

export const HostName = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '1.125rem' : '18px'};
    font-family: ${theme.font.bold};
    color: ${theme.colors.purpleDark};
    margin-right: 10px;
`;
export const HostInfos = styled.View`
    padding: ${RFValue(10)}px ${isWeb && windowWidth > 650 ? '0px' : '20px'};
    ${isWeb && windowWidth > 650 && 'width: 30.875rem; align-self: center;'};
    margin-bottom: 30px;
`;

export const ImageBottom = styled.ImageBackground.attrs(isWeb ? {
    resizeMode: 'repeat',
} : {})`
    height: ${isWeb && windowWidth > 650 ? `${RFPercentage(2)}px` : `${RFPercentage(5)}px`};
    width: 100%;
    position: absolute;
    bottom: 0;
    /* background-color: #FAFAFA; */
`;
