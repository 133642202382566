import React from 'react';
import { Modal } from 'react-native';
import { Heading } from '../Heading';
import * as S from './styles';

interface ModalProgressProps {
    isOpen: boolean;
    progress: number;
    title: string
}

export function ModalProgress({ isOpen, progress, title }: ModalProgressProps) {
    return (
        <Modal visible={isOpen} transparent>
            <S.ModalContainer>
                <S.Content>
                    <Heading color='gray' size='small'>
                        {title}
                    </Heading>
                    <S.BackgroundProgress>
                        <S.Progress width={progress} />
                    </S.BackgroundProgress>
                </S.Content>
            </S.ModalContainer>
        </Modal>
    );
}
