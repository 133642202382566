export const numberOfGuests = [
    { label: '2 Pessoas', value: 2 },
    { label: '3 Pessoas', value: 3 },
    { label: '4 Pessoas', value: 4 },
    { label: '5 Pessoas', value: 5 },
    { label: '6 Pessoas', value: 6 },
    { label: '7 Pessoas', value: 7 },
    { label: '8 Pessoas', value: 8 },
    { label: '9 Pessoas', value: 9 },
    { label: '10 Pessoas', value: 10 },
];

export const numberOfGuestsWithDualLink = [
    { label: '1 Pessoa', value: 1 },
    { label: '2 Pessoas', value: 2 },
];
