import React from 'react';

import * as S from './styles';

export interface HostProps {
    email: string;
    permission: string;
}

interface Host {
    host: any;
    onPress: () => void;
}

export function CardHost({ host, ...rest }: Host) {
    function returnPermission() {
        if (host.permission === 'receptionist') {
            return 'Recepcionista';
        }
        return 'Master';
    }

    return (
        <S.Container style={S.Shadow} {...rest} blocked={host.blocked_at}>
            <S.Box>
                <S.Content>
                    <S.Name numberOfLines={1}>{host.email}</S.Name>
                    <S.Email>{returnPermission()}</S.Email>
                </S.Content>
            </S.Box>
        </S.Container>
    );
}
