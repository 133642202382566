import { Platform, StatusBar, Dimensions } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

import { Feather } from '@expo/vector-icons';

import { defaultTheme as theme } from '../../../styles/theme';

const windowWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === 'web';

export const Safe = styled.SafeAreaView`
     flex: 1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Container = styled.View`
    flex: 1;
    padding: ${windowWidth > 650 ? '4.875rem 8.265rem 2.437rem 8.265rem' : `${RFValue(35)}px ${RFValue(36)}px ${RFValue(0)}px ${RFValue(36)}px`};
`;
export const ProgressContainer = styled.View`
    /* margin-top: ${RFValue(16)}px; */
    margin-bottom: ${RFValue(20)}px;
    width: 100%;
`;
export const Content = styled.View`
     background-color: ${isWeb && windowWidth > 470 ? '#fff' : ''};
    border: ${isWeb && windowWidth > 470 ? 'solid 1px #B2BEC3' : 'none'};
    width: 100%;
    border-radius: 10px;
    padding: ${windowWidth > 470 ? '5.312rem 3.25rem 5.625rem 3.25rem' : '20px 0px 50px 0px'};
    justify-content: space-between;
`;

export const Box = styled.ScrollView`
    height: 100%;
    padding: ${isWeb && windowWidth > 1000 ? '20px 8.265rem 2.437rem 8.265rem' : '0px'};
`;

export const BoxPhoto = styled.View`
    margin-top: 16px;
`;

export const ButtomPhoto = styled.TouchableOpacity`
    height: ${isWeb ? '203px' : `${RFValue(150)}px`};
    width: ${isWeb && windowWidth > 477 ? '409px' : '100%'};
    margin-top: 50px;
    align-self: center;
`;

export const IconCan = styled(Feather)`
    font-size: 18px;
    color: ${theme.colors.grayLight};
    position: absolute;
    bottom: 12px;
    right: 12px;
`;

export const ButtonPhotoImg = styled.Image`
    height: 100%;
    width: 100%;
    border-radius: 5px;
`;

export const ButtonLabel = styled.Text`
    text-align: center;
    font-family: ${theme.font.regular};
    font-size: ${isWeb ? '0.75rem' : `${RFValue(12)}px`};
    margin-bottom: ${RFValue(10)}px;
    color: ${theme.colors.gray};
    margin-top: 12px;
`;

export const ButtonContainer = styled.View`
    margin-top: 40px;
    margin-bottom: ${isWeb && windowWidth > 470 ? '0' : '50px'};
     ${isWeb && 'width: 324px; align-self:center'}
`;
