import styled, { css } from 'styled-components/native';
import { Platform, Dimensions } from 'react-native';

import { defaultTheme as theme } from '../../styles/theme';

const windowWidth = Dimensions.get('window').width;
type EventProps = {
    status: 'success' | 'error'
}

export const Shadow = Platform.OS === 'android' && {
    shadowColor: '#00000029',
    shadowOffset: {
        width: 0,
        height: 8,
    },
    shadowOpacity: 0.46,
    shadowRadius: 11.14,

    elevation: 17,
};


export const EventBox = styled.View`
    display: flex;
    flex-direction: row;
    width: ${Platform.OS === 'web' && windowWidth > 650 ? '40%' : '100%'};
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
    box-shadow: 5px 5px 6px #00000029;
    border-radius: 10px;
    margin: 5px 0;
    margin-right: ${Platform.OS === 'web' ? '10px' : '0px'};
    /* shadowColor: 'black';
    shadowOffset: {width: -3, height: 6};
    shadowOpacity: 1;
    shadowRadius: 8;
    elevation: 1; */
`;

export const BoxImage = styled.View`
    width: 60px;
    height: 60px;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 5px 5px 3px #00000029;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 15px;
`;

export const Content = styled.View`
    display: flex;
    flex-direction: column;
`;

export const ImageQrCode = styled.Image`
    width:  60px;
    height:  60px;
`;

export const Title = styled.Text<EventProps>`
    ${({ status }) => css`
        color: ${status === 'success' ? theme.colors.green : theme.colors.red};
        font-size: 12px;
        font-family: ${theme.font.bold};
        font-weight: bold;
        text-decoration: ${status === 'success' ? 'none' : 'line-through'};
    `}
`;

export const Footer = styled.View`
    display: flex;
    margin-top: 3px;
`;

export const Date = styled.Text`
    color: ${theme.colors.gray};
    font-size: 10px;
    margin-right: 13px;
`;

export const Hour = styled.Text`
    color: ${theme.colors.gray};
    font-size: 13px;
`;
