export const categories = [
    { label: 'Marketing', value: '1' },
    { label: 'Gastronomia', value: '2' },
    { label: 'Educação', value: '3' },
    { label: 'Finanças', value: '4' },
    { label: 'Saúde', value: '5' },
    { label: 'Esportivos', value: '6' },
    { label: 'Tecnologia', value: '7' },
    { label: 'Artes', value: '8' },
    { label: 'Jogos', value: '9' },
];

export const periodUtils = [
    { label: 'Essa semana', value: 'THIS_WEEK' },
    { label: 'Próxima semana', value: 'NEXT_WEEK' },
    { label: 'Esse mês', value: 'THIS_MONTH' },
    { label: 'Próximo mês', value: 'NEXT_MONTH' },
];
