import { Platform, Dimensions } from 'react-native';
import styled from 'styled-components/native';
import { defaultTheme as theme } from '../../styles/theme';

const isNotWeb = Platform.OS !== 'web';
const windowWidth = Dimensions.get('window').width;
export const Container = styled.View`
    flex: 1;
    min-width: ${windowWidth > 650 ? '494px' : '303px'} ;
    width: 100%;
    background-color: ${theme.colors.white};
`;

export const Content = styled.View`
    flex: 1;
    display: flex;
    padding: ${isNotWeb ? '40px' : '0px'};
`;

export const Form = styled.View`
    margin: 25px 0;
    min-height: 10px;
    justify-content: space-between;
`;
