import React from 'react';

import * as S from './styles';

interface CardHostInfoProps {
    title: string;
    info: string
}

export function CardUserInfo({ title, info }: CardHostInfoProps) {
    return (
        <S.Container style={S.Shadow}>
            <S.Title>{title}</S.Title>
            <S.Info>{info}</S.Info>
        </S.Container>
    );
}
