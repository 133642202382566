/* eslint-disable react/destructuring-assignment */
import { ScrollView, Dimensions, TouchableOpacity, RefreshControl, Modal } from 'react-native';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';

import { ListEmpty } from '../../../components/ListEmpty';
import { logoutAuth } from '../../../store/modules/user/actions';
import { Loading } from '../../../components/Loading';
import { Heading } from '../../../components/Heading';
import { EventsGallery } from '../../../components/EventsGallery';
import { Can } from '../../../components/Can';
import { ButtonDrawer } from '../../../components/ButtonDrawer';
import { FiltersModal } from '../../../components/FiltersModal';
import * as S from './styles';
import { getAllEvents, listCategories } from '../../../services/events';
import { EventsProps } from '../utils/types';
import { permissions } from '../../../utils/permissions';

const windowWidth = Dimensions.get('window').width;

export default function EventsScreen(navigation: any) {

    const { user } = useSelector((state: any) => state.user.user);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState('');
    const [period, setPeriod] = useState('');
    const [searchTxt, setSearchTxt] = useState('');
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [events, setEvents] = useState<EventsProps>({
        main: [],
    });
    const [categories, setCategories] = useState([]);
    const dispatch = useDispatch();
    const isFocused = useIsFocused();

    const { permissionMaster } = permissions;

    const getEvents = async () => {
        setLoading(true);
        setRefreshing(true);

        getAllEvents(user.id, user.type, filters, period, searchTxt).then((response) => {
            if (response?.result !== undefined) {
                setEvents(response?.result?.list);
            } else {
                setEvents({
                    main: [],
                });
            }
            setRefreshing(false);
            setLoading(false);
        }).catch(() => {
            setRefreshing(false);
            setLoading(false);
        });

    };


    async function getCategories() {
        await listCategories().then((response) => {
            const categoriesFormated = response.result.list.map((e) => {
                return {
                    value: String(e.id),
                    label: e.name,
                };
            });
            setCategories(categoriesFormated);
        });

    }

    useEffect(() => {
        setLoading(true);
        if (isFocused) {
            getCategories();
            getEvents();
        }
    }, [isFocused]);

    const handleLogout = async () => {
        dispatch(logoutAuth());
        navigation.navigation.navigate('Login');
    };

    const handleClickAddEvent = () => {
        navigation.navigation.navigate('AddEventScreen', {
            getEvents,
        });
    };

    const handleModalFilter = () => {
        setOpenModalFilter(!openModalFilter);
        getEvents();
    };

    const handleChangeFilters = (value) => {
        setFilters(value);
    };

    const handleChangePeriod = (value) => {
        setPeriod(value);
    };

    const handleChangeSearchText = (text: string) => {
        setSearchTxt(text);
    };

    useEffect(() => {
        navigation.navigation.setOptions({
            title: 'Eventos',
        });
    }, [navigation]);

    return (
        <S.Safe>
            {!loading && Object.keys(events).length === 1 && <ListEmpty message='Sem eventos disponíveis!' /> }


            {loading && <Loading /> }
            <S.Container>
                <S.Content>
                    <S.Header>
                        <>
                            {windowWidth > 650 ? (<ButtonDrawer type='drawer' navigation={navigation} />) : null}

                            <Heading color='purpleDark' size='large'>
                                Olá!
                            </Heading>
                        </>
                        {windowWidth < 650 ? (
                            <TouchableOpacity onPress={handleLogout}>
                                <S.Exit name='logout' />
                            </TouchableOpacity>
                        ) : null}
                    </S.Header>
                    <S.Title>Vamos descobrir eventos!</S.Title>
                    {!loading
                        && (
                            <ScrollView refreshControl={
                                <RefreshControl onRefresh={getEvents} refreshing={refreshing} />
                            }
                            >
                                {Object.keys(events).length === 1 ? (
                                    <S.ButtonFilterContainer>
                                        <S.ButtonFilter onPress={handleModalFilter}>
                                            <S.ButtonFilterIcon name='filter' />
                                        </S.ButtonFilter>
                                    </S.ButtonFilterContainer>
                                ) : null}

                                {events.main && events.main.length > 0 && (
                                    <>
                                        <S.EventHeader>
                                            <Heading color='gray' size='large' marginRight={27}>
                                                Favoritos
                                            </Heading>
                                            <S.ButtonFilter onPress={handleModalFilter}>
                                                <S.ButtonFilterIcon name='filter' />
                                            </S.ButtonFilter>
                                        </S.EventHeader>
                                        <EventsGallery events={events.main} navigation={navigation} getEvents={getEvents} allEventsList={events} />
                                    </>
                                )}

                                {Object.keys(events).map((key, event) => (
                                    key !== 'main' && events[key].length > 0 && (
                                        <S.GalleryContainer key={key}>
                                            <S.EventHeader>
                                                <Heading color='gray' size='large' marginRight={27}>
                                                    {key}
                                                </Heading>
                                                {event === 1 && events?.main.length === 0 ? (
                                                    <S.ButtonFilter onPress={handleModalFilter}>
                                                        <S.ButtonFilterIcon name='filter' />
                                                    </S.ButtonFilter>
                                                ) : null}
                                            </S.EventHeader>
                                            <EventsGallery events={events[key]} navigation={navigation} getEvents={getEvents} allEventsList={events} />
                                        </S.GalleryContainer>
                                    )

                                ))}
                            </ScrollView>
                        )

                    }
                </S.Content>
                <Can role={permissionMaster}>
                    <S.AddButton onPress={handleClickAddEvent}>
                        <FontAwesome5 name='plus' size={24} color='white' />
                    </S.AddButton>
                </Can>
            </S.Container>

            <Modal
                animationType='slide'
                visible={openModalFilter}
                onRequestClose={handleModalFilter}
            >
                <FiltersModal
                    changeFilter={handleChangeFilters}
                    changePeriod={handleChangePeriod}
                    changeSearchTxt={handleChangeSearchText}
                    closeModal={handleModalFilter}
                    handleChangeFilter={getEvents}
                    currentFilter={filters}
                    currentPeriod={period}
                    currentSearchText={searchTxt}
                    categories={categories}
                />
            </Modal>
        </S.Safe>
    );
}
