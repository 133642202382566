import { Platform, Dimensions } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';
import Checkbox from 'expo-checkbox';
import { A } from '@expo/html-elements';
import { defaultTheme } from '../../../../../styles/theme';

const isWeb = Platform.OS === 'web';

const windowWidth = Dimensions.get('window').width;


export const Container = styled.View`

`;

export const Form = styled.View`
    margin-top: 50px;
`;

export const ContainerButton = styled.View`
    margin-top: ${isWeb ? '10px' : '20px'};
    margin-bottom: ${isWeb && windowWidth > 470 ? '0' : '50px'};
    ${isWeb && 'width: 324px; align-self:center'}
`;

export const Inputs = styled.View`
    margin-top: 30px;
    ${isWeb && 'margin-bottom: 30px'}
    ${!isWeb && `height: ${RFPercentage(50)}px`}
`;

export const CheckboxContent = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
`;

export const CheckboxButton = styled(Checkbox).attrs({
    color: defaultTheme.colors.purpleDark,
})``;

export const CheckboxText = styled.Text`
    color: ${defaultTheme.colors.gray};
    font-family: ${defaultTheme.font.bold};
    font-size: ${isWeb ? '0.6rem' : '10px'};
`;

export const CheckboxLink = styled(A)`
    color: ${defaultTheme.colors.purpleDark};
    font-family: ${defaultTheme.font.bold};
    font-size: ${isWeb ? '0.6rem' : '10px'};
`;
