import React, { useEffect, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Platform } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { MenuProvider } from 'react-native-popup-menu';
import { SimpleLineIcons } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import { hostsFavorite, hostBlocked, hostDelete } from '../../../services/hosts';
import { ButtonFavorite } from '../../../components/ButtonFavorite';
import { Can } from '../../../components/Can';
// eslint-disable-next-line no-unused-vars
import { Dropdown, OptionProps } from '../../../components/Dropdown';
import { CardHostInfo } from './components/CardHostInfo';

import * as S from './styles';
import { Info } from './components/CardHostInfo/styles';
import bottomImg from '../../../assets/images/qr-codes-desktop-bottom.png';
import userPhotoEmpty from '../../../assets/images/user/user-photo-hosts.png';
import { defaultTheme } from '../../../styles/theme';
import { permissions } from '../../../utils/permissions';

const isWeb = Platform.OS === 'web';
interface HostsDetailsScreen {
    route: RouteProp<{
        params: {
            host: any
        }
    }>;
    navigation: any
}

interface ModalSuccessProps {
    title:string;
    message:string;
    function?: 'delete' | 'blocked'
}

export function HostsDetailsScreen({ route, navigation }: HostsDetailsScreen) {

    const { host } = route.params;
    const user = useSelector((state: any) => state?.user.user);
    const [isFavorite, setIsFavorite] = useState(host?.is_favorite);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [modalSuccessConfig, setModalSuccessConfig] = useState({} as ModalSuccessProps);
    const [modalSuccessDelete, setModalSuccessDelete] = useState(false);
    const [blockedAt, setBlockedAt] = useState(host.blocked_at);
    const insets = useSafeAreaInsets();

    const { permissionMaster } = permissions;

    const handleFavoriteHost = async () => {
        try {
            await hostsFavorite(host.id, !host.is_favorite, user.user.id);
            setIsFavorite(!isFavorite);
        } catch {
            return false;
        }
        return false;
    };

    const handleGoBack = () => {
        navigation.navigate('Anfitriões');
    };

    const handleEdit = () => {
        navigation.navigate('HostsEditScreen' as never, { host } as never);
    };


    const handleConfirmModalSuccess = () => {
        setOpenModalSuccess(false);
    };

    const handleBlock = async () => {
        try {
            const response = await hostBlocked(host.id, host.blocked_at === null);
            if (response.status === 200) {
                setModalSuccessConfig({
                    title: `Anfitrião ${blockedAt ? 'desbloqueado' : 'bloqueado'}`,
                    message: `Anfitrião ${blockedAt ? 'desbloqueado' : 'bloqueado'} com sucesso`,
                    function: 'blocked',
                });
                setBlockedAt(!blockedAt);
                setOpenModalSuccess(true);
            }
            // navigation.navigate('Anfitriões');
        } catch (e) {
            // eslint-disable-next-line no-alert
            alert(e);
        }
    };

    const openModalDelete = () => {
        setOpenModalConfirm(!openModalConfirm);
    };

    const handleNavigateInSucessDelete = () => {
        isWeb ? navigation.reset({
            index: 0,
            routes: [{ name: 'Anfitriões' }],
        }) : navigation.navigate('Anfitriões', {
            reload: true,
        });
    };

    const handleDelete = async () => {
        openModalDelete();
        await hostDelete(host.id).then(() => {
            setModalSuccessDelete((prevState: boolean) => !prevState);
        });
    };

    const optionsDropdown: OptionProps[] = [
        {
            title: 'Editar',
            nameIcon: 'square-edit-outline',
            onSelect: handleEdit,
        },
        {
            title: blockedAt ? 'Desbloquear' : 'Bloquear',
            nameIcon: 'block-helper',
            onSelect: handleBlock,
        },
        {
            title: 'Excluir',
            nameIcon: 'trash-can-outline',
            onSelect: openModalDelete,
        },
    ];


    const renderEvents = () => {
        return (
            <>
                {host?.events.length >= 1 && host?.events[0] !== null ? host?.events.map((event: any) => (
                    <>
                        {event?.name && (
                            <S.EventItem>
                                <S.EventItemTitle>{event?.name}</S.EventItemTitle>
                            </S.EventItem>
                        )
                        }
                    </>
                )) : <Info>Não possui eventos!</Info>}
            </>
        );
    };

    useEffect(() => {
        setIsFavorite(host.is_favorite);
    }, []);

    useEffect(() => {
        setIsFavorite(host.is_favorite);
    }, [host]);

    useEffect(() => {
        navigation.setOptions({
            title: `Anfitrião | ${host.name}`,
        });
    }, [navigation, route]);


    return (
        <MenuProvider>
            <S.Safe>
                {isWeb
                    ? (
                        <S.HeaderWeb>
                            <S.HeaderWebButton onPress={handleGoBack}>
                                <S.HeaderWebButtonIcon name='close' />
                            </S.HeaderWebButton>
                        </S.HeaderWeb>
                    ) : null
                }

                <S.Scroll>
                    <S.Header source={userPhotoEmpty} resizeMode='cover'>
                        <S.UserPhoto source={{ uri: host.photo }} resizeMode='cover' />
                        <S.GoBackButton onPress={handleGoBack} top={insets.top}>
                            {!isWeb
                                ? (
                                    <S.BlurGoBackButton intensity={50} tint='light'>
                                        <S.GoBackButtonIcon name='arrow-back-sharp' />
                                    </S.BlurGoBackButton>
                                ) : null
                            }
                        </S.GoBackButton>
                    </S.Header>

                    <S.BoxHostName>
                        <S.HostNameDivider>
                            <S.HostName>{host.name}</S.HostName>
                            <ButtonFavorite star={isFavorite} size={20} onPress={handleFavoriteHost} />
                        </S.HostNameDivider>
                        <Can role={permissionMaster}>
                            <Dropdown
                                options={optionsDropdown}
                                icon={(
                                    <S.ButtonOptionsTrigger>
                                        <SimpleLineIcons name='options-vertical' size={20} color='#B2BEC3' />
                                    </S.ButtonOptionsTrigger>
                                )}
                            />
                        </Can>
                    </S.BoxHostName>

                    <S.HostInfos>
                        <CardHostInfo title='Nome' info={host.name} />
                        <CardHostInfo title='Email' info={host.email} />
                        <CardHostInfo title='Telefone' info={host.telephone} />
                        <CardHostInfo title='Eventos'>
                            <S.ContainerEvents>
                                {renderEvents()}
                            </S.ContainerEvents>
                        </CardHostInfo>
                    </S.HostInfos>
                </S.Scroll>
                <S.ImageBottom source={bottomImg} />
                <AwesomeAlert
                    show={openModalConfirm}
                    title='Excluir Anfitrião'
                    message={`Tem certeza que deseja excluir ${host.name}?`}
                    closeOnTouchOutside={false}
                    closeOnHardwareBackPress={false}
                    showCancelButton
                    showConfirmButton
                    cancelText='Cancelar'
                    confirmText='Confirmar'
                    confirmButtonColor='#FF7171'
                    onCancelPressed={openModalDelete}
                    onConfirmPressed={handleDelete}
                />
                <AwesomeAlert
                    show={modalSuccessDelete}
                    title='Anfitrião excluído!'
                    message='O Anfitrião foi excluído com sucesso.'
                    closeOnHardwareBackPress={false}
                    closeOnTouchOutside={false}
                    showConfirmButton
                    confirmText='OK'
                    confirmButtonColor={defaultTheme.colors.green}
                    onConfirmPressed={handleNavigateInSucessDelete}
                />
                <AwesomeAlert
                    show={openModalSuccess}
                    title={modalSuccessConfig.title}
                    message={modalSuccessConfig.message}
                    closeOnHardwareBackPress={false}
                    closeOnTouchOutside={false}
                    showConfirmButton
                    confirmText='OK'
                    confirmButtonColor='#98C828'
                    onConfirmPressed={handleConfirmModalSuccess}
                    confirmButtonStyle={{
                        padding: '10px 0',
                    }}
                />
            </S.Safe>
        </MenuProvider>
    );
}
