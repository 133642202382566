import styled from 'styled-components/native';
import { Ionicons } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../styles/theme';

export const Container = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
`;

export const Icon = styled(Ionicons)`
    font-size: 26px;
    margin-right: 10px;
    color:${theme.colors.gray}
`;
