import React from 'react';
import * as S from './styles';


export function ButtonGoBack({ ...rest }) {
    return (
        <S.Container {...rest}>
            <S.Icon name='arrow-back' />
        </S.Container>
    );
}
