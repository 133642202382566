/* eslint-disable */
import * as React from "react"
import Svg, { SvgProps, G, Path, Ellipse } from "react-native-svg"


interface UserProps{
    props?: SvgProps;
    color: string
  }

const SvgComponent = ({props, color}:UserProps) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={23.077}
    height={25.712}
    {...props}
  >
    <G
      transform="translate(1 1)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <Path
        data-name="Caminho 83294"
        d="M21.077 23.708v-2.631a5.269 5.269 0 0 0-5.269-5.269H5.269A5.269 5.269 0 0 0 0 21.077v2.631"
      />
      <Ellipse
        data-name="Elipse 9416"
        cx={4.91}
        cy={5.456}
        rx={4.91}
        ry={5.456}
        transform="translate(5.456)"
      />
    </G>
  </Svg>
)

export default SvgComponent
