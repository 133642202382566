import React, { useState } from 'react';
import { TextInputProps } from 'react-native';

import { useController } from 'react-hook-form';

import MaskInput, { Masks } from 'react-native-mask-input';
import * as S from './styles';
import { validations, masksHours } from './utils';

interface InputHookFormProps extends TextInputProps {
    optional?: boolean;
    label: string;
    name: string;
    type: 'text' | 'email' | 'phone' | 'password' | 'passwordNotRequired' | 'date' | 'hours' | 'descriptions' | 'hex' | 'number' | 'cpf';
    marginRight?: number;
    width?: string;
    control: any,
    errors?:any,
    valueInicial?: any
    align?: 'center' | 'flex-start'
}

const mask = {
    phone: ['(', /\d/, /\d/, ')', ' ', '9', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    date: Masks.DATE_DDMMYYYY,
    cpf: Masks.BRL_CPF,
    hex: ['#', /[0-9a-fA-F]+/, /[0-9a-fA-F]+/, /[0-9a-fA-F]+/, /[0-9a-fA-F]+/, /[0-9a-fA-F]+/, /[0-9a-fA-F]+/],
};


export function InputHookForm({ optional, name, label, type, marginRight, control, width, errors, valueInicial, align, ...rest }: InputHookFormProps) {

    const { field } = useController({
        control,
        defaultValue: valueInicial || '',
        name,
        rules: optional ? null : validations[type],
    });

    const [showPassword, setShowPassword] = useState(true);
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const onChange = (text) => {
        if (type === 'number') {
            const onlyNumbers = text.replace(/[^0-9]/g, '');
            return field.onChange(onlyNumbers);
        }
        return field.onChange(text);
    };


    return (
        <S.Container alignItems={align} accessible={false} errors={errors} marginRight={marginRight} width={width} description={type === 'descriptions'}>
            <S.Field accessible={false}>
                <S.Label accessible={false} errors={errors}>{label}</S.Label>
                <MaskInput
                    secureTextEntry={type === 'password' || type === 'passwordNotRequired' ? showPassword : false}
                    multiline={type === 'descriptions'}
                    {...rest}
                    autoComplete='off'
                    value={field.value}
                    mask={type === 'hours' ? masksHours : mask[type]}
                    onChangeText={onChange}
                    style={[S.InputMask.input, type !== 'descriptions' ? { maxHeight: 20 } : { height: 70 }]}
                    accessible
                    numberOfLines={4}
                />
                {/* <S.Input isLarge={isLarge} value={field.value} onChangeText={field.onChange} secureTextEntry={type === 'password' ? showPassword : false} /> */}
            </S.Field>
            {type === 'password' || type === 'passwordNotRequired' ? (
                <S.PasswordButton onPress={handleShowPassword} accessible={false}>
                    {type === 'password' || type === 'passwordNotRequired' ? <S.PasswordIconButton accessible={false} name={showPassword ? 'eye-with-line' : 'eye'} /> : null}
                </S.PasswordButton>
            ) : null }
        </S.Container>
    );
}
