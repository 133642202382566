import React from 'react-native';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder';
import { LinearGradient } from 'expo-linear-gradient';
import { Entypo } from '@expo/vector-icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Heading } from '../../../Heading';
import * as S from '../../styles';
import { defaultTheme as theme } from '../../../../styles/theme';
import { placeLoader } from './styles';

const ShimmerPlaceHolder = createShimmerPlaceholder(LinearGradient);

export function Event({ event, navigation, onFavorite, isFavoriteInCategory }) {
    const user = useSelector((state: any) => state?.user.user);
    const [loadingImg, setLoadingImg] = useState(false);
    const coverImage = event.front_cover;
    const existsEventInFavorites = isFavoriteInCategory(event);
    const handleClickEvent = () => {
        navigation.navigation.navigate('EventDetailsScreen', {
            event,
        });
    };

    const onLoadImage = () => {
        setLoadingImg(true);
    };

    const date = new Date(event.date);


    const favoriteEvent = () => {
        onFavorite(event);
    };

    const renderFavoritesButton = () => {
        if (user?.user.permission === 'receptionist') {
            return null;
        }

        return (
            <S.Heart onPress={favoriteEvent}>
                <Entypo
                    name={existsEventInFavorites ? 'heart' : 'heart-outlined'}
                    size={20}
                    color={theme.colors.purple}
                />
            </S.Heart>
        );
    };

    return (
        <S.Slide
            blocked={event.blocked_at}
            key={event.id}
            onPress={handleClickEvent}
        >
            <ShimmerPlaceHolder
                visible={loadingImg}
                shimmerStyle={placeLoader}
            >
                <S.ImageContainer>
                    <S.Image
                        onLoad={onLoadImage}
                        source={{ uri: coverImage }}
                    />
                    {renderFavoritesButton()}
                </S.ImageContainer>
            </ShimmerPlaceHolder>
            <S.HeadingContainer>
                <Heading color='gray' size='xsmall' marginTop={12}>
                    {event?.name}
                </Heading>
            </S.HeadingContainer>
            <S.EventInfos>
                {format(
                    date.setDate(date.getDate() + 1),
                    "EEEE, dd 'de' MMMM 'de' yyyy",
                    { locale: ptBR },
                )}
            </S.EventInfos>
        </S.Slide>
    );
}
