import React from 'react';
import * as S from './styles';

interface AllInfoChartProps {
    name: string,
}

interface ModalChartProps {
    allInfoChart: Array<AllInfoChartProps>,
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
}

export function ModalChartInfo({ allInfoChart, isOpen, setIsOpen }: ModalChartProps) {

    function RenderItem({ item }) {
        return (
            <S.GuestNameArea>
                <S.GuestName>{item.name}</S.GuestName>
            </S.GuestNameArea>
        );
    }

    function handleBackdropPress() {
        setIsOpen(!isOpen);
    }

    return (
        <S.ModalArea isVisible={isOpen} backdropOpacity={0.8} onBackdropPress={handleBackdropPress}>
            <S.ModalContainer>
                <S.ListArea
                    data={allInfoChart}
                    numColumns={1}
                    renderItem={RenderItem}
                />
            </S.ModalContainer>
        </S.ModalArea>
    );
}
