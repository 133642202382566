import { api } from './api';
import { getToken } from './functions';
import { ErrorAlert } from '../components/ErrorAlert';

export async function guestConfirmPresence(idGuest?: number) {
    try {
        const token = await getToken();
        const body = {
            idGuest: Number(idGuest),
        };
        const { data } = await api.patch('guests/confirmPresence', body, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function guestUpdateStatus(status: string, idsGuests?: number[]) {
    try {
        const token = await getToken();
        const body = {
            status,
            idsGuests,
        };
        const { data } = await api.patch('guests/changeStatus', body, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function sendMessage(
    body: {
        guestStatus: 'pending' | 'approved' | 'disapproved',
        message: string,
        type: 'whatsapp' | 'email',
        idEvent: number,
    },
) {
    try {
        const token = await getToken();
        const { data } = await api.post('guests/sendMessage', body, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function uniqueGuest(hash: string) {
    try {
        const token = await getToken();
        const { data } = await api.get(`guests/uniqueGuest?hash=${hash}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function exportGuests(idEvent: number) {
    try {
        const token = await getToken();
        const { data } = await api.get(`guests/export?idEvent=${idEvent}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function reportGuests(idEvent: number) {
    try {
        const token = await getToken();
        const { data } = await api.get(`guests/report?idEvent=${idEvent}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function importGuests() {
    try {
        const token = await getToken();
        const { data } = await api.get('guests/import', {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function guestEdit(guestId: number, body: Object) {
    try {
        const token = await getToken();
        const { data } = await api.put(`/guests?idGuest=${guestId}`, body, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function guestDelete(guestId: number) {
    try {
        const token = await getToken();
        const { data } = await api.delete(`/guests?idGuest=${guestId}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function guestViewInvite(event: any) {
    try {
        const token = await getToken();
        const body = {
            event,
        };
        const { data } = await api.patch('guests/viewInvite', body, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}
