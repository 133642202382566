import styled from 'styled-components/native';
import Modal from 'react-native-modal';
import { Platform } from 'react-native';

export const ModalArea = styled(Modal)`
    align-items: center;
    justify-content: center;
`;

export const ModalContainer = styled.View`
    align-self: center;
    background-color: #FFF;
    height: 70%;
    padding: 20px;
    align-items: center;
    max-width: ${Platform.OS === 'web' ? '300px' : '80%'};
    justify-content: center;
`;

export const ListArea = styled.FlatList``;

export const GuestNameArea = styled.View`
    margin-bottom: 12px;
    margin-top: 12px;
    padding-left: 8px;
    padding-right: 12px;
`;

export const GuestName = styled.Text`
    font-size: 14px;
`;
