import styled from 'styled-components/native';
import { Dimensions } from 'react-native';
import { PieChart } from 'react-native-svg-charts';

const windowWidth = Dimensions.get('window').width;

export const PieChartContent = styled.View`
    height: 400px;
    /* background-color: blue; */
    flex-direction: column;
`;

export const ContainerGraphic = styled.View`
    flex: 1;
    /* background-color: orange; */
`;

export const PieChartGraphic = styled(PieChart)`
    height: 200px;
    /* background-color: orange; */
`;

export const PierChartTotalContainer = styled.View`
    /* background-color: pink; */
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
`;

export const PierChartTotal = styled.Text`
    font-size: 36px;
    font-weight: 300;
    color: #4b4b4b;
`;

export const PierChartTotalLabel = styled.Text`
    font-size: 14px;
    font-weight: 700;
    color: #000;
`;


export const Chart = styled.View`
    margin-bottom: 40px;
    margin: 40px 37px 10px 37px;
    ${windowWidth > 800 && 'width:30%'}
`;
