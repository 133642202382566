import React from 'react';
import { DrawerToggleButton } from '@react-navigation/drawer';

import * as S from './styles';
import { defaultTheme as theme } from '../../styles/theme';

import { ButtonGoBack } from '../ButtonGoback';

interface ButtonDraweProps {
    type: 'goback' | 'drawer';
    navigation?: any;
    handleGoBack?: () => void;
}

export function ButtonDrawer({ type, navigation, handleGoBack }: ButtonDraweProps) {
    function handleGoBackButton() {
        navigation.goBack();
    }

    function renderButton() {
        const goBackFunction = handleGoBack || handleGoBackButton;

        if (type === 'goback') {
            return (
                <ButtonGoBack onPress={goBackFunction} />
            );
        }

        return (
            <DrawerToggleButton tintColor={theme.colors.purpleDark} />
        );
    }

    return (
        <S.Container>
            {renderButton()}
        </S.Container>
    );
}
