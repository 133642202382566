import 'react-native-gesture-handler';
import { useState, useEffect } from 'react';
import { AppRegistry, Platform } from 'react-native';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';

import { NavigationContainer } from '@react-navigation/native';


import {
    useFonts,
    Montserrat_300Light,
    Montserrat_400Regular,
    Montserrat_500Medium,
    Montserrat_700Bold,
} from '@expo-google-fonts/montserrat';
import { persistor, store } from './src/store/';

import { Routes } from './src/routes';

import { defaultTheme } from './src/styles/theme';


export default function App() {
    const [, setFinishedTimeout] = useState(false);

    useEffect(() => {
        const id = setTimeout(() => {
            setFinishedTimeout(true);
        }, 4000);

        return () => clearTimeout(id);
    }, []);

    const [fontsLoaded] = useFonts({
        Montserrat_300Light,
        Montserrat_400Regular,
        Montserrat_500Medium,
        Montserrat_700Bold,
    });

    if (!fontsLoaded) {
        return null;
    }


    const linking = {
        // prefixes: [
        //   /* your linking prefixes */
        // ],
        config: {
            screens: {
                Home: 'home',
                Login: 'login',
                HostsEditScreen: 'host-edit-screen',
                HostsDetailsScreen: 'host-details-screen',
                EventDetailsScreen: 'event-details-screen',
                EventEditScreen: 'event-edit-screen',
                AddEventScreen: 'add-event-screen',
                FiltersScreen: 'filter-screen',
                GuestsScreen: 'guests-screen',
                QrCodeResultScreen: 'qr-code-result-screen',
                FormPhotoIndividualScreen: 'form-photo-individual-screen',
                FormPhotoFamily: 'form-photo-family',
                FormVaccineCard: 'form-vaccine-card',
                FormVaccineFamily: 'form-vaccine-family',
                FormScreen: 'convites/:event',
                FormUniqueScreen: 'convite/:event',
                GuestEventDetails: 'details/:hash',
                ChartsEventScreen: 'chart-event',
            },
        },
    };

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={defaultTheme}>
                    <NavigationContainer linking={linking}>
                        <Routes />
                    </NavigationContainer>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
}

AppRegistry.registerComponent('App', () => App);
if (Platform.OS === 'web') {
    AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });
}
