import styled from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../styles/theme';


interface ButtonOptions {
    selected: boolean
}

interface ButtonPickerProps {
    errors: boolean
}

export const Container = styled.View`
    position: relative;
    z-index: 5;
`;

export const ButtonPicker = styled.TouchableOpacity<ButtonPickerProps>`
    padding: 17px 12px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid ${({ errors }) => (errors ? theme.colors.red : theme.colors.grayLight)};
    border-radius: 4px;
    margin-top: 12px;
`;

export const ButtonPickerIcon = styled(AntDesign)`
    font-size: 16px;
    color: ${theme.colors.grayLight};
`;

export const ButtonPickerText = styled.Text`
    font-family: ${theme.font.bold};
    color: ${theme.colors.gray};
    font-size: 12px;
`;

export const Box = styled.View`
    
`;

export const ModalContainer = styled.View`
    background-color: rgba(0,0,0, 0.9);
    flex:1;
    padding: 20px;
    align-items: center;
    justify-content: center;
`;
export const Content = styled.ScrollView.attrs(
    {
        contentContainerStyle: {
            flex: 1,
            justifyContent: 'space-between',
            paddingBottom: 20,
        },
    },
)`
    background-color: #fff;
    max-height: 350px;
    border-radius: 4px ;
    padding: 10px;
    max-width: 300px;  
    width: 100%;
    position: absolute;
`;

export const ButtonOptions = styled.TouchableOpacity<ButtonOptions>`
    ${({ selected }) => (selected ? `background-color:${theme.colors.purpleDark};padding:10px` : 'padding:10px')};
    border-radius: 4px;
`;

export const ButtonOptionsText = styled.Text<ButtonOptions>`
    font-family: ${theme.font.regular};
    color: ${({ selected }) => (selected ? theme.colors.white : theme.colors.purpleDark)};
    font-size: 18px;
`;
