import { MotiImage } from 'moti';

import bottomImage from '../../../../assets/images/qr-code-mobile-bottom02.png';
import { motiStyles } from '../../motiStyles';

export function BottomImage() {
    return (
        <MotiImage
            style={motiStyles.bottomImageStyles}
            resizeMode='contain'
            source={bottomImage}
            from={{
                top: 125,
            }}
            animate={{
                top: -650,
            }}
            transition={{
                type: 'timing',
                duration: 3000,
            }}
        />
    );
}
