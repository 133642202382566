import { MotiImage, MotiView } from 'moti';
import { useEffect, useState } from 'react';
import { View } from 'react-native';

import logoImage from '../../../../assets/images/splash-icon.png';
import { motiStyles } from '../../motiStyles';

export function Logo() {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => setVisible(false), 1900);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <MotiView style={motiStyles.containerLogo}>
            {visible ? (
                <MotiImage
                    source={logoImage}
                    style={motiStyles.logo}
                    resizeMode='contain'
                />
            ) : (
                <View style={motiStyles.logo} />
            )}
        </MotiView>
    );
}
