import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import * as S from './styles';
import { CardUserInfo } from './components/CardUserInfo';
import { Can } from '../../../components/Can';
import bottomImg from '../../../assets/images/qr-codes-desktop-bottom.png';

import { permissions } from '../../../utils/permissions';

export function GuestDetailsScreen({ navigation, route }) {
    const { name, photo, telephone, invitation } = route.params.guest;
    const { front_cover } = route.params.event;
    const currentPhoto = String(photo);
    const photoGuest = { uri: currentPhoto };
    const inviteImage = { uri: invitation };
    const guests = route.params.guest;
    const insets = useSafeAreaInsets();

    function handleGoBack() {
        navigation.navigate(
            'GuestsScreen' as never,
            {
                idEvent: route.params.event.id,
                event: route.params.event,
            } as never,
        );
    }

    function renderInvite() {
        if (!invitation) {
            return null;
        }
        return (
            <S.InvitePhoto source={inviteImage} resizeMode='stretch' />
        );
    }

    function handleEditPress() {
        const params = {
            guest: route.params.guest,
            event: route.params.event,
        } as never;
        navigation.navigate('GuestEditScreen' as never, params);
    }

    return (
        <>
            <S.Safe>
                <S.ImageEvent source={{ uri: front_cover }} />
                <S.GoBackButton top={insets.top}>
                    <S.BlurGoBackButton intensity={50} tint='light'>
                        <TouchableOpacity onPress={handleGoBack}>
                            <S.GoBackButtonIcon name='close' />
                        </TouchableOpacity>
                    </S.BlurGoBackButton>
                </S.GoBackButton>
                <S.Content>
                    <S.ImageUser source={photoGuest} />
                    <S.InfoArea>
                        <S.NameUser>{name}</S.NameUser>
                        <Can role={permissions.permissionHostAndMaster}>
                            <S.EditText onPress={handleEditPress}>Editar</S.EditText>
                        </Can>
                    </S.InfoArea>
                    <CardUserInfo title='Nome' info={name} />
                    <CardUserInfo title='Telefone' info={telephone} />
                    {guests.main_name && <CardUserInfo title='Convidado por' info={guests.main_name || ''} />}
                    {guests.childrenGuest && <CardUserInfo title='Acompanhante(s):' info={guests.childrenGuest || ''} />}
                    {guests.email && <CardUserInfo title='Email' info={guests.email || ''} />}
                    {guests.rg && <CardUserInfo title='RG' info={guests.rg || ''} />}
                    {guests.cpf && <CardUserInfo title='CPF' info={guests.cpf || ''} />}
                    {guests.address && <CardUserInfo title='Endereço' info={guests.address || ''} />}
                    {guests.numberShoe && <CardUserInfo title='Número da sandália' info={guests.numberShoe || ''} />}
                    {guests.profession && <CardUserInfo title='Profissão' info={guests.profession || ''} />}
                    {guests.office && <CardUserInfo title='Cargo' info={guests.office || ''} />}
                    {guests.business && <CardUserInfo title='Empresa' info={guests.business || ''} />}
                </S.Content>
                {renderInvite()}
            </S.Safe>
            <S.ImageBottom source={bottomImg} />
        </>
    );
}
