import * as React from 'react';
/* eslint-disable-next-line */
import Svg, { SvgProps, G, Path, Ellipse } from "react-native-svg"


interface FamilyProps {
    props?: SvgProps;
    isActive: boolean
}

const SvgComponent = ({ props, isActive }:FamilyProps) => (
    <Svg
        xmlns='http://www.w3.org/2000/svg'
        width={46.942}
        height={25.753}
        {...props}
    >
        <G
            data-name='Componente 77 \u2013 2'
            fill='none'
            stroke={isActive ? '#fff' : '#b5c1c6'}
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
        >
            <G transform='translate(12.904 1)'>
                <Path
                    data-name='Caminho 83294'
                    d='M21.114 23.753v-2.64a5.278 5.278 0 0 0-5.279-5.278H5.278A5.278 5.278 0 0 0 0 21.113v2.639'
                />
                <Ellipse
                    data-name='Elipse 9416'
                    cx={4.919}
                    cy={5.466}
                    rx={4.919}
                    ry={5.466}
                    transform='translate(5.466)'
                />
            </G>
            <G data-name='user' transform='translate(33.039 4.693)'>
                <Path
                    data-name='Caminho 83294'
                    d='M12.9 14.517v-1.613a3.226 3.226 0 0 0-3.222-3.226H3.226A3.226 3.226 0 0 0 0 12.904v1.613'
                />
                <Ellipse
                    data-name='Elipse 9416'
                    cx={3.006}
                    cy={3.34}
                    rx={3.006}
                    ry={3.34}
                    transform='translate(3.34)'
                />
            </G>
            <G data-name='user' transform='translate(1 4.693)'>
                <Path
                    data-name='Caminho 83294'
                    d='M12.9 14.517v-1.613a3.226 3.226 0 0 0-3.222-3.226H3.226A3.226 3.226 0 0 0 0 12.904v1.613'
                />
                <Ellipse
                    data-name='Elipse 9416'
                    cx={3.006}
                    cy={3.34}
                    rx={3.006}
                    ry={3.34}
                    transform='translate(3.34)'
                />
            </G>
        </G>
    </Svg>
);

export default SvgComponent;
