import React from 'react';
import { Dimensions } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useSelector } from 'react-redux';

import { MainTabRoutes } from '../routes/MainTabRoutes';

// Events
import { EventDetailsScreen } from '../screens/Events/EventDetails';
import { EventEditScreen } from '../screens/Events/EventEditScreen';
import { AddEventScreen } from '../screens/Events/AddEventScreen';
import { ChartsEventScreen } from '../screens/Events/ChartsEventScreen';
import EventsScreen from '../screens/Events/EventsScreen';
// Hosts
import { AddHostScreen } from '../screens/Hosts/AddHostScreen';
import { HostEditScreen } from '../screens/Hosts/HostEditScreen';
import { HostsDetailsScreen } from '../screens/Hosts/HostsDetailsScreen';

// Form
import { FormScreen } from '../screens/Form/FormScreen';
import { FormPhotoIndividualScreen } from '../screens/Form/FormPhotoIndividualScreen';
import { FormPhotoFamily } from '../screens/Form/FormPhotoFamily';
import { FormVaccineCard } from '../screens/Form/FormVaccineCard';
import { FormVaccineFamily } from '../screens/Form/FormVaccineFamily';

// QrCode
import { QrCodeResultScreen } from '../screens/QrCode/QrCodeResultScreen';

// Guests
import { GuestsScreen } from '../screens/Guests/GuestsScreen';
import { GuestDetailsScreen } from '../screens/Guests/GuestDetails';
import { SignInScreen } from '../screens/SignInScreen';
import { GuestEventDetails } from '../screens/Guests/GuestEventDetails';

import { AdministratorsScreen } from '../screens/Administrators/AdministratorsScreen';
import { AddAdministratorsScreen } from '../screens/Administrators/AddAdministratorsScreen';

import DrawerRoutes from '../routes/DrawerRoutes';
import { GuestEditScreen } from '../screens/Guests/GuestEditScreen';

const { Navigator, Screen } = createNativeStackNavigator();

const windowWidth = Dimensions.get('window').width;

export function Routes() {

    const user = useSelector((state: any) => state?.user.user);
    return (
        <>
            {windowWidth > 650 ? <DrawerRoutes /> : (
                <Navigator screenOptions={
                    { headerShown: false }
                }
                >
                    {!user ? (
                        <>
                            <Screen name='Login' component={SignInScreen} />
                            <Screen name='FormScreen' component={FormScreen} />
                            <Screen name='FormUniqueScreen' component={FormScreen} />
                            <Screen name='FormPhotoIndividualScreen' component={FormPhotoIndividualScreen} />
                            <Screen name='FormPhotoFamily' component={FormPhotoFamily} />
                            <Screen name='FormVaccineCard' component={FormVaccineCard} />
                            <Screen name='FormVaccineFamily' component={FormVaccineFamily} />
                            <Screen name='GuestEventDetails' component={GuestEventDetails} />
                        </>
                    ) : (
                        <>
                            { user.user.type === 'adm' ? (
                                <>
                                    <Screen name='Home' component={MainTabRoutes} />
                                    <Screen name='HostsEditScreen' component={HostEditScreen} />
                                    <Screen name='HostsDetailsScreen' component={HostsDetailsScreen} />
                                    <Screen
                                        name='EventDetailsScreen'
                                        component={EventDetailsScreen}
                                        options={{
                                            title: 'Detalhes do evento',
                                        }}
                                    />
                                    <Screen name='EventEditScreen' component={EventEditScreen} />
                                    <Screen name='AddHostScreen' component={AddHostScreen} />
                                    <Screen name='AddEventScreen' component={AddEventScreen} />
                                    <Screen name='GuestsScreen' component={GuestsScreen} />
                                    <Screen name='GuestsDetailsScreen' component={GuestDetailsScreen} />
                                    <Screen name='QrCodeResultScreen' component={QrCodeResultScreen} />
                                    <Screen name='FormScreen' component={FormScreen} />
                                    <Screen name='FormUniqueScreen' component={FormScreen} />
                                    <Screen name='FormPhotoIndividualScreen' component={FormPhotoIndividualScreen} />
                                    <Screen name='FormPhotoFamily' component={FormPhotoFamily} />
                                    <Screen name='FormVaccineCard' component={FormVaccineCard} />
                                    <Screen name='FormVaccineFamily' component={FormVaccineFamily} />
                                    <Screen name='GuestEventDetails' component={GuestEventDetails} />
                                    <Screen name='ChartsEventScreen' component={ChartsEventScreen} />
                                    <Screen name='GuestEditScreen' component={GuestEditScreen} />
                                    <Screen name='AdministratorsScreen' component={AdministratorsScreen} />
                                    <Screen name='AddAdministratorsScreen' component={AddAdministratorsScreen} />
                                </>
                            ) : (
                                <>
                                    <Screen name='Home' component={EventsScreen} />
                                    <Screen
                                        name='EventDetailsScreen'
                                        component={EventDetailsScreen}
                                        options={{
                                            title: 'Detalhes do evento',
                                        }}
                                    />
                                    <Screen name='GuestsScreen' component={GuestsScreen} />
                                    <Screen name='ChartsEventScreen' component={ChartsEventScreen} />
                                </>
                            )}
                        </>
                    )}
                </Navigator>
            )}
        </>
    );
}
