import React from 'react';

import * as S from './styles';

interface CardHostInfoProps {
    title: string;
    info?: string;
    children?: React.ReactNode
}

export function CardHostInfo({ title, info, children }: CardHostInfoProps) {
    return (
        <S.Container style={S.Shadow}>
            <S.Title>{title}</S.Title>
            {!!info && <S.Info>{info}</S.Info>}
            {!!children && children}
        </S.Container>
    );
}
