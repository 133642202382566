import styled from 'styled-components/native';
import { defaultTheme as theme } from '../../styles/theme';

export const Container = styled.View`
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    position: absolute;
    height: 100%;
    width: 100%;
`;

export const Text = styled.Text`
    font-size: 14px;
    /* text-align: center; */
    font-family: ${theme.font.regular};
    color: ${theme.colors.gray};
    text-align: center;
`;
