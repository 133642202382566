import { Platform, StatusBar, Dimensions } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';
import { Ionicons } from '@expo/vector-icons';

import { defaultTheme as theme } from '../../styles/theme';

const windowWidth = Dimensions.get('window').width;
interface FilterProps {
    selected: boolean
}

const isWeb = Platform.OS === 'web';
export const Safe = styled.SafeAreaView`
    flex: 1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;
export const Container = styled.ScrollView.attrs(
    {
        contentContainerStyle: {
            flex: 1,
        },
    },
)`
    /* padding: ${isWeb ? '4.875rem 8.265rem 2.437rem 8.265rem' : '60px 36px'}; */
`;
export const Box = styled.ScrollView`
    padding-top: ${RFValue(60)}px;
    padding: ${isWeb && windowWidth > 650 ? '4.875rem 8.265rem 2.437rem 8.265rem' : '30px 36px 60px'};
`;
export const Header = styled.View`
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

export const BoxGoBack = styled.View`
    align-items: center;
    flex-direction: row;
`;
export const GoBackButton = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
`;
export const GoBack = styled(Ionicons)`
    font-size: ${isWeb && windowWidth > 650 ? '26px' : `${RFValue(26)}px`};
    color: ${theme.colors.gray};
    margin-right: 20px;
`;
export const Title = styled.Text`
    font-family: ${theme.font.bold};
    font-size: ${isWeb && windowWidth > 650 ? '18px' : '18px'};
    color: ${theme.colors.gray};
`;
export const CleanButton = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
`;
export const CleanButtonText = styled.Text`
    font-family: ${theme.font.regular};
    font-size: ${isWeb && windowWidth > 650 ? '12px' : '12px'};
    color: ${theme.colors.gray};
`;


export const SearchBox = styled.View`
    flex-direction: row;
    align-items: center;
    padding: 1px;
    margin-top: 27px;
    margin-bottom: 55px;
    border-bottom-width: 0.5px;
    border-bottom-color:${theme.colors.grayLight} ;
`;
export const SearchIcon = styled(Ionicons)`
    font-size: ${isWeb && windowWidth > 650 ? '26px' : `${RFValue(26)}px`};
    color: ${theme.colors.grayLight};
    margin-right: 20px;
`;
export const SearchInput = styled.TextInput`
    height: 20px;
    font-family: ${theme.font.regular};
    color: ${theme.colors.gray};
    font-size: ${isWeb && windowWidth > 650 ? '18px' : `${RFValue(18)}px`};
    width: 100%;
`;

export const Filters = styled.View``;
export const FilterTitle = styled.Text`
    font-family: ${theme.font.bold};
    color: ${theme.colors.gray};
    font-size: ${isWeb && windowWidth > 650 ? '16px' : '16px'};
    margin-bottom: 25px;
`;
export const FilterBox = styled.View`
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 36px;
`;
export const Filter = styled.TouchableOpacity<FilterProps>`
    border: 1px solid ${theme.colors.grayLight};
    padding: 6px 20px;
    border-radius: 15px;
    margin: 6px 5px;
    background-color: ${({ selected }) => (selected ? 'rgba(82, 9, 168, 0.5)' : 'transparent')};
`;
export const FilterText = styled.Text<FilterProps>`
    font-family: ${theme.font.regular};
    font-size: ${isWeb && windowWidth > 650 ? '14px' : '14px'};
    color: ${({ selected }) => (selected ? theme.colors.purpleDark : theme.colors.gray)};
`;

export const ContainerButtonFilter = styled.View`
    ${isWeb && windowWidth > 650 && 'width: 324px; align-self:center; margin-top:82px'}
    margin-bottom: 15%
`;
