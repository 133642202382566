import { MotiView } from 'moti';
import { BottomImage } from '../components/BottomImage';
import { Logo } from '../components/Logo';
import { SingInForm } from '../../../components/SingInForm';
import { TopImage } from '../components/TopImage';
import { motiStyles } from '../motiStyles';

export type MobileViewProps = {
    navigation: any
}

export function MobileView({ navigation }: MobileViewProps) {
    return (
        <MotiView style={motiStyles.container}>
            <TopImage />
            <Logo />
            <BottomImage />
            <MotiView
                style={motiStyles.singInForm}
                from={{
                    bottom: -1000,
                }}
                animate={{
                    bottom: -250,
                }}
                transition={{
                    type: 'timing',
                    duration: 5000,
                }}
            >
                <SingInForm navigation={navigation} />
            </MotiView>
        </MotiView>
    );
}
