import { useSelector } from 'react-redux';

export type CanProps = {
    children?: React.ReactNode;
    role?: Array<string>
}

export function Can({ children, role }: CanProps) {
    const { user } = useSelector((state: any) => state.user);

    if (user === null) {
        return null;
    }

    if (!user) {
        return null;
    }

    if (!role?.includes(user.user.permission || 'host')) {
        return null;
    }

    return (
        <>
            {children}
        </>
    );
}
