import { StatusBar, Platform, Dimensions } from 'react-native';
import { RFValue, RFPercentage } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';
import { BlurView } from 'expo-blur';
import { AntDesign } from '@expo/vector-icons';

import { defaultTheme as theme } from '../../../styles/theme';

const isWeb = Platform.OS === 'web';
const windowWidth = Dimensions.get('window').width;

interface GoBackButtonProps {
    top: number
}

export const Safe = styled.ScrollView.attrs({
    contentContainerStyle: {
        paddingBottom: 100,
    },
})`
    width: 100%;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const ImageEvent = styled.Image`
    height: 270px;
`;
export const Content = styled.View`
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    max-width: 350px;
    padding: ${RFValue(10)}px ${isWeb ? '2px' : `${RFValue(20)}px`};
    ${isWeb && 'width: 30.875rem; align-self: center;'};
    margin-top: -125px;
`;
export const ImageUser = styled.Image`
    height: 140px;
    width: 140px;
    border-radius: 5px;
    align-self: center;
`;
export const NameUser = styled.Text`
    font-family: ${theme.font.bold};
    /* font-size: ${RFValue(18)}px; */
    font-size: ${isWeb ? '20px' : `${RFValue(18)}px`};
    color: ${theme.colors.purpleDark};
    text-align: center;
    margin: 30px 0px;
`;

export const GoBackButton = styled.View<GoBackButtonProps>`
    position: absolute;
    top: ${({ top }) => `${top + 12}px`};
    right: 12px;
    width: 45px;
    height: 40px;
`;

export const BlurGoBackButton = styled(BlurView)`
    align-items: center;
    justify-content: center;
    width: ${isWeb ? '35px' : '45px'};
    height: ${isWeb ? '30px' : '40px'};
    overflow: hidden;
    border-radius: 4px;
`;

export const GoBackButtonIcon = styled(AntDesign)`
    font-size: ${isWeb ? `${RFValue(14)}px` : `${RFValue(26)}px`};
    color: ${theme.colors.black};
`;

export const ImageBottom = styled.ImageBackground.attrs(isWeb ? {
    resizeMode: 'repeat',
} : {})`
    height: ${isWeb ? `${RFPercentage(2)}px` : `${RFPercentage(5)}px`};
    width: 100%;
    position: absolute;
    /* ${isWeb && 'top: 100%'}; */
    bottom: 0;
    z-index: 3;
    background-color: ${theme.colors.lightGray};
`;

export const InfoArea = styled.View`
    flex-direction: row;
    justify-content: space-around;
`;

export const EditText = styled.Text`
    font-family: ${theme.font.bold};
    /* font-size: ${RFValue(18)}px; */
    font-size: ${isWeb ? '20px' : `${RFValue(18)}px`};
    color: ${theme.colors.gray};
    text-align: center;
    margin: 30px 0px;
`;

export const InvitePhoto = styled.Image`
    height: ${isWeb && windowWidth > 650 ? '30.625rem' : `${RFValue(350)}}px`};
    width: ${isWeb && windowWidth > 650 ? '22.5rem' : `${RFValue(250)}}px`};
    border-radius: ${isWeb && windowWidth > 650 ? '1px' : '10px'};
    align-self: center;
    margin-top: 40px;
`;
