import React from 'react';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder';
import { LinearGradient } from 'expo-linear-gradient';

const ShimmerPlaceHolder = createShimmerPlaceholder(LinearGradient);

interface PlaceLoaderProps {
    visible: boolean;
    height?: number | string;
    width?: number | string;
    border?: number;
    ml?: number;
    mb?: number;
    mt?: number;
    mr?: number;
    maxW?:number|string;
    align?:string;
    children: React.ReactNode
}

export function PlaceLoader({ visible, height, width, border, ml, mb, mt, mr, maxW, align, children }: PlaceLoaderProps) {

    const styles = {
        width,
        height,
        borderRadius: border,
        marginRight: mr || 0,
        marginLeft: ml || 0,
        marginBottom: mb || 0,
        marginTop: mt || 0,
        maxWidth: maxW,
        alignSelf: align,
    };

    return (
        <ShimmerPlaceHolder
            visible={visible}
            shimmerStyle={styles}
        >
            {children}
        </ShimmerPlaceHolder>
    );
}
