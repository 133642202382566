export const defaultTheme = {
    colors: {
        purple: '#7630BE',
        purpleDark: '#5209A8',
        orange: '#EF510A',
        white: '#fafafa',
        gray: '#636E72',
        grayLight: '#B2BEC3',
        lightGray: '#e8ebe9',
        black: '#000000',
        red: '#FF7171',
        star: '#ED8A1A',
        green: '#98C828',
        yellow: '#ED8A19',
        chart: {
            empty: '#B2BEC3',
            guests: {
                defaulters: '#00E1FF',
                gift: '#9719A2',
                awaiting: '#ED8A1A',
            },
            scans: {
                correct: '#98C828',
                incorrect: '#FF7171',
                refused: '#FF0000',
            },
            presence: {
                presence: '#98C828',
                nullPresence: '#B2BEC3',
                noPresence: '#FF7171',
            },
        },
    },
    spacings: {
        xxsmall: '5px',
        xsmall: '12px',
        small: '16px',
        medium: '24px',
        large: '32px',
        xlarge: '64px',
        xxlarge: '128px',
    },
    font: {
        light: 'Montserrat_300Light',
        regular: 'Montserrat_400Regular',
        normal: 'Montserrat_500Medium',
        bold: 'Montserrat_700Bold',
        sizes: {
            xxsmall: '10px',
            xsmall: '12px',
            small: '14px',
            medium: '16px',
            intermediate: '18px',
            large: '20px',
        },
    },
    layers: {
        base: 0,
        overlay: 1,
        overlay2: 2,
        overlay3: 3,
        overlay4: 4,
    },
};

export type Theme = typeof defaultTheme;
