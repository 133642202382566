import { StatusBar, Platform, Dimensions } from 'react-native';
import { RFValue, RFPercentage } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';
import { BlurView } from 'expo-blur';
import { AntDesign } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../../styles/theme';

const windowWidth = Dimensions.get('window').width;

const isWeb = Platform.OS === 'web';

interface GoBackButtonProps {
    top: number;
}
interface SafeProps {
    hex: string;
}

interface NameUserProps{
    color:string
}

export const Safe = styled.ScrollView.attrs({
    contentContainerStyle: {
        flex: 1,
    },
})<SafeProps>`

    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
    background-color: ${({ hex }) => hex};
`;

export const ImageEvent = styled.Image.attrs({
    resizeMode: windowWidth < 650 ? 'cover' : 'contain',
})`
    height: ${windowWidth < 650 ? '40%' : '270px'};
`;
export const Content = styled.View`
    width: 100%;
    max-width: 350px;
    padding: ${RFValue(10)}px ${isWeb ? '2px' : `${RFValue(20)}px`};
    ${isWeb && 'width: 30.875rem; align-self: center;'};
    margin-top: -60px;
    /* margin-bottom: 70px; */
`;
export const ImageUser = styled.Image`
    height: 140px;
    width: 140px;
    border-radius: 5px;
    align-self: center;
    border-radius: 50%;
    margin-bottom: 30px;
    border: 5px solid ${theme.colors.white};
    background-color: ${theme.colors.grayLight} ;
`;
export const NameUser = styled.Text<NameUserProps>`
    font-family: ${theme.font.bold};
    /* font-size: ${RFValue(18)}px; */
    font-size: ${isWeb ? '20px' : `${RFValue(18)}px`};
    color: ${(props) => props.color};
    text-align: center;
    margin: 0px 0px 10px;
`;

export const GoBackButton = styled.TouchableOpacity<GoBackButtonProps>`
    position: absolute;
    top: ${({ top }) => (isWeb ? '16px' : `${top}px`)};
    right: 16px;
    width: 45px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    z-index: 1;
`;

export const BlurGoBackButton = styled(BlurView)`
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 40px;
    overflow: hidden;
    border-radius: 4px;
`;

export const GoBackButtonIcon = styled(AntDesign)`
    font-size: 26px;
    color: ${theme.colors.black};
`;

export const ImageBottom = styled.ImageBackground.attrs(
    isWeb ? { resizeMode: 'repeat' } : {},
)`
    height: ${isWeb ? `${RFPercentage(2)}px` : `${RFPercentage(5)}px`};
    width: 100%;
    position: absolute;
    top: ${isWeb ? '100%' : '98%'};
    bottom: 0;
`;
