import React, { useEffect, useState } from 'react';
import { TouchableWithoutFeedback, Modal, Platform } from 'react-native';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useSelector } from 'react-redux';
import AwesomeAlert from 'react-native-awesome-alerts';
import { Loading } from '../../../components/Loading';
import { InputHookForm } from '../../../components/InputHookform';
import { Button } from '../../../components/Button';
import { HostProps } from '../HostsScreen/components/CardHost';
import { Errors } from '../../../components/Errors';
import { LoadingPhoto } from '../../../components/LoadingPhoto';
import { hostUpdate } from '../../../services/hosts';
import { uploadFile } from '../../../services/toolsApi';
import { getAllEvents } from '../../../services/events';
import photoUserEmpty from '../../../assets/images/user/user-photo-hosts.png';
import * as S from './styles';

export type HostPropsRoute = {
    events: any[];
} & HostProps;

export type ListSelectProps = {};
interface HostsDetailsScreen {
    route: RouteProp<{
        params: {
            host: HostPropsRoute;
        };
    }>;
    navigation: NavigationProp<{}>;
}

export function HostEditScreen({ route, navigation }: HostsDetailsScreen) {
    const isWeb = Platform.OS === 'web';
    const { host } = route.params;
    const { user } = useSelector((state: any) => state.user.user);
    const [hostImage, setHostImage] = useState(host.photo);
    const [hostImageUrl, setHostImageUrl] = useState(host.photo);
    const [filtersSelectedCategories, setFiltersSelectedCategories] = useState<any[]>([]);
    const [events, setEvents] = useState<any[]>([]);
    const [modalEvents, setModalEvents] = useState(false);
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm();

    const pickImageHostImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        if (!result.cancelled) {
            setHostImage(result.uri);
            setLoadingPhoto(true);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                setHostImageUrl(response.result);
                setLoadingPhoto(false);
            }
        }
    };

    const handleGoBack = () => {
        isWeb
            ? navigation.reset({
                index: 0,
                routes: [{ name: 'Anfitriões' } as never],
            })
            : navigation.navigate('Anfitriões' as never, {
                reload: true,
            } as never);
    };

    const handleNavigationHost = () => {
        isWeb
            ? navigation.reset({
                index: 0,
                routes: [{ name: 'Anfitriões' } as never],
            })
            : navigation.navigate('Anfitriões' as never, {
                reload: true,
            } as never);
    };

    const handleSave = async (data) => {
        if (filtersSelectedCategories.length === 0) {
            setError('idEvents', { type: 'validate', message: 'Selecione pelos menos um evento' });
        } else {
            const dataFormated = {
                ...data,
                idEvents: filtersSelectedCategories,
                photo: hostImageUrl,
            };
            const response = await hostUpdate(host.id, dataFormated);
            if (response?.status === 200) {
                setOpenModalSuccess(true);
            } else if (response.message.includes('nova senha')) {
                setError('newPassword', {
                    type: 'validate',
                    message: 'A nova senha deve conter pelo menos 6 dígitos.',
                });
            } else {
                setError('password', {
                    type: 'validate',
                    message: 'A senha não confere com a atual.',
                });
            }
        }
    };

    function ErrorsComponent({ message }) {
        return <Errors message={message} />;
    }

    const handleOpenModal = () => {
        setModalEvents(!modalEvents);
    };

    const getEvents = async () => {
        getAllEvents(user.id, user.type).then((response) => {
            delete response.result.list.main;
            const values = Object.values(response.result.list);
            // @ts-ignore
            const flatten = [].concat(...values);
            const formatted = flatten.map((e: any) => ({
                label: e.name,
                value: e.id,
            }));
            setLoading(false);
            setEvents(formatted);
        });
    };

    function handleSelectedFilterCategories(filter: number) {
        if (!filtersSelectedCategories.includes(filter)) {
            setFiltersSelectedCategories([
                ...filtersSelectedCategories,
                filter,
            ]);
        } else {
            setFiltersSelectedCategories((oldState) => oldState.filter((e) => e !== filter));
        }
    }

    useEffect(() => {
        getEvents();
        if (host.events[0] !== null) {
            const eventsCurrent = host.events.map((e) => {
                return e?.id;
            });
            setFiltersSelectedCategories(eventsCurrent);
        }
    }, [route]);

    useEffect(() => {
        clearErrors('idEvents');
    }, [
        filtersSelectedCategories,
    ]);

    useEffect(() => {
        navigation.setOptions({
            title: `Editar anfitrião | ${host.name}`,
        });
    }, [navigation, route]);

    return (
        <>
            {loading ? <Loading /> : (
                <>
                    <S.Safe>
                        <S.Header>
                            <S.ButtonGoback onPress={handleGoBack}>
                                <S.ButtonGobackIcon name='close' />
                                <S.NamePage>Editar</S.NamePage>
                            </S.ButtonGoback>
                            <S.ButtonSave disabled={loadingPhoto} onPress={handleSubmit(handleSave)}>
                                <S.ButtonSaveText>Salvar</S.ButtonSaveText>
                            </S.ButtonSave>
                        </S.Header>
                        <S.Scroll>
                            <S.ButtomImageHost onPress={pickImageHostImage}>
                                <S.ImageHost
                                    source={
                                        hostImage ? { uri: hostImage } : photoUserEmpty
                                    }
                                    resizeMode='cover'
                                />
                                {loadingPhoto && <LoadingPhoto />}
                                <S.IconCan name='camera' withImage={hostImage} />
                            </S.ButtomImageHost>
                            <S.Form>
                                <InputHookForm
                                    errors={errors.name}
                                    name='name'
                                    control={control}
                                    label='Nome'
                                    type='text'
                                    placeholder='Digite o nome do anfitrião'
                                    valueInicial={host.name}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name='name'
                                    render={ErrorsComponent}
                                />
                                <InputHookForm
                                    errors={errors.email}
                                    name='email'
                                    control={control}
                                    label='E-mail'
                                    type='email'
                                    placeholder='Digite o email do anfitrião'
                                    valueInicial={host.email}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name='email'
                                    render={ErrorsComponent}
                                />
                                <InputHookForm
                                    errors={errors.telephone}
                                    name='telephone'
                                    control={control}
                                    label='Telefone'
                                    type='phone'
                                    placeholder='(12) 12345-6789'
                                    valueInicial={host.telephone}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name='telephone'
                                    render={ErrorsComponent}
                                />
                                <InputHookForm
                                    errors={errors.password}
                                    name='password'
                                    control={control}
                                    label='Senha atual'
                                    type='passwordNotRequired'
                                    placeholder='Digite a senha atual'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name='password'
                                    render={ErrorsComponent}
                                />
                                <InputHookForm
                                    errors={errors.newPassword}
                                    name='newPassword'
                                    control={control}
                                    label='Nova Senha'
                                    type='passwordNotRequired'
                                    placeholder='Digite a nova senha'
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name='newPassword'
                                    render={ErrorsComponent}
                                />
                                <S.ButtonPicker errors={errors.idEvents}>
                                    <S.ButtonPickerHeader onPress={handleOpenModal}>
                                        <S.ButtonPickerText>Eventos</S.ButtonPickerText>
                                        <S.ButtonPickerIcon name='down' />
                                    </S.ButtonPickerHeader>
                                    <S.EventsSelectedBox>
                                        {events.map((e: any) => {
                                            if (
                                                filtersSelectedCategories.includes(
                                                    e.value,
                                                )
                                            ) {
                                                return (
                                                    <S.FilterForm
                                                        key={e.value}
                                                        onPress={() => {
                                                            handleSelectedFilterCategories(Number(e.value));
                                                        }}
                                                        selected={filtersSelectedCategories.includes(Number(e.value))}
                                                    >
                                                        <S.FilterText
                                                            selected={filtersSelectedCategories.includes(Number(e.value))}
                                                        >
                                                            {String(e.label)}
                                                        </S.FilterText>
                                                        <S.FilterFormIconClose name='close' />
                                                    </S.FilterForm>
                                                );
                                            }
                                            return null;
                                        })}
                                    </S.EventsSelectedBox>
                                </S.ButtonPicker>
                                <ErrorMessage
                                    errors={errors}
                                    name='idEvents'
                                    render={ErrorsComponent}
                                />
                            </S.Form>
                        </S.Scroll>
                        <Modal
                            animationType='slide'
                            transparent
                            visible={modalEvents}
                            onRequestClose={handleOpenModal}
                        >
                            <TouchableWithoutFeedback onPress={handleOpenModal}>
                                <S.ModalContainer>
                                    <S.ModalContent>
                                        <S.FilterBox>
                                            {events.map((e) => (
                                                <S.Filter
                                                    key={e.value}
                                                    onPress={() => {
                                                        handleSelectedFilterCategories(
                                                            e.value,
                                                        );
                                                    }}
                                                    selected={filtersSelectedCategories.includes(Number(e.value))}
                                                >
                                                    <S.FilterText
                                                        selected={filtersSelectedCategories.includes(Number(e.value))}
                                                    >
                                                        {e.label}
                                                    </S.FilterText>
                                                </S.Filter>
                                            ))}
                                        </S.FilterBox>
                                        <S.ContainerButtonModal>
                                            <Button
                                                title='CONTINUAR'
                                                onClick={handleOpenModal}
                                            />
                                        </S.ContainerButtonModal>
                                    </S.ModalContent>
                                </S.ModalContainer>
                            </TouchableWithoutFeedback>
                        </Modal>
                    </S.Safe>
                    <AwesomeAlert
                        show={openModalSuccess}
                        title='Anfitrião editado'
                        message='Anfitrião editado com sucesso.'
                        closeOnTouchOutside={false}
                        closeOnHardwareBackPress={false}
                        showConfirmButton
                        confirmText='OK'
                        confirmButtonColor='#98C828'
                        onConfirmPressed={handleNavigationHost}
                    />
                </>
            )}
        </>
    );
}
