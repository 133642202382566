import { Platform, TouchableOpacityProps, Dimensions } from 'react-native';
import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';

import { AntDesign } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../../../../styles/theme';

const windowWidth = Dimensions.get('window').width;

const isWeb = Platform.OS === 'web';

interface ContainerProps extends TouchableOpacityProps {
    blocked: string | null
}

export const Shadow = Platform.OS === 'android' && {
    shadowColor: '#000',
    shadowOffset: {
        width: 100,
        height: 8,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,
    elevation: 10,
};

export const Container = styled.TouchableOpacity<ContainerProps>`
    width: ${isWeb && windowWidth > 650 ? '40%' : '100%'};
    background-color: #fff;
    margin-bottom: ${isWeb && windowWidth > 650 ? '1rem' : `${RFValue(12)}px`};
    padding: 10px;
    box-shadow: 5px 5px 6px #00000029;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    align-content: flex-end;
    ${({ blocked }) => blocked !== null && 'opacity: 0.5'}
`;

export const Box = styled.View`
    flex-direction: row;
    max-width: 70%;
`;

export const Image = styled.Image`
    height: ${isWeb && windowWidth > 650 ? '2.875rem' : `${RFValue(46)}px`};
    width: ${isWeb && windowWidth > 650 ? '2.875rem' : `${RFValue(46)}px`};
    border-radius: 4px;
    margin-right: 12px;
`;

export const Content = styled.View``;

export const Name = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : `${RFValue(12)}px`};
    font-family: ${theme.font.bold};
    color: ${theme.colors.gray};
    margin-bottom: 3px;
`;

export const Email = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.625rem' : `${RFValue(10)}px`};
    font-family: ${theme.font.normal};
    color: ${theme.colors.gray};
`;

export const Phone = styled.Text`
    font-size: ${isWeb && windowWidth > 650 ? '0.625rem' : `${RFValue(10)}px`};
    font-family: ${theme.font.normal};
    color: ${theme.colors.gray};
`;

export const ButtonStar = styled.TouchableOpacity``;

export const Icon = styled(AntDesign)`
    font-size: ${RFValue(14)}px;
    color: ${({ star }: any) => (star ? theme.colors.star : theme.colors.gray)};
`;
