
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native"

import { AntDesign } from '@expo/vector-icons';

import { defaultTheme as theme } from '../../styles/theme';

export const ButtonStar = styled.TouchableOpacity`
`;


export const Icon = styled(AntDesign)``
