import styled from 'styled-components/native';
import { defaultTheme as theme } from '../../styles/theme';


export const Container = styled.View`
    background-color: ${theme.colors.white};
    flex: 1;
`;

export const Wrapper = styled.View`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.white};
`;

export const BoxImage = styled.View`
`;

export const TextLogo = styled.Image`
    width: 60px;
    height: 60px;
`;

export const Logo = styled.Image`
    width: 204.22;
    height: 109.15;
`;

export const LogoDesktop = styled.Image`
    width: 200;
    height: 200;
`;

export const ContainerLogo = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const TopImage = styled.Image`
    width: 100%;
    height: 25%;
`;

export const BottomImage = styled.Image`
    width: 100%;
    height: 15%;
`;

export const TopImageDesktop = styled.Image`
    width: 100%;
    height: 15%;
`;

export const BottomDesktopImage = styled.Image`
    width: 100%;
    height: 15%;
    opacity: 44%;
`;

export const SingInFormContainer = styled.View`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: #FFFFFF; */
    /* z-index: ${theme.layers.overlay4}; */
`;
