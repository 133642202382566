import { StatusBar, Platform, Dimensions } from 'react-native';
import { RFValue, RFPercentage } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';


import { BlurView } from 'expo-blur';
import { AntDesign } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../../styles/theme';

const windowWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === 'web';

interface GoBackButtonProps {
    top: number
}

export const Safe = styled.ScrollView.attrs(
    {
        contentContainerStyle: {
            flex: 1,
        },
    },
)`
    flex: 1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const ImageEvent = styled.Image`
    height: 270px;
`;
export const Content = styled.View`
    width: 100%;
    max-width: 350px;
    padding: ${RFValue(10)}px ${isWeb ? '2px' : `${RFValue(20)}px`};
    ${isWeb && 'width: 30.875rem; align-self: center;'};
    margin-top: -125px;
    /* margin-bottom: 70px; */
`;
export const ImageUser = styled.Image`
    height: 140px;
    width: 140px;
    border-radius: 5px;
    align-self: center;
`;
export const NameUser = styled.Text`
    font-family: ${theme.font.bold};
    /* font-size: ${RFValue(18)}px; */
    font-size: ${isWeb ? '20px' : `${RFValue(18)}px`};
    color: ${theme.colors.purpleDark};
    text-align: center;
    margin: 30px 0px;
`;


export const GoBackButton = styled.TouchableOpacity<GoBackButtonProps>`
    position: absolute;
    top: ${({ top }) => (isWeb ? '16px' : `${top}px`)};
    right: 16px;
    width: 125px;
    height: 120px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    z-index: 1;
`;

export const BlurGoBackButton = styled(BlurView)`
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 40px;
    overflow: hidden;
    border-radius: 4px;
`;

export const GoBackButtonIcon = styled(AntDesign)`
    font-size: 26px;
    color: ${theme.colors.black};
`;

export const ImageBottom = styled.ImageBackground.attrs(isWeb ? {
    resizeMode: 'repeat',
} : {})`
    height: ${isWeb && windowWidth > 650 ? `${RFPercentage(2)}px` : `${RFPercentage(5)}px`};
    width: 100%;
    position: absolute;
    bottom: 0;
    /* background-color: #FAFAFA; */
`;
