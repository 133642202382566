import { Dimensions, Platform } from 'react-native';
import styled from 'styled-components/native';

import { defaultTheme as theme } from '../../../../../styles/theme';

const windowWidth = Dimensions.get('window').width;
const isWeb = Platform.OS === 'web';

export const Shadow = Platform.OS === 'android' && {
    shadowColor: '#000',
    shadowOffset: {
        width: 100,
        height: 8,
    },
    shadowOpacity: 0.1,
    shadowRadius: 50,

    elevation: 10,
};

export const Container = styled.View`
    background-color: #fff;
    border-radius: 4px;
    padding: 9px 12px;
    margin-top: 12px;
    box-shadow: 0px 3px 6px #00000029;
    justify-content: center;
`;
export const Title = styled.Text`
    color: ${theme.colors.gray};
    font-family: ${theme.font.bold};
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : '12px'};
`;
export const Info = styled.Text`
    font-family: ${theme.font.regular};
    color: ${theme.colors.gray};
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : '12px'};
    margin-top: 2px;
`;
