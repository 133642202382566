import { api } from './api';
import { getToken } from './functions';
import { ErrorAlert } from '../components/ErrorAlert';

interface HostCreateProps {
    name: string,
    email: string,
    password: string,
    telephone: string,
    photo: string
}

interface HostUpdateProps {
    name: string,
    email: string,
    telephone: string,
    photo: string,
    password: string
    newPassword: string
}

export async function hostsList(idAdm?: number, idEvent?: number) {
    const token = await getToken();
    try {
        const { data } = await api.get(idEvent ? `hosts?idAdm=${idAdm}&idEvent${idEvent}` : `hosts?idAdm=${idAdm}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error: any) {
        return error;
    }
}

export async function hostsListNoAdm() {
    const token = await getToken();
    try {
        const { data } = await api.get('hosts', {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error: any) {
        return error;
    }
}

export async function hostBlocked(idHost: number, isBlocked: boolean) {
    const token = await getToken();
    try {
        const { data } = await api.put(`hosts/blocked?idHost=${idHost}&isBlocked=${isBlocked}`, {}, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error: any) {
        return error;
    }
}

export async function hostsFavorite(idHost: number, isFavorite: boolean, idAdmin: number): Promise<any> {
    const token = await getToken();
    try {
        const data = await api.post('/favorite/hosts', {
            idAdm: idAdmin,
            idHost,
            isFavorite,
        }, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.data.message);
        }
        return data;
    } catch (error: any) {
        return error;
    }
}

export async function hostDelete(idHost: number): Promise<any> {
    try {
        const token = await getToken();
        const response = await api.delete('/hosts', {
            headers: {
                'x-access-token': token,
            },
            params: {
                idHost,
            },
        });
        if (response.data.status !== 200) {
            ErrorAlert('Algo deu errado!', response.data.message);
        }
        return response;
    } catch (error: any) {
        return error;
    }
}

export async function hostUpdate(idHost: number, host: HostUpdateProps) {
    const token = await getToken();
    try {
        const { data } = await api.put(`/hosts?idHost=${idHost}`, host, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error: any) {
        return error;
    }
}

export async function hostCreate(host: HostCreateProps) {
    const token = await getToken();
    try {
        const response = await api.post('/hosts', host, {
            headers: {
                'x-access-token': token,
            },
        });
        if (response.data.status !== 201) {
            ErrorAlert('Algo deu errado!', response.data.message);
        }
        return response;
    } catch (error: any) {
        return error;
    }
}

export async function hostsListByEvent(idEvent: number, searchTxt: string, status: string) {
    const url = `/guests?idEvent=${idEvent}&searchTxt=${searchTxt}&status=${status}`;

    const token = await getToken();
    try {
        const { data } = await api.get(url, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error: any) {
        return error;
    }
}
