import { Platform } from 'react-native';

import { DesktopView } from './views/DesktopView';
import { MobileView } from './views/MobileView';

import * as S from './styles';

export function SignInScreen(navigation: any) {
    return (
        <S.Container>
            {Platform.OS === 'web'
                ? <DesktopView navigation={navigation} />
                : <MobileView navigation={navigation} />}
        </S.Container>
    );
}
