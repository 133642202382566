/* eslint-disable */
import * as React from 'react';
/* eslint-disable-next-line */
import Svg, { SvgProps, G, Path } from 'react-native-svg';

const LogoSvg = (props: SvgProps) => (
    <Svg
        xmlns='http://www.w3.org/2000/svg'
        width={73.456}
        height={85.398}
        {...props}
    >
        <G data-name='Grupo 160015'>
            <Path
                data-name='Caminho 83298'
                d='M4.408 73.132H1.1a1.1 1.1 0 0 1-1.1-1.1V51.019a1.1 1.1 0 0 1 .351-.808l3.314-3.087a1.1 1.1 0 0 1 .752-.3h9.74a1.1 1.1 0 0 1 1.1 1.1v17.421a1.1 1.1 0 0 1-1.1 1.1H6.616a1.1 1.1 0 0 0-1.1 1.1v4.468a1.1 1.1 0 0 1-1.1 1.1m5.334-12.8v-7.379a1.1 1.1 0 0 0-1.1-1.1H6.616a1.1 1.1 0 0 0-1.1 1.1v7.379a1.1 1.1 0 0 0 1.1 1.1h2.031a1.1 1.1 0 0 0 1.1-1.1'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83299'
                d='M32.059 73.132H18.713a1.1 1.1 0 0 1-1.1-1.1v-24.1a1.1 1.1 0 0 1 1.1-1.1h3.3a1.1 1.1 0 0 1 1.1 1.1v19.112a1.1 1.1 0 0 0 1.1 1.1h2.3a1.1 1.1 0 0 0 1.1-1.1V47.932a1.1 1.1 0 0 1 1.1-1.1h3.323a1.1 1.1 0 0 1 1.1 1.1v24.1a1.1 1.1 0 0 1-1.1 1.1'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83300'
                d='M35.618 72.028v-3.016a1.1 1.1 0 0 1 .1-.469l7.107-15.14a1.1 1.1 0 0 0-1-1.573h-4.563a1.1 1.1 0 0 1-1.1-1.1v-2.795a1.1 1.1 0 0 1 1.1-1.1h10.314a1.1 1.1 0 0 1 1.027.7l1.191 3.029a1.1 1.1 0 0 1-.024.863l-6.941 15.161a1.1 1.1 0 0 0 1 1.563h4.762a1.1 1.1 0 0 1 1.1 1.1v2.777a1.1 1.1 0 0 1-1.1 1.1H36.722a1.1 1.1 0 0 1-1.1-1.1'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83301'
                d='M52.354 72.028v-24.1a1.1 1.1 0 0 1 1.1-1.1h3.3a1.1 1.1 0 0 1 1.1 1.1V67.04a1.1 1.1 0 0 0 1.1 1.1h5.506a1.1 1.1 0 0 1 1.1 1.1v2.777a1.1 1.1 0 0 1-1.1 1.1H53.458a1.1 1.1 0 0 1-1.1-1.1'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83302'
                d='M73.456 47.932V52.2a1.1 1.1 0 0 1-1.1 1.1h-3.3a1.1 1.1 0 0 1-1.1-1.1v-4.268a1.1 1.1 0 0 1 1.1-1.1h3.3a1.1 1.1 0 0 1 1.1 1.1m0 8.076v16.02a1.1 1.1 0 0 1-1.1 1.1h-3.3a1.1 1.1 0 0 1-1.1-1.1v-16.02a1.1 1.1 0 0 1 1.1-1.1h3.3a1.1 1.1 0 0 1 1.1 1.1'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83303'
                d='M12.2 84.249a1.827 1.827 0 0 1-1.446.68 2.041 2.041 0 0 1-1-.26 4.366 4.366 0 0 1-.933-.743.484.484 0 0 0-.3-.138 3.177 3.177 0 0 1-1.372-.416 2.907 2.907 0 0 1-1.08-1.08 3.164 3.164 0 0 1 .013-3.07 2.9 2.9 0 0 1 1.123-1.084 3.444 3.444 0 0 1 3.2 0 2.9 2.9 0 0 1 1.119 1.084 3 3 0 0 1 .403 1.551 3.046 3.046 0 0 1-.31 1.374 2.887 2.887 0 0 1-.868 1.042 3.046 3.046 0 0 1-1.28.553 1.84 1.84 0 0 0 1.293.689 1.441 1.441 0 0 0 1.131-.544Zm-5.575-2.22a2.373 2.373 0 0 0 .9.885 2.688 2.688 0 0 0 2.556 0 2.351 2.351 0 0 0 .893-.885 2.613 2.613 0 0 0 0-2.517 2.349 2.349 0 0 0-.893-.884 2.684 2.684 0 0 0-2.556 0 2.371 2.371 0 0 0-.9.884 2.584 2.584 0 0 0 0 2.517'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83304'
                d='M17.649 83.748a.474.474 0 0 1-.386-.2l-1.219-1.714a4.4 4.4 0 0 1-.476.026h-1.124a.474.474 0 0 0-.474.474v1.414h-.629v-5.48a.474.474 0 0 1 .474-.474h1.753a2.69 2.69 0 0 1 1.786.544 1.851 1.851 0 0 1 .647 1.5 1.935 1.935 0 0 1-.353 1.178 1.793 1.793 0 0 1-.532.48.456.456 0 0 0-.158.658l1.137 1.6Zm-.747-2.815a1.534 1.534 0 0 0 0-2.207 2.071 2.071 0 0 0-1.352-.387h-1.107a.474.474 0 0 0-.474.474v2.036a.474.474 0 0 0 .474.474h1.107a2.055 2.055 0 0 0 1.352-.391'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83305'
                d='M25.922 83.203v.544h-3.736a.474.474 0 0 1-.474-.474v-5a.474.474 0 0 1 .474-.474h3.608v.544h-2.972a.474.474 0 0 0-.474.474v1.169a.474.474 0 0 0 .474.474h2.6v.536h-2.6a.474.474 0 0 0-.474.474v1.262a.474.474 0 0 0 .474.474Z'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83306'
                d='m29.871 83.56-.9-1.187a.474.474 0 0 0-.755 0l-.908 1.189a.474.474 0 0 1-.377.187h-.446l1.546-2.006a.474.474 0 0 0 0-.58l-1.46-1.888h.447a.474.474 0 0 1 .376.186l.825 1.076a.474.474 0 0 0 .753 0l.968-1.261h.663l-1.459 1.886a.474.474 0 0 0 0 .581l1.56 2.006h-.454a.474.474 0 0 1-.378-.188'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83307'
                d='M35.147 79.524a2.037 2.037 0 0 1 .8.8 2.577 2.577 0 0 1 0 2.373 2.046 2.046 0 0 1-.8.8 2.288 2.288 0 0 1-1.144.285 2.113 2.113 0 0 1-.983-.225 1.855 1.855 0 0 1-.719-.659v2.492h-.6v-6.124h.578v.884a1.87 1.87 0 0 1 .719-.681 2.11 2.11 0 0 1 1.008-.238 2.294 2.294 0 0 1 1.139.285m-.332 3.513a1.537 1.537 0 0 0 .6-.617 1.878 1.878 0 0 0 .217-.91 1.85 1.85 0 0 0-.217-.905 1.571 1.571 0 0 0-.6-.617 1.756 1.756 0 0 0-1.705 0 1.579 1.579 0 0 0-.6.617 1.85 1.85 0 0 0-.217.905 1.878 1.878 0 0 0 .217.91 1.545 1.545 0 0 0 .6.617 1.786 1.786 0 0 0 1.705 0'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83308'
                d='M41.363 81.697h-3.126a.475.475 0 0 0-.432.68 1.551 1.551 0 0 0 .352.447 1.777 1.777 0 0 0 1.224.429 1.933 1.933 0 0 0 .766-.149 1.672 1.672 0 0 0 .6-.438l.34.391a1.911 1.911 0 0 1-.744.544 2.516 2.516 0 0 1-.983.187 2.48 2.48 0 0 1-1.22-.293 2.107 2.107 0 0 1-.829-.812 2.312 2.312 0 0 1-.3-1.174 2.4 2.4 0 0 1 .285-1.173 2.057 2.057 0 0 1 .782-.808 2.184 2.184 0 0 1 1.118-.289 2.16 2.16 0 0 1 1.114.289 2.053 2.053 0 0 1 .774.8 2.42 2.42 0 0 1 .281 1.178Zm-3.245-1.526a1.62 1.62 0 0 0-.5 1.076h3.172a1.622 1.622 0 0 0-.5-1.076 1.642 1.642 0 0 0-2.177 0'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83309'
                d='M43.817 79.469a2.15 2.15 0 0 1 1.042-.23v.587l-.145-.008a1.43 1.43 0 0 0-1.106.433 1.728 1.728 0 0 0-.4 1.216v2.28h-.6v-4.474h.578v.876a1.461 1.461 0 0 1 .634-.681'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83310'
                d='M45.947 78.168a.418.418 0 0 1-.127-.306.408.408 0 0 1 .127-.3.429.429 0 0 1 .315-.128.435.435 0 0 1 .315.124.394.394 0 0 1 .127.293.445.445 0 0 1-.757.314m.009 1.106h.6v4.474h-.6Z'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83311'
                d='M52.139 81.697h-3.126a.475.475 0 0 0-.432.68 1.55 1.55 0 0 0 .352.447 1.777 1.777 0 0 0 1.224.429 1.933 1.933 0 0 0 .766-.149 1.673 1.673 0 0 0 .6-.438l.34.391a1.911 1.911 0 0 1-.744.544 2.516 2.516 0 0 1-.983.187 2.48 2.48 0 0 1-1.22-.293 2.107 2.107 0 0 1-.829-.812 2.311 2.311 0 0 1-.3-1.174 2.4 2.4 0 0 1 .285-1.173 2.057 2.057 0 0 1 .782-.808 2.184 2.184 0 0 1 1.119-.289 2.16 2.16 0 0 1 1.114.289 2.053 2.053 0 0 1 .774.8 2.42 2.42 0 0 1 .281 1.178Zm-3.245-1.526a1.62 1.62 0 0 0-.5 1.076h3.172a1.622 1.622 0 0 0-.5-1.076 1.642 1.642 0 0 0-2.177 0'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83312'
                d='M57 79.728a1.909 1.909 0 0 1 .5 1.425v2.594h-.6v-2.534a1.482 1.482 0 0 0-.349-1.063 1.309 1.309 0 0 0-1-.366 1.529 1.529 0 0 0-1.144.429 1.623 1.623 0 0 0-.421 1.186v2.347h-.6v-4.474h.578v.825a1.68 1.68 0 0 1 .685-.634 2.194 2.194 0 0 1 1.016-.225 1.828 1.828 0 0 1 1.339.489'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83313'
                d='M59.824 83.505a2.094 2.094 0 0 1-.821-.812 2.338 2.338 0 0 1-.3-1.179 2.311 2.311 0 0 1 .3-1.173 2.1 2.1 0 0 1 .821-.808 2.41 2.41 0 0 1 1.187-.289 2.3 2.3 0 0 1 1.033.226 1.715 1.715 0 0 1 .719.659l-.451.306a1.411 1.411 0 0 0-.561-.5 1.667 1.667 0 0 0-.74-.166 1.75 1.75 0 0 0-.872.217 1.525 1.525 0 0 0-.6.617 2.029 2.029 0 0 0 0 1.825 1.529 1.529 0 0 0 .6.612 1.75 1.75 0 0 0 .872.217 1.7 1.7 0 0 0 .74-.162 1.372 1.372 0 0 0 .561-.493l.451.306a1.7 1.7 0 0 1-.723.659 2.3 2.3 0 0 1-1.029.225 2.417 2.417 0 0 1-1.187-.287'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83314'
                d='M67.771 81.697h-3.127a.475.475 0 0 0-.432.68 1.551 1.551 0 0 0 .352.447 1.777 1.777 0 0 0 1.224.429 1.933 1.933 0 0 0 .764-.149 1.672 1.672 0 0 0 .6-.438l.34.391a1.91 1.91 0 0 1-.744.544 2.517 2.517 0 0 1-.983.187 2.48 2.48 0 0 1-1.22-.293 2.108 2.108 0 0 1-.829-.812 2.313 2.313 0 0 1-.3-1.174 2.394 2.394 0 0 1 .285-1.173 2.056 2.056 0 0 1 .782-.808 2.183 2.183 0 0 1 1.118-.289 2.16 2.16 0 0 1 1.114.289 2.052 2.052 0 0 1 .774.8 2.419 2.419 0 0 1 .281 1.178Zm-3.245-1.526a1.62 1.62 0 0 0-.5 1.076h3.172a1.622 1.622 0 0 0-.5-1.076 1.642 1.642 0 0 0-2.177 0'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83315'
                d='M35.9 19.952h1.6a.838.838 0 0 0 .838-.838v-1.6a.838.838 0 0 0-.838-.838h-1.6a.838.838 0 0 0-.838.838v1.6a.838.838 0 0 0 .838.838'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83316'
                d='M54.738 17.935v7.314a1.262 1.262 0 0 1-1.262 1.262H46.16a1.262 1.262 0 0 0-1.262 1.262v4.035a1.262 1.262 0 0 0 1.262 1.262h.756a1.262 1.262 0 0 0 1.262-1.262 2.017 2.017 0 0 1 2.018-2.017h2.421a2.121 2.121 0 0 1 2.12 2.121v3.177a1.262 1.262 0 0 1-1.262 1.262H43.76a2.137 2.137 0 0 1-2.137-2.137v-6.439a1.262 1.262 0 0 0-1.262-1.262h-4.035a1.262 1.262 0 0 1-1.266-1.263v-.761a1.262 1.262 0 0 1 1.262-1.262h10.594a1.262 1.262 0 0 0 1.262-1.262v-4.036a1.262 1.262 0 0 1 1.262-1.262h4.035a1.262 1.262 0 0 1 1.262 1.262'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83317'
                d='M20.096 6.835h3.69A1.435 1.435 0 0 0 25.221 5.4v-.41c0-.044.009-.086.013-.129l-.008-2.973A1.617 1.617 0 0 0 23.608.275h-1.666l-1.775.164a5.6 5.6 0 0 0-1.5 1.27v3.69a1.435 1.435 0 0 0 1.429 1.436'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83318'
                d='M36.907 10.114h-5.534v-8.4A1.435 1.435 0 0 0 29.938.279h-1.853v4.624c0 .029.009.057.009.087v3.69a1.435 1.435 0 0 1-1.435 1.435h-6.563a1.435 1.435 0 0 0-1.435 1.435v6.969a1.435 1.435 0 0 0 1.435 1.435h3.689a1.435 1.435 0 0 0 1.435-1.435v-3.695a1.435 1.435 0 0 1 1.435-1.435h10.248a1.435 1.435 0 0 0 1.435-1.435v-.41a1.435 1.435 0 0 0-1.435-1.435'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83319'
                d='M50.026 10.105c-.033 0-.065.01-.1.01h-3.494A1.532 1.532 0 0 1 44.9 8.583V1.808A1.532 1.532 0 0 0 43.368.276h-6.774a1.532 1.532 0 0 0-1.532 1.532v3.495a1.532 1.532 0 0 0 1.532 1.532h3.494a1.532 1.532 0 0 1 1.532 1.532v10.057a1.532 1.532 0 0 0 1.532 1.532h.215a1.532 1.532 0 0 0 1.532-1.532v-3.5a1.532 1.532 0 0 1 1.532-1.532h6.775a1.532 1.532 0 0 0 1.532-1.532v-1.755Z'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83320'
                d='M49.713 6.835h.215c.034 0 .065.008.1.01v-.007h3.066a1.648 1.648 0 0 0 1.647-1.648V1.808A1.532 1.532 0 0 0 53.209.276h-3.496a1.532 1.532 0 0 0-1.532 1.532v3.494a1.532 1.532 0 0 0 1.532 1.533'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83321'
                d='M23.688 29.79a1.116 1.116 0 0 1-.162-.014h-2.869a1.906 1.906 0 0 0-1.906 1.906v3.028a1.64 1.64 0 0 0 1.64 1.64h3.279a1.64 1.64 0 0 0 1.64-1.639v-3.28a1.607 1.607 0 0 0-1.623-1.64'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83322'
                d='M38.428 33.07v-1.64a1.64 1.64 0 0 0-1.64-1.64h-3.279a1.64 1.64 0 0 1-1.64-1.64v-9.838a1.64 1.64 0 0 0-1.64-1.64 1.64 1.64 0 0 0-1.64 1.64v3.28a1.64 1.64 0 0 1-1.64 1.64h-6.558a1.64 1.64 0 0 0-1.64 1.64v1.639h8.2a1.64 1.64 0 0 1 1.64 1.64v6.558a1.64 1.64 0 0 0 1.64 1.64h6.558a1.64 1.64 0 0 0 1.64-1.64Z'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83323'
                d='M23.08 7.159h-2.306a2.429 2.429 0 0 1-2.426-2.426V2.426A2.429 2.429 0 0 1 20.773 0h2.306a2.429 2.429 0 0 1 2.426 2.426v2.306a2.429 2.429 0 0 1-2.426 2.426M20.773.54a1.888 1.888 0 0 0-1.888 1.886v2.306a1.888 1.888 0 0 0 1.886 1.886h2.306a1.888 1.888 0 0 0 1.886-1.886V2.426A1.888 1.888 0 0 0 23.08.54Z'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83324'
                d='M23.096 36.619h-2.291a2.413 2.413 0 0 1-2.41-2.41v-2.291a2.413 2.413 0 0 1 2.41-2.41h2.291a2.413 2.413 0 0 1 2.41 2.41v2.291a2.413 2.413 0 0 1-2.41 2.41m-2.291-6.574a1.875 1.875 0 0 0-1.873 1.873v2.291a1.875 1.875 0 0 0 1.873 1.873h2.291a1.875 1.875 0 0 0 1.874-1.873v-2.291a1.875 1.875 0 0 0-1.874-1.873Z'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83325'
                d='M52.605 7.111h-2.291a2.413 2.413 0 0 1-2.41-2.41V2.41A2.413 2.413 0 0 1 50.314 0h2.291a2.413 2.413 0 0 1 2.41 2.41V4.7a2.413 2.413 0 0 1-2.41 2.41M50.314.537a1.875 1.875 0 0 0-1.873 1.873V4.7a1.876 1.876 0 0 0 1.873 1.874h2.291A1.876 1.876 0 0 0 54.478 4.7V2.41A1.875 1.875 0 0 0 52.605.537Z'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83326'
                d='M22.846 4.627h-1.789a.178.178 0 0 1-.178-.178V2.661a.178.178 0 0 1 .178-.178h1.789a.178.178 0 0 1 .178.178V4.45a.178.178 0 0 1-.178.178'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83327'
                d='M22.846 34.135h-1.789a.178.178 0 0 1-.178-.178v-1.789a.178.178 0 0 1 .178-.178h1.789a.178.178 0 0 1 .178.178v1.789a.178.178 0 0 1-.178.178'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83328'
                d='M52.354 4.627h-1.789a.178.178 0 0 1-.178-.178V2.661a.178.178 0 0 1 .178-.178h1.789a.178.178 0 0 1 .178.178V4.45a.178.178 0 0 1-.178.178'
                fill='#ef510a'
            />
            <Path
                data-name='Ret\xE2ngulo 147768'
                fill='none'
                d='M0 0h73.456v85.397H0z'
            />
            <Path
                data-name='Caminho 83360'
                d='M35.9 19.952h1.6a.838.838 0 0 0 .838-.838v-1.6a.838.838 0 0 0-.838-.838h-1.6a.838.838 0 0 0-.838.838v1.6a.838.838 0 0 0 .838.838'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83361'
                d='M54.738 17.935v7.314a1.262 1.262 0 0 1-1.262 1.262H46.16a1.262 1.262 0 0 0-1.262 1.262v4.035a1.262 1.262 0 0 0 1.262 1.262h.756a1.262 1.262 0 0 0 1.262-1.262 2.017 2.017 0 0 1 2.018-2.017h2.421a2.121 2.121 0 0 1 2.12 2.121v3.177a1.262 1.262 0 0 1-1.262 1.262H43.76a2.137 2.137 0 0 1-2.137-2.137v-6.439a1.262 1.262 0 0 0-1.262-1.262h-4.035a1.262 1.262 0 0 1-1.266-1.263v-.761a1.262 1.262 0 0 1 1.262-1.262h10.594a1.262 1.262 0 0 0 1.262-1.262v-4.036a1.262 1.262 0 0 1 1.262-1.262h4.035a1.262 1.262 0 0 1 1.262 1.262'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83362'
                d='M20.096 6.835h3.69A1.435 1.435 0 0 0 25.221 5.4v-.41c0-.044.009-.086.013-.129l-.008-2.973A1.617 1.617 0 0 0 23.608.275h-1.666l-1.775.164a5.6 5.6 0 0 0-1.5 1.27v3.69a1.435 1.435 0 0 0 1.429 1.436'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83363'
                d='M36.907 10.114h-5.534v-8.4A1.435 1.435 0 0 0 29.938.279h-1.853v4.624c0 .029.009.057.009.087v3.69a1.435 1.435 0 0 1-1.435 1.435h-6.563a1.435 1.435 0 0 0-1.435 1.435v6.969a1.435 1.435 0 0 0 1.435 1.435h3.689a1.435 1.435 0 0 0 1.435-1.435v-3.695a1.435 1.435 0 0 1 1.435-1.435h10.248a1.435 1.435 0 0 0 1.435-1.435v-.41a1.435 1.435 0 0 0-1.435-1.435'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83364'
                d='M50.026 10.105c-.033 0-.065.01-.1.01h-3.494A1.532 1.532 0 0 1 44.9 8.583V1.808A1.532 1.532 0 0 0 43.368.276h-6.774a1.532 1.532 0 0 0-1.532 1.532v3.495a1.532 1.532 0 0 0 1.532 1.532h3.494a1.532 1.532 0 0 1 1.532 1.532v10.057a1.532 1.532 0 0 0 1.532 1.532h.215a1.532 1.532 0 0 0 1.532-1.532v-3.5a1.532 1.532 0 0 1 1.532-1.532h6.775a1.532 1.532 0 0 0 1.532-1.532v-1.755Z'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83365'
                d='M49.713 6.835h.215c.034 0 .065.008.1.01v-.007h3.066a1.648 1.648 0 0 0 1.647-1.648V1.808A1.532 1.532 0 0 0 53.209.276h-3.496a1.532 1.532 0 0 0-1.532 1.532v3.494a1.532 1.532 0 0 0 1.532 1.533'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83366'
                d='M23.688 29.79a1.116 1.116 0 0 1-.162-.014h-2.869a1.906 1.906 0 0 0-1.906 1.906v3.028a1.64 1.64 0 0 0 1.64 1.64h3.279a1.64 1.64 0 0 0 1.64-1.639v-3.28a1.607 1.607 0 0 0-1.623-1.64'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83367'
                d='M38.428 33.07v-1.64a1.64 1.64 0 0 0-1.64-1.64h-3.279a1.64 1.64 0 0 1-1.64-1.64v-9.838a1.64 1.64 0 0 0-1.64-1.64 1.64 1.64 0 0 0-1.64 1.64v3.28a1.64 1.64 0 0 1-1.64 1.64h-6.558a1.64 1.64 0 0 0-1.64 1.64v1.639h8.2a1.64 1.64 0 0 1 1.64 1.64v6.558a1.64 1.64 0 0 0 1.64 1.64h6.558a1.64 1.64 0 0 0 1.64-1.64Z'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83368'
                d='M23.08 7.159h-2.306a2.429 2.429 0 0 1-2.426-2.426V2.426A2.429 2.429 0 0 1 20.773 0h2.306a2.429 2.429 0 0 1 2.426 2.426v2.306a2.429 2.429 0 0 1-2.426 2.426M20.773.54a1.888 1.888 0 0 0-1.888 1.886v2.306a1.888 1.888 0 0 0 1.886 1.886h2.306a1.888 1.888 0 0 0 1.886-1.886V2.426A1.888 1.888 0 0 0 23.08.54Z'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83369'
                d='M23.096 36.619h-2.291a2.413 2.413 0 0 1-2.41-2.41v-2.291a2.413 2.413 0 0 1 2.41-2.41h2.291a2.413 2.413 0 0 1 2.41 2.41v2.291a2.413 2.413 0 0 1-2.41 2.41m-2.291-6.574a1.875 1.875 0 0 0-1.873 1.873v2.291a1.875 1.875 0 0 0 1.873 1.873h2.291a1.875 1.875 0 0 0 1.874-1.873v-2.291a1.875 1.875 0 0 0-1.874-1.873Z'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83370'
                d='M52.605 7.111h-2.291a2.413 2.413 0 0 1-2.41-2.41V2.41A2.413 2.413 0 0 1 50.314 0h2.291a2.413 2.413 0 0 1 2.41 2.41V4.7a2.413 2.413 0 0 1-2.41 2.41M50.314.537a1.875 1.875 0 0 0-1.873 1.873V4.7a1.876 1.876 0 0 0 1.873 1.874h2.291A1.876 1.876 0 0 0 54.478 4.7V2.41A1.875 1.875 0 0 0 52.605.537Z'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83371'
                d='M22.846 4.627h-1.789a.178.178 0 0 1-.178-.178V2.661a.178.178 0 0 1 .178-.178h1.789a.178.178 0 0 1 .178.178V4.45a.178.178 0 0 1-.178.178'
                fill='#5209a8'
            />
            <Path
                data-name='Caminho 83372'
                d='M22.846 34.135h-1.789a.178.178 0 0 1-.178-.178v-1.789a.178.178 0 0 1 .178-.178h1.789a.178.178 0 0 1 .178.178v1.789a.178.178 0 0 1-.178.178'
                fill='#ef510a'
            />
            <Path
                data-name='Caminho 83373'
                d='M52.354 4.627h-1.789a.178.178 0 0 1-.178-.178V2.661a.178.178 0 0 1 .178-.178h1.789a.178.178 0 0 1 .178.178V4.45a.178.178 0 0 1-.178.178'
                fill='#ef510a'
            />
        </G>
    </Svg>
);

export default LogoSvg;
