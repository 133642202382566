export const validations = {
    text: {
        required: {
            value: true,
            message: 'Campo de preenchimento obrigatório',
        },
        minLength: {
            value: 3,
            message: 'Mínimo 3 caracteres',
        },
    },
    number: {
        required: {
            value: true,
            message: 'Campo de preenchimento obrigatório',
        },
    },
    cpf: {
        required: {
            value: true,
            message: 'Campo de preenchimento obrigatório',
        },
    },
    rg: {
        required: {
            value: true,
            message: 'Campo de preenchimento obrigatório',
        },
    },
    email: {
        required: {
            value: true,
            message: 'E-mail obrigatório.',
        },
        pattern: {
            // eslint-disable-next-line
            value: /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/,
            message: 'Digite um e-mail válido',
        },
    },
    phone: {
        required: {
            value: true,
            message: 'Campo de preenchimento obrigatório',
        },
        minLength: {
            value: 15,
            message: 'Formato de telefone inválido',
        },
    },
    password: {
        required: {
            value: true,
            message: 'Campo de preenchimento obrigatório',
        },
        minLength: {
            value: 6,
            message: 'A senha deve conter no mínimo 6 caracteres',
        },
    },
    date: {
        required: {
            value: true,
            message: 'Insira uma data',
        },
    },
    hours: {
        required: {
            value: true,
            message: 'Insira um horário',
        },
    },
    hex: {
        required: {
            value: true,
            message: 'Insira uma cor em hexadecimal',
        },
        pattern: {
            value: /[0-9a-fA-F]+/,
            message: 'Insira um hexadecimal válido',
        },
    },

    descriptions: {
        required: {
            value: true,
            message: 'Insira uma descrição',
        },
    },

};


export const masksHours = (text) => {
    const cleanText = text.replace(/\D+/g, '');

    let secondDigitMonthMask = /\d/;

    if (cleanText.charAt(0) === '0') {
        secondDigitMonthMask = /[0-9]/;
    }
    if (cleanText.charAt(0) === '1') {
        secondDigitMonthMask = /[0-9]/;
    }
    if (cleanText.charAt(0) === '2') {
        secondDigitMonthMask = /[0-3]/;
    }

    return [
        /[0-2]/,
        secondDigitMonthMask,
        ':',
        /[0-5]/,
        /[0-9]/,
    ];
};
