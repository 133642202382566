import * as React from 'react';
/* eslint-disable-next-line */
import Svg, { SvgProps, G, Circle, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
    <Svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} {...props}>
        <G
            transform='translate(1 1)'
            fill='none'
            stroke='#ff7171'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
        >
            <Circle data-name='Elipse 9403' cx={7} cy={7} r={7} />
            <Path data-name='Linha 332' d='m3.011 3.011 8.365 8.365' />
        </G>
    </Svg>
);

export default SvgComponent;
