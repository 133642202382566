import { api } from './api';
import { getToken } from './functions';
import { ErrorAlert } from '../components/ErrorAlert';

interface AddEventData {
    name?: string
    description?: string
    date?: string
    time?: string
    frontCover?: string,
    invitation?: string,
    color?: string,
    link_group_users:string;
    link_unique_user:string;
    isVaccination?: boolean,
    idCategory?: number
}

type FavoriteEventData = {
    idReference: number
    type: 'hosts' | 'adm'
    idEvent: number
    isFavorite: boolean
}


export async function listCategories() {
    try {
        const token = await getToken();
        const { data } = await api.get('/categories', {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch {
        throw new Error();
    }
}

export async function registerGuest(guests: Array<any>, hash: string) {
    try {
        const token = await getToken();
        const { data } = await api.post('/guests', {
            guests,
            idEvent: hash,
        }, {
            headers: {
                'x-access-token': token,
            },
        });
        return data;
    } catch {
        throw new Error();
    }
}

export async function dashboardEvent(idEvent: number) {
    try {
        const token = await getToken();
        const { data } = await api.get(`/events/dashboard?idEvent=${idEvent}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error: any) { return error; }
}

export async function getAllEvents(id: number, type: string, idsCategory?: string, period?: string, searchTxt?: string) {
    const url = `/events?idReference=${id}&type=${type}${idsCategory ? `&idsCategory=${idsCategory}` : ''}${period ? `&period=${period}` : ''}`;
    try {
        const token = await getToken();
        const { data } = await api.get(url, {
            headers: {
                'x-access-token': token,
            },
            params: {
                searchTxt,
            },
        });

        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error: any) {
        return error;
    }
}

export async function getEvent(hash: string) {
    try {
        const token = await getToken();
        const { data } = await api.get(`/events/uniqueEvent?hash=${hash}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error: any) {
        return error;
    }
}

export async function createEvent(data: AddEventData) {
    try {
        const token = await getToken();
        const response = await api.post('/events', data, {
            headers: {
                'x-access-token': token,
            },
        });
        if (response.data.status !== 201) {
            ErrorAlert('Algo deu errado!', response.data.message);
        }
        return response.data;
    } catch (error: any) {
        return error;
    }

}

export async function editEvent(data: AddEventData, id: number) {
    try {
        const token = await getToken();
        const response = await api.put(`/events?idEvent=${id}`, data, {
            headers: {
                'x-access-token': token,
            },
        });
        if (response.data.status !== 200) {
            ErrorAlert('Algo deu errado!', response.data.message);
        }
        return response.data;
    } catch (error: any) {
        return error;
    }
}

export async function deleteEvent(id: string) {
    try {
        const token = await getToken();
        const response = await api.delete(`/events?idEvent=${id}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (response.data.status !== 200) {
            ErrorAlert('Algo deu errado!', response.data.message);
        }
        return response.data;
    } catch (error: any) { return error; }
}

export async function favoriteEvent({ idEvent, idReference, isFavorite, type }: FavoriteEventData) {
    const token = await getToken();
    try {
        const response = await api.post('/favorite/events', {
            idReference,
            type,
            idEvent,
            isFavorite,
        }, {
            headers: {
                'x-access-token': token,
            },
        });
        if (response.data.status !== 200) {
            ErrorAlert('Algo deu errado!', response.data.message);
        }
        return response.data;
    } catch (error: any) {
        return error;
    }

}

export async function eventBlocked(idEvent: number, isBlocked: boolean) {
    const token = await getToken();
    try {
        const { data } = await api.put('events/blocked', {}, {
            headers: {
                'x-access-token': token,
            },
            params: {
                idEvent,
                isBlocked,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error: any) {
        return error;
    }
}
