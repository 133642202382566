import GeometricImage from '../../../../assets/images/qr-code-mobile-top.png';
import * as S from '../../styles';
import { motiStyles } from '../../motiStyles';

export function TopImage() {
    return (
        <S.TopImage
            resizeMode='contain'
            source={GeometricImage}
            style={motiStyles.topImageStyle}
        />
    );
}
