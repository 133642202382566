import styled, { css } from 'styled-components/native';
import { TextFieldProps } from './index';
import { defaultTheme as theme } from '../../../../styles/theme';

const wrapperModifiers = {
    error: () => css`
        border-color: ${theme.colors.red};
    `,
};

export const Container = styled.View`
    margin-bottom: ${theme.spacings.small};
`;

export const Wrapper = styled.TextInput<TextFieldProps>`
    ${({ errorMessage }) => css`
        padding: ${theme.spacings.xsmall};
        font-size: ${theme.font.sizes.small};
        border: 1px solid ${theme.colors.purple};
        border-radius: ${theme.spacings.xxsmall};
        ${errorMessage && wrapperModifiers.error()}
    `}
`;

export const Error = styled.Text`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.red};
`;
