import * as React from 'react';
/* eslint-disable-next-line */
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
    <Svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} {...props}>
        <G
            fill='none'
            stroke='#ff7171'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
        >
            <Path
                data-name='Caminho 83194'
                d='M7 19H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h4'
            />
            <Path data-name='Caminho 83195' d='m14 15 5-5-5-5' />
            <Path data-name='Linha 275' d='M19 10H7' />
        </G>
    </Svg>
);

export default SvgComponent;
