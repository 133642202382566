import { useEffect, useState } from 'react';
import { TouchableWithoutFeedback, Platform, Modal } from 'react-native';
import { NavigationProp } from '@react-navigation/native';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as Progress from 'react-native-progress';
import * as ImagePicker from 'expo-image-picker';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { LoadingPhoto } from '../../../components/LoadingPhoto';
import { getAllEvents } from '../../../services/events';
import { hostCreate } from '../../../services/hosts';
import { uploadFile } from '../../../services/toolsApi';
import { Loading } from '../../../components/Loading';
import { Heading } from '../../../components/Heading';
import { Button } from '../../../components/Button';
import { Header } from '../../../components/Header';
import { Errors } from '../../../components/Errors';
import { InputHookForm } from '../../../components/InputHookform';

import mobileCover from '../../../assets/images/user/user-photo.png';
import webCover from '../../../assets/images/event/webCover.png';

import { defaultTheme as theme } from '../../../styles/theme';
import * as S from './styles';


const isWeb = Platform.OS === 'web';

interface AddHostScreenProps {
    navigation: NavigationProp<{}>;
}

type EventListProps = {
    label: string,
    value: number,
}

type FormInputsField = {
    name: string,
    label: string,
    type: 'text' | 'email' | 'phone' | 'password' | 'passwordNotRequired' | 'date' | 'hours' | 'descriptions' | 'hex';
    placeholder: string,
    errors: any
}

export function AddHostScreen({
    navigation,
}: AddHostScreenProps) {
    const { user } = useSelector((state: any) => state.user.user);
    const [hostPicture, sethostPicture] = useState(null);
    const [hostPictureUrl, sethostPictureUrl] = useState(null);
    const [progress, setProgress] = useState(0.5);
    const [screen, setScreen] = useState(1);
    const [buttonTitle, setButtonTitle] = useState('CONTINUAR');
    const [events, setEvents] = useState<EventListProps[]>([]);
    const [modalEvents, setModalEvents] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    const [filtersSelectedCategories, setFiltersSelectedCategories] = useState([]);
    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [loadingHandleCreateHost, setLoadingHandleCreateHost] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const { control, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();


    const pickImageFrontCover = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });
        sethostPicture(result.uri);


        if (!result.cancelled) {
            setLoadingPhoto(true);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                sethostPictureUrl(response.result);
                setLoadingPhoto(false);
            }
        }
    };

    const onError = () => {
        if (filtersSelectedCategories.length === 0) {
            setError('idEvents', { type: 'validate', message: 'Selecione pelos menos um evento' });
        }
    };

    const handleContinue = () => {
        if (filtersSelectedCategories.length === 0) {
            setError('idEvents', { type: 'validate', message: 'Selecione pelos menos um evento' });
            return null;
        }
        if (screen <= 1) {
            setScreen(2);
            setProgress(1);
            setButtonTitle('FINALIZAR');
        } else {
            return null;
        }

        return null;
    };

    const handleOpenModalError = () => {
        setOpenModalError(!openModalError);
    };


    const handleNavigationHost = () => {
        isWeb ? navigation.reset({
            index: 0,
            routes: [{ name: 'Anfitriões' } as never],
        }) : navigation.navigate('Anfitriões' as never, {
            reload: true,
        } as never);
    };

    const handleCreateHost = async (data) => {
        setLoadingHandleCreateHost(true);
        hostCreate({
            ...data,
            photo: hostPictureUrl,
            idEvents: filtersSelectedCategories,
        }).then((response) => {
            if (response.data.status === 201) {
                setOpenModalSuccess(true);
            } else {
                setScreen(1);
                setProgress(0.5);
                setError('email', { type: 'validate', message: response.data.message });
            }
            setLoadingHandleCreateHost(false);
        }).catch(() => {
            setScreen(1);
            setProgress(0.5);
            setError('email', { type: 'validate', message: response.response.data.message });
        });
    };

    const goBack = () => {
        if (screen > 1) {
            setScreen(1);
            setProgress(0.5);
            setButtonTitle('CONTINUAR');
        } else {
            isWeb ? navigation.reset({
                index: 0,
                routes: [{ name: 'Anfitriões' } as never],
            }) : navigation.goBack();
        }
    };


    const getEvents = async () => {
        getAllEvents(user.id, user.type).then((response) => {
            delete response.result.list.main;
            const values = Object.values(response.result.list);
            // @ts-ignore

            const flatten = [].concat(...values);

            const formatted = flatten.map((e: any) => ({
                label: e.name,
                value: e.id,
            })) as EventListProps[];
            setEvents(formatted);
            setLoading(false);
        });
    };

    const handleOpenModal = () => {
        setModalEvents(!modalEvents);
    };

    function handleSelectedFilterCategories(filter: number) {
        if (!filtersSelectedCategories.includes(filter)) {
            setFiltersSelectedCategories([...filtersSelectedCategories, filter]);
        } else {
            setFiltersSelectedCategories((oldState) => oldState.filter((e) => e !== filter));
        }
    }

    function ErrorsComponent({ message }) {
        return <Errors message={message} />;
    }

    useEffect(() => {
        navigation.setOptions({
            title: 'Adicionar anfitrião',
        });
    }, [navigation]);

    useEffect(() => {
        clearErrors('idEvents');
    }, [

        filtersSelectedCategories,
    ]);

    useEffect(() => {
        getEvents();
    }, []);

    function renderInputs() {
        const inputs: FormInputsField[] = [
            {
                name: 'name',
                label: 'Nome',
                type: 'text',
                placeholder: 'Digite o nome do anfitrião',
                errors: errors.name,
            },
            {
                name: 'email',
                label: 'E-mail',
                type: 'email',
                placeholder: 'Digite o email do anfitrião',
                errors: errors.email,
            },
            {
                name: 'telephone',
                label: 'Telefone',
                type: 'phone',
                placeholder: '(12) 12345-6789',
                errors: errors.telephone,
            },
        ];

        return inputs.map((input) => (
            <>
                <InputHookForm
                    errors={input.errors}
                    name={input.name}
                    control={control}
                    label={input.label}
                    type={input.type}
                    placeholder={input.placeholder}
                />
                <ErrorMessage
                    errors={errors}
                    name={input.name}
                    render={ErrorsComponent}
                />
            </>
        ));
    }


    return (
        <>
            {loading ? <Loading /> : (
                <>
                    <S.Safe>
                        <S.Container>
                            <Header title='Criar Anfitrião' navigation={navigation} handleGoback={goBack} />
                            <S.ProgressContainer>
                                <Progress.Bar
                                    progress={progress}
                                    color={theme.colors.purpleDark}
                                    height={6}
                                    borderRadius={10}
                                    width={null}
                                />
                            </S.ProgressContainer>
                            <S.Content>
                                {screen === 1 ? (
                                    <>
                                        <Heading color='gray' size='intermediate'>
                                            Informações do anfitrião
                                        </Heading>
                                        <S.Form>
                                            {renderInputs()}
                                            <InputHookForm
                                                errors={errors.password}
                                                name='password'
                                                control={control}
                                                label='Senha'
                                                type='password'
                                                placeholder='Digite a senha do anfitrião'
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name='password'
                                                render={ErrorsComponent}
                                            />
                                            <S.ButtonPicker errors={errors.idEvents}>
                                                <S.ButtonPickerHeader onPress={handleOpenModal}>
                                                    <S.ButtonPickerText>Eventos</S.ButtonPickerText>
                                                    <S.ButtonPickerIcon name='down' />
                                                </S.ButtonPickerHeader>
                                                <S.EventsSelectedBox>
                                                    {events.map((e: EventListProps) => {
                                                        if (filtersSelectedCategories.includes(e.value as never)) {
                                                            return (
                                                                <S.FilterForm
                                                                    key={Number(e.value)}
                                                                    onPress={() => {
                                                                        handleSelectedFilterCategories(Number(e.value));
                                                                    }}
                                                                    selected={filtersSelectedCategories.includes(e.value as never)}
                                                                >
                                                                    <S.FilterText selected={filtersSelectedCategories.includes(e.value as never)}>{String(e.label)}</S.FilterText>
                                                                    <S.FilterFormIconClose name='close' />
                                                                </S.FilterForm>
                                                            );
                                                        }
                                                        return null;

                                                    })}
                                                </S.EventsSelectedBox>
                                            </S.ButtonPicker>
                                            <ErrorMessage
                                                errors={errors}
                                                name='idEvents'
                                                render={ErrorsComponent}
                                            />
                                        </S.Form>
                                        <S.ContainerButton>
                                            <Button
                                                title={buttonTitle}
                                                onClick={handleSubmit(handleContinue, onError)}
                                            />
                                        </S.ContainerButton>
                                    </>
                                ) : (
                                    <>
                                        <Heading color='gray' size='intermediate'>
                                            Foto do anfitrião
                                        </Heading>
                                        <S.ButtonImageEvent
                                            height={170}
                                            onPress={pickImageFrontCover}
                                        >

                                            {hostPicture !== null ? (
                                                <S.ImageButton
                                                    source={{ uri: hostPicture }}
                                                    resizeMode='cover'
                                                />
                                            ) : (
                                                <S.ImageButton
                                                    source={
                                                        isWeb ? webCover : mobileCover
                                                    }
                                                    resizeMode='cover'
                                                />
                                            )}
                                            <S.IconCan name='camera' />
                                            {loadingPhoto && <LoadingPhoto />}
                                        </S.ButtonImageEvent>
                                        <S.ContainerButton isFinal>
                                            <Button
                                                loading={loadingHandleCreateHost}
                                                title={buttonTitle}
                                                onClick={handleSubmit(handleCreateHost)}
                                                disabled={hostPictureUrl === null || loadingPhoto}
                                            />
                                        </S.ContainerButton>
                                    </>
                                )}
                            </S.Content>
                        </S.Container>
                        <Modal
                            animationType='slide'
                            transparent
                            visible={modalEvents}
                            onRequestClose={handleOpenModal}
                        >
                            <TouchableWithoutFeedback onPress={handleOpenModal}>
                                <S.ModalContainer>
                                    <S.ModalContent>
                                        <S.FilterBox>
                                            {events.map((e: EventListProps) => (
                                                <S.Filter
                                                    key={Number(e.value)}
                                                    onPress={() => {
                                                        handleSelectedFilterCategories(Number(e.value));
                                                    }}
                                                    selected={filtersSelectedCategories.includes(e.value as never)}
                                                >
                                                    <S.FilterText selected={filtersSelectedCategories.includes(e.value as never)}>{String(e.label)}</S.FilterText>
                                                </S.Filter>
                                            ))}
                                        </S.FilterBox>
                                        <S.ContainerButtonModal>
                                            <Button
                                                title='CONTINUAR'
                                                onClick={handleOpenModal}
                                            />
                                        </S.ContainerButtonModal>
                                    </S.ModalContent>
                                </S.ModalContainer>
                            </TouchableWithoutFeedback>
                        </Modal>
                    </S.Safe>
                    <AwesomeAlert
                        show={openModalError}
                        title='Erro ao criar anfitrião'
                        message='Ocorreu um erro ao criar o anfitrião, tente novamente!'
                        closeOnTouchOutside={false}
                        closeOnHardwareBackPress={false}
                        showConfirmButton
                        confirmText='OK'
                        confirmButtonColor='#98C828'
                        onConfirmPressed={handleOpenModalError}
                    />
                    <AwesomeAlert
                        show={openModalSuccess}
                        title='Anfitrião cadastrado'
                        message='Anfitrião cadastrado com sucesso.'
                        closeOnHardwareBackPress={false}
                        closeOnTouchOutside={false}
                        showConfirmButton
                        confirmText='OK'
                        confirmButtonColor='#98C828'
                        onConfirmPressed={handleNavigationHost}
                    />
                </>
            )}
        </>
    );
}
