import styled from 'styled-components/native';
import { Dimensions, Platform } from 'react-native';

import { FontAwesome } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';
import { defaultTheme as theme } from '../../../../../styles/theme';

const windowWidth = Dimensions.get('window').width;

const isWeb = Platform.OS === 'web';

export const ButtonSelected = styled.TouchableOpacity`
    width: 49%;
    height: 100%;
    flex-direction: ${windowWidth < 930 ? 'column' : 'row'};
    border: 1px solid ${theme.colors.grayLight};
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
`;
export const ButtonSelectedIcon = styled(FontAwesome)`
    font-size: 26px;
    color: ${theme.colors.gray};
`;
export const ButtonSelectedTitle = styled.Text`
    text-align: center;
    margin-left: ${isWeb && windowWidth > 930 ? '30px' : '0px'};
    color: ${theme.colors.gray};
    font-family: ${theme.font.bold};
    font-size: ${isWeb ? '12px' : `${RFValue(12)}px`};
    margin-top: ${isWeb && windowWidth > 930 ? '0px' : '10px'};
`;

export const ButtonSelectedActive = styled.TouchableOpacity`
    width: 49%;
    height: 100%;
    flex-direction: ${windowWidth < 930 ? 'column' : 'row'};
    border: 1px solid ${theme.colors.purpleDark};
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
    background-color: ${theme.colors.purpleDark};
`;
export const ButtonSelectedIconActive = styled(FontAwesome)`
    font-size: 26px;
    color: ${theme.colors.white};
`;
export const ButtonSelectedTitleActive = styled.Text`
    text-align: center;
    margin-left: ${isWeb && windowWidth > 930 ? '30px' : '0px'};
    margin-top: ${isWeb && windowWidth > 930 ? '0px' : '10px'};
    color: ${theme.colors.white};
    font-family: ${theme.font.bold};
    font-size: ${isWeb ? '12px' : `${RFValue(12)}px`};

`;
