import * as React from 'react';

import { createDrawerNavigator } from '@react-navigation/drawer';

import { useSelector } from 'react-redux';

// Events
import EventsScreen from '../../screens/Events/EventsScreen';
import { EventEditScreen } from '../../screens/Events/EventEditScreen';
import { EventDetailsScreen } from '../../screens/Events/EventDetails';
import { AddEventScreen } from '../../screens/Events/AddEventScreen';
import { ChartsEventScreen } from '../../screens/Events/ChartsEventScreen';

// Hosts
import { HostsScreen } from '../../screens/Hosts/HostsScreen';
import { HostEditScreen } from '../../screens/Hosts/HostEditScreen';
import { HostsDetailsScreen } from '../../screens/Hosts/HostsDetailsScreen';
import { AddHostScreen } from '../../screens/Hosts/AddHostScreen';

// QrCode
import { QrCodeScreen } from '../../screens/QrCode/QrCodeScreen';
import { QrCodeResultScreen } from '../../screens/QrCode/QrCodeResultScreen';

// Form
import { FormScreen } from '../../screens/Form/FormScreen';
import { FormPhotoIndividualScreen } from '../../screens/Form/FormPhotoIndividualScreen';
import { FormPhotoFamily } from '../../screens/Form/FormPhotoFamily';
import { FormVaccineCard } from '../../screens/Form/FormVaccineCard';
import { FormVaccineFamily } from '../../screens/Form/FormVaccineFamily';

// Guests
import { GuestsScreen } from '../../screens/Guests/GuestsScreen';
import { GuestDetailsScreen } from '../../screens/Guests/GuestDetails';
import { SignInScreen } from '../../screens/SignInScreen';
import { GuestEventDetails } from '../../screens/Guests/GuestEventDetails';
import { GuestEditScreen } from '../../screens/Guests/GuestEditScreen';

import { AdministratorsScreen } from '../../screens/Administrators/AdministratorsScreen';
import { AddAdministratorsScreen } from '../../screens/Administrators/AddAdministratorsScreen';

// Whatsapp

// import { Whatsapp } from '../../screens/Whatsapp';

import { DrawerCustom } from '../../components/DrawerCustom';

const { Navigator, Screen } = createDrawerNavigator();

export default function DrawerRoutes() {
    const user = useSelector((state: any) => state?.user.user);

    function renderRoutesNotUser() {
        if (user) {
            return null;
        }

        return (
            <>
                <Screen name='Login' component={SignInScreen} />
                <Screen name='FormScreen' component={FormScreen} />
                <Screen name='FormUniqueScreen' component={FormScreen} />
                <Screen
                    name='FormPhotoIndividualScreen'
                    component={FormPhotoIndividualScreen}
                />
                <Screen name='FormPhotoFamily' component={FormPhotoFamily} />
                <Screen name='FormVaccineCard' component={FormVaccineCard} />
                <Screen
                    name='FormVaccineFamily'
                    component={FormVaccineFamily}
                />
                <Screen
                    name='GuestEventDetails'
                    component={GuestEventDetails}
                />
            </>
        );
    }

    function renderRoutesWithUser() {
        if (!user || user?.user.permission === 'receptionist') {
            return null;
        }

        return (
            <>
                <Screen name='Home' component={EventsScreen} />
                <Screen
                    name='EventDetailsScreen'
                    component={EventDetailsScreen}
                />
                <Screen name='EventEditScreen' component={EventEditScreen} />
                <Screen name='AddEventScreen' component={AddEventScreen} />
                <Screen name='Anfitriões' component={HostsScreen} />
                <Screen name='AddHostScreen' component={AddHostScreen} />
                <Screen
                    name='HostsDetailsScreen'
                    component={HostsDetailsScreen}
                />
                <Screen name='HostsEditScreen' component={HostEditScreen} />
                <Screen name='GuestsScreen' component={GuestsScreen} />
                <Screen
                    name='GuestsDetailsScreen'
                    component={GuestDetailsScreen}
                />
                <Screen name='Qr Code' component={QrCodeScreen} />
                <Screen
                    name='QrCodeResultScreen'
                    component={QrCodeResultScreen}
                />
                <Screen name='FormScreen' component={FormScreen} />
                <Screen name='FormUniqueScreen' component={FormScreen} />
                <Screen
                    name='FormPhotoIndividualScreen'
                    component={FormPhotoIndividualScreen}
                />
                <Screen name='FormPhotoFamily' component={FormPhotoFamily} />
                <Screen name='FormVaccineCard' component={FormVaccineCard} />
                <Screen
                    name='FormVaccineFamily'
                    component={FormVaccineFamily}
                />
                <Screen
                    name='GuestEventDetails'
                    component={GuestEventDetails}
                />
                <Screen
                    name='ChartsEventScreen'
                    component={ChartsEventScreen}
                />
                {/* <Screen name='Whatsapp' component={Whatsapp} /> */}
                <Screen name='GuestEditScreen' component={GuestEditScreen} />
                <Screen
                    name='AdministratorsScreen'
                    component={AdministratorsScreen}
                />
                <Screen
                    name='AddAdministratorsScreen'
                    component={AddAdministratorsScreen}
                />
            </>
        );
    }

    function renderRoutesWithUserReceptionist() {
        if (user?.user.permission !== 'receptionist') {
            return null;
        }

        return (
            <>
                <Screen name='Home' component={EventsScreen} />
                <Screen
                    name='EventDetailsScreen'
                    component={EventDetailsScreen}
                />
                <Screen name='Qr Code' component={QrCodeScreen} />
                <Screen
                    name='QrCodeResultScreen'
                    component={QrCodeResultScreen}
                />
                <Screen name='GuestsScreen' component={GuestsScreen} />
                <Screen
                    name='GuestsDetailsScreen'
                    component={GuestDetailsScreen}
                />
            </>
        );
    }

    return (
        <Navigator
            drawerContent={DrawerCustom}
            screenOptions={{
                headerShown: false,
            }}
        >
            {renderRoutesNotUser()}
            {renderRoutesWithUser()}
            {renderRoutesWithUserReceptionist()}
        </Navigator>
    );
}
