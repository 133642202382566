import React from 'react';
import * as S from './styles';

interface ButtonOptions {
    type: 'pending' | 'approved' | 'disapproved' | 'delete' | 'presence';
    onPress: ()=> void,
    text?: string,
}

const label = {
    pending: 'Em espera',
    approved: 'Aprovar',
    disapproved: 'Reprovar',
    delete: 'Excluir',
    presence: 'Presença',
};

export function ButtonOptions({ type, text, ...rest }: ButtonOptions) {
    return (
        <S.Container type={type} {...rest}>
            <S.Text type={type}>{text || label[type]}</S.Text>
        </S.Container>
    );
}
