/* eslint-disable no-unused-vars */
import React from 'react';
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
    MenuOptionProps,
} from 'react-native-popup-menu';


import { Feather } from '@expo/vector-icons';

import * as S from './styles';

export type OptionProps = {
    title: string
    nameIcon: React.ReactNode,
    onSelect: () => void,
    typeIcon?: string
}

export type DropdownProps = {
    children?: React.ReactNode
    options: OptionProps[]
    icon: React.ReactNode,
}

export function Dropdown({ options, icon }: DropdownProps) {
    return (
        <Menu>
            <MenuTrigger>
                {icon}
            </MenuTrigger>
            <MenuOptions optionsContainerStyle={
                {
                    paddingVertical: 8,
                    paddingLeft: 8,
                    borderRadius: 4,
                    marginTop: 32,
                }
            }
            >
                {options.map((option: OptionProps) => {
                    return (
                        <MenuOption onSelect={option.onSelect} key={option.title}>
                            <S.OptionsMenuContainer>
                                {option.typeIcon === 'Feather' ? <S.OptionsMenuIconFeather size={14} name={option.nameIcon} /> : <S.OptionsMenuIcon name={option.nameIcon} size={14} />}
                                <S.OptionsMenuText>{option.title}</S.OptionsMenuText>
                            </S.OptionsMenuContainer>
                        </MenuOption>
                    );
                })}
            </MenuOptions>
        </Menu>
    );
}
