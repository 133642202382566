import styled from 'styled-components/native';

import { AntDesign } from '@expo/vector-icons';
import { Platform } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import Checkbox from 'expo-checkbox';
import { A } from '@expo/html-elements';
import { defaultTheme as theme } from '../../../../../styles/theme';

const isWeb = Platform.OS === 'web';

export const Container = styled.View``;

export const SelectInput = styled.TextInput``;

export const Forms = styled.ScrollView`
    margin-top: 40px;
`;

export const Form = styled.View`
    margin-top: 30px;
`;

export const Inputs = styled.View`
    margin-top: 16px;
    /* ${isWeb && 'margin-bottom: 82px'}
    ${!isWeb && `height: ${RFPercentage(50)}px`} */
`;

export const ContainerButton = styled.View`
    margin-top: ${isWeb ? '40px' : '20px'};
    margin-bottom: ${isWeb ? '50px' : '50px'};
    ${isWeb && 'width: 324px; align-self:center'}
`;

export const ModalContainer = styled.SafeAreaView`
    flex: 1;
    background-color: transparent;
    margin: 20px;
    align-items: center;
    justify-content: center;
`;

export const ModalBox = styled.ScrollView`
    background-color: #fff;
    height: 50%;
    width: 100%;
    border-radius: 5px ;
    padding: 30px 20px;
`;

export const ModalCloseButton = styled.TouchableOpacity`
    position: absolute;
    top: -15px;
    right: -5px;
`;

export const ModalCloseButtonIcon = styled(AntDesign)`
    font-size: 26px;
    color: ${theme.colors.black};
`;

export const ButtonOption = styled.TouchableOpacity`
    padding: 10px;
`;

export const ButtonText = styled.Text`
    font-family: ${theme.font.regular};
    font-size: 20px;
    color: ${theme.colors.gray};
`;

export const CheckboxContent = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
`;

export const CheckboxButton = styled(Checkbox).attrs({
    color: theme.colors.purpleDark,
})``;

export const CheckboxText = styled.Text`
    color: ${theme.colors.gray};
    font-family: ${theme.font.bold};
    font-size: ${isWeb ? '0.6rem' : '10px'};
`;

export const CheckboxLink = styled(A)`
    color: ${() => theme.colors.purpleDark};
    font-family: ${theme.font.bold};
    font-size: ${isWeb ? '0.6rem' : '10px'};
`;
