import styled from 'styled-components/native';
import {
    StatusBar,
    Platform,
    TouchableOpacityProps,
    Dimensions,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';

import { Feather, AntDesign } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../../styles/theme';

const isWeb = Platform.OS === 'web';
const windowWidth = Dimensions.get('window').width;

interface FilterProps {
    selected: boolean;
}

interface ButtonImageProps extends TouchableOpacityProps {
    height: number;
}

interface ButtonOptions {
    selected: boolean;
}

interface ButtonPickerProps {
    errors?: boolean;
}

export const Safe = styled.SafeAreaView`
    flex: 1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Container = styled.ScrollView`
    flex: 1;
    padding: ${isWeb && windowWidth > 650
        ? '4.875rem 8.265rem 2.437rem 8.265rem'
        : `${RFValue(35)}px ${RFValue(36)}px ${RFValue(0)}px ${RFValue(36)}px`};
`;

export const Content = styled.View`
    background-color: ${isWeb && windowWidth > 650 ? '#fff' : ''};
    border: ${isWeb && windowWidth > 650 ? 'solid 1px #B2BEC3' : 'none'};
    width: ${isWeb && windowWidth > 1100 ? '834px' : '100%'};
    border-radius: 10px;
    padding: ${isWeb && windowWidth > 650
        ? '85px 52px 50px 52px'
        : '20px 0px 50px 0px '};
    align-self: center;
`;

export const ProgressContainer = styled.View`
    margin-top: ${RFValue(16)}px;
    margin-bottom: ${RFValue(20)}px;
    width: 100%;
`;

export const Form = styled.ScrollView`
    margin-top: ${RFValue(20)}px;
    width: 100%;
`;

export const Inputs = styled.View``;

export const InputsInLine = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const VacinityContainer = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: ${RFValue(16)}px;
`;

export type ContainerButtonProps = {
    isFinal?: boolean;
};

export const ContainerButton = styled.View<ContainerButtonProps>`
    ${isWeb && 'width: 324px; align-self:center'}
    margin-bottom: ${(props) => (props.isFinal ? `${RFValue(40)}px` : `${RFValue(10)}px`)};
    padding: ${isWeb && windowWidth > 650 ? '0px' : `0px ${RFValue(36)}px`};
    margin-top: ${(props) => (props.isFinal && windowWidth < 650 ? '80%' : `${RFValue(82)}px`)};
`;

export const ButtonImageEvent = styled.TouchableOpacity<ButtonImageProps>`
    height: ${({ height }) => height}px;
    width: 170px;
    align-self: center;
    margin-top: ${RFValue(26)}px;
    margin-bottom: 5px;
`;

export const ButtonLabel = styled.Text`
    text-align: center;
    font-family: ${theme.font.regular};
    font-size: ${isWeb && windowWidth > 650 ? '0.75rem' : `${RFValue(12)}px`};
    margin-bottom: ${RFValue(10)}px;
    color: ${theme.colors.gray};
`;

export const IconCan = styled(Feather)`
    font-size: 18px;
    color: ${theme.colors.black};
    position: absolute;
    bottom: 12px;
    right: 12px;
`;

export const ImageButton = styled.Image`
    height: 100%;
    width: 100%;
    border-radius: 5px;
`;

export const ModalContainer = styled.View`
    background-color: rgba(0, 0, 0, 0.7);
    flex: 1;
    padding: 20px;
    align-items: center;
    justify-content: center;
`;

export const ModalContent = styled.View`
    background-color: #fff;
    max-height: 350px;
    min-height: 300px;
    border-radius: 4px;
    padding: 10px;
    max-width: 330px;
    width: 100%;
    position: absolute;
`;

export const FilterBox = styled.ScrollView.attrs({
    contentContainerStyle: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginBottom: 10,
    },
})``;

export const Filter = styled.TouchableOpacity<FilterProps>`
    border: 1px solid ${theme.colors.grayLight};
    padding: 6px 20px;
    border-radius: 15px;
    height: ${isWeb ? 'max-content' : '35px'};
    margin: 6px 2px;
    background-color: ${({ selected }) => (selected ? 'rgba(82, 9, 168, 0.5)' : 'transparent')};
`;

export const FilterForm = styled.TouchableOpacity<FilterProps>`
    border: 1px solid ${theme.colors.grayLight};
    padding: 6px 10px;
    border-radius: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: ${isWeb ? 'max-content' : '35px'};
    margin: 6px 2px;
    background-color: ${({ selected }) => (selected ? 'rgba(82, 9, 168, 0.5)' : 'transparent')};
`;

export const FilterFormIconClose = styled(AntDesign)`
    font-size: 15px;
    margin-left: 5px;
    color: ${theme.colors.white};
`;

export const FilterText = styled.Text<FilterProps>`
    font-family: ${theme.font.regular};
    font-size: ${isWeb && windowWidth > 650 ? '14px' : '14px'};
    color: ${({ selected }) => (selected ? theme.colors.purpleDark : theme.colors.gray)};
`;

export const ContainerButtonModal = styled.View<ContainerButtonProps>`
    ${isWeb && 'width: 200px; align-self:center'}
    margin-bottom: ${(props) => (props.isFinal ? `${RFValue(40)}px` : `${RFValue(10)}px`)};
    padding: ${isWeb && windowWidth > 650 ? '0px' : `0px ${RFValue(36)}px`};
    margin-top: ${RFValue(22)}px;
`;

export const ButtonPicker = styled.View<ButtonPickerProps>`
    border: 1px solid
        ${({ errors }) => (errors ? theme.colors.red : theme.colors.grayLight)};
    border-radius: 4px;
    margin-top: 12px;
`;

export const ButtonPickerHeader = styled.TouchableOpacity`
    padding: 17px 12px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

export const ButtonPickerIcon = styled(AntDesign)`
    font-size: 16px;
    color: ${theme.colors.grayLight};
`;

export const ButtonPickerText = styled.Text`
    font-family: ${theme.font.bold};
    color: ${theme.colors.gray};
    font-size: 12px;
`;

export const EventsSelectedBox = styled.ScrollView.attrs({
    contentContainerStyle: {
        flexWrap: 'wrap',
        flexDirection: 'row',
        paddingHorizontal: 10,
    },
})`
    max-height: 100px;
`;
