import styled from 'styled-components/native';

interface HeaderProps {
    marginBottom?:number
}

export const Header = styled.View<HeaderProps>`
    flex-direction: row;
    align-items: center;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '0px')}
`;
