import { AnyAction } from 'redux';
import { handleActions } from 'redux-actions'

import { LOGIN, LOGOUT } from './constants'

const initialState = {
    user: null,
}

const handlers = {
    [LOGIN]: (state = initialState, action: AnyAction) => ({
        ...state,
        user: action.payload,
    }),
    [LOGOUT]: () => {
        return initialState
    }
}

export default handleActions(handlers, initialState);
