import { Platform, StatusBar, Dimensions } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

const windowWidth = Dimensions.get('window').width;

const isWeb = Platform.OS === 'web';

export const Safe = styled.SafeAreaView`
     flex: 1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Container = styled.ScrollView`
    padding: ${windowWidth > 650 ? '4.875rem 8.265rem 2.437rem 8.265rem' : `${RFValue(35)}px ${RFValue(36)}px ${RFValue(0)}px ${RFValue(36)}px`};
`;
export const ProgressContainer = styled.View`
    /* margin-top: ${RFValue(16)}px; */
    margin-bottom: ${RFValue(20)}px;
    width: 100%;
`;

export const Options = styled.View`
`;
export const Content = styled.View`
    background-color: ${isWeb && windowWidth > 470 ? '#fff' : ''};
    border: ${isWeb && windowWidth > 470 ? 'solid 1px #B2BEC3' : 'none'};
    width: 100%;
    border-radius: 10px;
    padding: ${windowWidth > 470 ? '5.312rem 3.25rem 5.625rem 3.25rem' : '20px 0px 50px 0px'};
`;

export const Box = styled.View`
    padding: ${isWeb && windowWidth > 1000 ? '20px 8.265rem 0px 8.265rem' : '0px'};
`;

export const BoxButtons = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
