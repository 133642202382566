import React, { useState, useEffect } from 'react';
import { TouchableWithoutFeedback, Modal } from 'react-native';
import * as S from './styles';


export type InputSelectProps = {
    list: any[];
    initialValue?: any;
    setProps: any;
    errors?: any;
    label?: string;
}

export function InputSelect({ list, errors, setProps, initialValue, label }: InputSelectProps) {

    const [currentSelection, setCurrentSelection] = useState('');
    const [idSelection, setIdSelection] = useState(0);

    const [openPicker, setOpenPicker] = useState(false);

    function handleOpenPicker() {
        setOpenPicker(!openPicker);
    }

    function handleGetSelection(value: any, labelValue: string) {
        setProps(value);
        setCurrentSelection(labelValue);
        setIdSelection(value);
        setOpenPicker(!openPicker);
    }

    function renderList() {
        return list.map((e) => {
            const isSelected = Number(e.value) === Number(idSelection);

            function handleSelection() {
                handleGetSelection(e.value, e.label);
            }

            return (
                <S.ButtonOptions
                    selected={isSelected}
                    key={e.value}
                    onPress={handleSelection}
                >
                    <S.ButtonOptionsText selected={isSelected}>
                        {e.label}
                    </S.ButtonOptionsText>
                </S.ButtonOptions>
            );
        });
    }

    useEffect(() => {
        if (initialValue) {
            const currentValue = list.find((e) => {
                return String(e.value) === String(initialValue);
            });
            setCurrentSelection(currentValue?.label);
            setIdSelection(currentValue?.value);
            setProps(currentValue?.value);
        } else if (Number(initialValue) === 0) {
            setCurrentSelection(label);
            setIdSelection(0);
            setProps(0);
        } else {
            setCurrentSelection(list[0].label || '');
            setIdSelection(0);
        }
    }, [list]);

    return (
        <S.Container>
            <S.ButtonPicker onPress={handleOpenPicker} errors={errors}>
                <S.ButtonPickerText>{currentSelection}</S.ButtonPickerText>
                <S.ButtonPickerIcon name='down' />
            </S.ButtonPicker>
            <Modal animationType='fade' onRequestClose={handleOpenPicker} transparent visible={openPicker}>
                <TouchableWithoutFeedback onPress={handleOpenPicker}>
                    <S.ModalContainer>
                        <S.Content>
                            {renderList()}
                        </S.Content>
                    </S.ModalContainer>
                </TouchableWithoutFeedback>
            </Modal>
        </S.Container>
    );
}
