/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import * as Progress from 'react-native-progress';
import * as ImagePicker from 'expo-image-picker';
import AwesomeAlert from 'react-native-awesome-alerts';
import { Platform, Dimensions } from 'react-native';
import * as Linking from 'expo-linking';
import * as S from './styles';
import { Header } from '../../../components/Header';
import { Heading } from '../../../components/Heading';
import { defaultTheme as theme } from '../../../styles/theme';

import vaccineCard from '../../../assets/images/event/webInvite.png';
import { uploadFile } from '../../../services/toolsApi';
import { Button } from '../../../components/Button';
import { registerGuest } from '../../../services/events';
import { LoadingPhoto } from '../../../components/LoadingPhoto';

const isWeb = Platform.OS === 'web';


export function FormVaccineCard({ route, navigation }) {
    // eslint-disable-next-line no-unused-vars
    const [progress, setProgress] = useState(1);
    const [vaccinePhoto, setVaccinePhoto] = useState(null);
    const [vaccinePhotoUrl, setVaccinePhotoUrl] = useState(null);
    const [vaccinePhotoLoading, setVaccinePhotoLoading] = useState(false);
    const [openModalNotification, setOpenModalNotification] = useState(false);
    const [titleModalNotification, setTitleModalNotification] = useState('');
    const [messageModalNotification, setMessageModalNotification] = useState('');
    const [loading, setLoading] = useState(false);
    const windowWidth = Dimensions.get('window').width;
    const data = route.params;

    const handleOpenModalNotification = () => {
        setOpenModalNotification(!openModalNotification);
    };

    const pickImageHostImage = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
            base64: true,
        });
        if (!result.cancelled) {
            setVaccinePhoto(result.uri);
            setVaccinePhotoLoading(true);
            const file = {
                name: result.uri.split('/').pop(),
                type: 'image/jpeg',
                uri: result.uri,
            };
            const response = await uploadFile(file);
            if (response.status === 200) {
                setVaccinePhotoLoading(false);
                setVaccinePhotoUrl(response.result);
                return response.result;
            }
            return false;
        }
        return '';
    };

    const handleNavigationForm = () => {
        Linking.openURL('http://confirma.puzli.com.br/ok');
    };

    async function handleRegister() {
        const { hash } = data.event;
        const dataRegister = {
            ...data,
            photo: data.guetsPhotoUrl,
            vaccinationCard: vaccinePhotoUrl,
        };
        delete dataRegister.event;
        try {
            setLoading(true);
            const response = await registerGuest([dataRegister], hash);

            if (response.status === 200) {
                setLoading(false);
                setTitleModalNotification('Convidado cadastrado!');
                setMessageModalNotification('Aguarde, pois em breve você receberá o seu QR Code individual no whatsapp cadastrado. Obrigado!');
                handleOpenModalNotification();
            } else {
                setLoading(false);
                setTitleModalNotification('Erro ao cadastrar!');
                setMessageModalNotification(response.message);
                handleOpenModalNotification();
            }
            return true;
        } catch (e) {
            setLoading(false);
            setTitleModalNotification('Erro ao cadastrar!');
            setMessageModalNotification(e.message);
            handleOpenModalNotification();
            return false;
        }
    }

    const goBack = () => {
        navigation.navigate('FormPhotoIndividualScreen', { dataInvite: data });
    };

    useEffect(() => {
        navigation.setOptions({
            title: 'Cateira de vacinação',
        });
    }, [navigation]);

    return (
        <>
            <S.Safe>
                <S.Container>
                    <Header title={`Formulário - ${data.event?.name}`} navigation={navigation} marginBottom={16} handleGoback={goBack} />
                    <S.ProgressContainer>
                        <Progress.Bar progress={progress} color={theme.colors.purpleDark} height={6} borderRadius={10} width={null} />
                    </S.ProgressContainer>
                    <S.Box>
                        <S.Content>
                            <S.BoxPhoto>
                                <Heading color='gray' size='intermediate'>Cartão de vacinação</Heading>
                                <S.ButtomPhoto disabled={vaccinePhotoLoading} onPress={pickImageHostImage}>
                                    <S.ButtonPhotoImg source={vaccinePhoto ? { uri: vaccinePhoto } : vaccineCard} />
                                    <S.IconCan name='camera' />
                                    {vaccinePhotoLoading && <LoadingPhoto />}
                                </S.ButtomPhoto>
                                <S.ButtonLabel>Insira uma foto</S.ButtonLabel>
                            </S.BoxPhoto>
                            {isWeb && windowWidth > 470 && (
                                <S.ContainerButton position='fixed'>
                                    <Button disabled={vaccinePhoto === null} title='FINALIZAR' onClick={handleRegister} />
                                </S.ContainerButton>
                            )}
                        </S.Content>
                    </S.Box>
                </S.Container>
                {isWeb && windowWidth < 470 && (
                    <S.ContainerButton position='fixed'>
                        <Button loading={loading} disabled={vaccinePhotoUrl === null} title='FINALIZAR' onClick={handleRegister} />
                    </S.ContainerButton>
                )}
            </S.Safe>
            <AwesomeAlert
                show={openModalNotification}
                title={titleModalNotification}
                message={messageModalNotification}
                closeOnTouchOutside={false}
                closeOnHardwareBackPress={false}
                showConfirmButton
                confirmText='OK'
                confirmButtonColor='#98C828'
                onConfirmPressed={handleNavigationForm}
            />
        </>
    );
}
