import { api } from './api';
import { getToken } from './functions';
import { ErrorAlert } from '../components/ErrorAlert';

export async function verifyCode(idEvent: number, hashCode: string, idAdm: number) {
    const token = await getToken();
    try {
        const { data } = await api.get('/qrCode', {
            headers: {
                'x-access-token': token,
            },
            params: {
                idEvent,
                hash: hashCode,
                idAdm,
            },
        });
        if (data.status !== 200 && data.status !== 403) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}

export async function historyCodes(idEvent: number) {
    const url = idEvent === 0 ? 'qrCode/history' : `qrCode/history?idEvent=${idEvent}`;
    const token = await getToken();
    try {
        const { data } = await api.get(url, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (e) {
        return e;
    }
}
