import * as React from 'react';
/* eslint-disable-next-line */
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
    <Svg
        xmlns='http://www.w3.org/2000/svg'
        width={16.608}
        height={16.254}
        {...props}
    >
        <G
            fill='none'
            stroke='#98c828'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
        >
            <Path
                data-name='Caminho 83100'
                d='M15.193 7.515v.646a7 7 0 1 1-4.151-6.4'
            />
            <Path data-name='Caminho 83101' d='m15.193 2.91-8.75 8.759-2.625-2.625' />
        </G>
    </Svg>
);

export default SvgComponent;
