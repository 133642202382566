import styled from 'styled-components/native';
import { defaultTheme as theme } from '../../../../../styles/theme';


interface ButtonOptions {
    type: 'pending' | 'approved' | 'disapproved' | 'delete' | 'presence';
}

export const Container = styled.TouchableOpacity<ButtonOptions>`
    padding: 6px 20px;
    border: 0.5px solid;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    ${(props) => props.type === 'approved' && `border-color: ${theme.colors.green}`}
    ${(props) => props.type === 'disapproved' && `border-color: ${theme.colors.red}`}
    ${(props) => props.type === 'delete' && `background-color: ${theme.colors.red}`}
    ${(props) => props.type === 'delete' && `border-color: ${theme.colors.red}`}
    ${(props) => props.type === 'pending' && `border-color: ${theme.colors.chart.guests.defaulters}`}
`;

export const Text = styled.Text<ButtonOptions>`
    font-size: 10px;
    font-family: ${theme.font.regular};
    ${(props) => props.type === 'approved' && `color: ${theme.colors.green}`}
    ${(props) => props.type === 'disapproved' && `color: ${theme.colors.red}`}
    ${(props) => props.type === 'delete' && `color: ${theme.colors.white}`}
    ${(props) => props.type === 'pending' && `color: ${theme.colors.chart.guests.defaulters}`}
`;
