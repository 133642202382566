import React from 'react';

import * as S from './styles';

import FamilyIcon from '../../../../../assets/images/svgs/family';
import UserIcon from '../../../../../assets/images/svgs/formUser';

interface ButtonSelectedProps{
    title: string;
    icon: string;
    selected: boolean;
    onPress:()=> void
}

export function ButtonSelected({ title, icon, selected, onPress }:ButtonSelectedProps) {
    return (
        <>
            {!selected ? (
                <S.ButtonSelected onPress={onPress}>
                    {icon === 'user' ? <UserIcon isActive={selected} /> : <FamilyIcon isActive={selected} />}
                    <S.ButtonSelectedTitle>{title}</S.ButtonSelectedTitle>
                </S.ButtonSelected>
            ) : (
                <S.ButtonSelectedActive onPress={onPress}>
                    {icon === 'user' ? <UserIcon isActive={selected} /> : <FamilyIcon isActive={selected} />}
                    <S.ButtonSelectedTitleActive>{title}</S.ButtonSelectedTitleActive>
                </S.ButtonSelectedActive>
            )}
        </>
    );
}
