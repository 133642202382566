import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { CardUserInfo } from './components/CardUserInfo';
import { Loading } from '../../../components/Loading';
import { ListEmpty } from '../../../components/ListEmpty';
import { uniqueGuest } from '../../../services/guests';

interface UniqueGuest {
    name_color: string;
    name?: string;
    email?: string;
    photo?: string;
    telephone?: string;
    nameEvent?: string;
    descriptionEvent?: string;
    dateEvent?: string;
    timeEvent?: string;
    link_group_users?:string;
    link_unique_user?:string;
    invitation?: string;
    cover: string;
    hex: string;
}

export function GuestEventDetails({ route }) {
    const { hash } = route.params;
    const [infos, setInfos] = useState<UniqueGuest>({} as UniqueGuest);
    const [loading, setLoading] = useState(true);
    const [userPending, setUserPending] = useState(false);

    const imageUser = { uri: infos.photo };
    const imageLogo = { uri: 'https://puzli.nyc3.digitaloceanspaces.com/Documentos/marca_pz-01.png' };

    const getUniqueGuest = async () => {
        setLoading(true);
        try {
            const response = await uniqueGuest(hash);
            if (response.result) {
                setInfos(response.result);
                setLoading(false);
                return null;
            }

            setUserPending(true);
            setLoading(false);
        } catch (e) {
            setUserPending(true);
            setLoading(false);
            return null;
        }
        return null;
    };

    useEffect(() => {
        getUniqueGuest();
    }, []);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    {userPending ? (
                        <ListEmpty message='Seu convite não existe, ou ainda não foi aprovado.' />
                    ) : (
                        <S.Safe hex={infos.hex}>
                            <S.ImageEvent source={{ uri: infos.cover }} />
                            <S.Content>
                                {imageUser.uri ? (
                                    <S.ImageUser source={imageUser} />
                                ) : (
                                    <S.ImageUser
                                        source={imageLogo}
                                        resizeMode='cover'
                                    />
                                )}
                                <S.NameUser color={infos.name_color}>{infos.name}</S.NameUser>
                                <CardUserInfo title='Nome' info={infos.name} />
                                {infos.telephone && (
                                    <CardUserInfo
                                        title='Telefone'
                                        info={infos.telephone}
                                    />
                                )}
                            </S.Content>
                        </S.Safe>
                    )}
                </>
            )}
        </>
    );
}
