import React, { useState } from 'react';
import { Platform, Dimensions } from 'react-native';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Errors } from '../../../../../components/Errors';
import { InputHookForm } from '../../../../../components/InputHookform';
import { Button } from '../../../../../components/Button';
import { Heading } from '../../../../../components/Heading';
import { registerGuest } from '../../../../../services/events';
import * as S from './styles';

interface FormIndivual {
    eventId: string;
    navigation: any;
    event: any;
    handleOpenModalNotification: () => void;
    setTitleModalNotification: (text: string) => void;
    setMessageModalNotification: (text: string) => void;
}

const isWeb = Platform.OS === 'web';

export function FormIndividual(props : FormIndivual) {
    const { navigation, event, handleOpenModalNotification, setTitleModalNotification, setMessageModalNotification } = props;

    const [loading, setLoading] = useState(false);

    const { control, handleSubmit, reset, formState: { errors } } = useForm();
    const windowWidth = Dimensions.get('window').width;

    async function saveGuests(data: any) {
        setLoading(true);
        try {
            const response = await registerGuest([data], event.hash);
            if (response.status === 200) {
                setLoading(false);
                setTitleModalNotification('Convidado cadastrado!');
                setMessageModalNotification('Aguarde, pois em breve você receberá o seu QR Code individual no whatsapp cadastrado. Obrigado!');
                handleOpenModalNotification();
                reset();
            } else {
                setLoading(false);
                setTitleModalNotification('Erro ao cadastrar!');
                setMessageModalNotification(response.message);
                handleOpenModalNotification();
                reset();
            }
        } catch (e: any) {
            setLoading(false);
            setTitleModalNotification('Erro ao cadastrar!');
            setMessageModalNotification(e.message);
            handleOpenModalNotification();
            reset();
        }
    }

    async function handleContinue(data) {
        const dataInvite = {
            ...data,
            isMain: true,
            event,
        };
        if (event.is_photo_guests) {
            navigation.navigate('FormPhotoIndividualScreen', {
                dataInvite,
            });
        } else if (!event.is_photo_guests && event.is_vaccination) {
            navigation.navigate('FormVaccineCard', {
                ...dataInvite,
                guetsPhoto: null,
            });
        } else {
            saveGuests(dataInvite);
        }
    }

    function ErrorsComponent({ message }) {
        return <Errors message={message} />;
    }

    return (
        <S.Container>
            <S.Form>
                <Heading color='gray' size='intermediate'>
                    Informações Pessoais
                </Heading>
                <S.Inputs>
                    <InputHookForm
                        errors={errors.name}
                        name='name'
                        control={control}
                        label='Nome Completo'
                        type='text'
                        placeholder='Digite seu nome completo'
                    />
                    <ErrorMessage
                        errors={errors}
                        name='name'
                        render={ErrorsComponent}
                    />
                    <InputHookForm
                        errors={errors.telephone}
                        name='telephone'
                        control={control}
                        label='Whatsapp'
                        type='phone'
                        placeholder='Digite seu número'
                    />
                    <ErrorMessage
                        errors={errors}
                        name='telephone'
                        render={ErrorsComponent}
                    />
                    {event.is_email ? (
                        <>
                            <InputHookForm
                                errors={errors.email}
                                name='email'
                                control={control}
                                label='Email'
                                type='email'
                                placeholder='Digite seu email'
                            />
                            <ErrorMessage
                                errors={errors}
                                name='email'
                                render={ErrorsComponent}
                            />
                        </>
                    ) : null}
                    {event.is_rg ? (
                        <>
                            <InputHookForm
                                errors={errors.rg}
                                name='rg'
                                control={control}
                                label='RG'
                                type='number'
                                placeholder='Digite seu RG'
                            />
                            <ErrorMessage
                                errors={errors}
                                name='rg'
                                render={ErrorsComponent}
                            />
                        </>
                    ) : null}
                    {event.is_cpf ? (
                        <>
                            <InputHookForm
                                errors={errors.cpf}
                                name='cpf'
                                control={control}
                                label='CPF'
                                type='cpf'
                                placeholder='Digite seu CPF'
                            />
                            <ErrorMessage
                                errors={errors}
                                name='cpf'
                                render={ErrorsComponent}
                            />
                        </>
                    ) : null}
                    {event.is_address ? (
                        <>
                            <InputHookForm
                                errors={errors.address}
                                name='address'
                                control={control}
                                label='Endereço'
                                type='text'
                                placeholder='Digite seu endereço'
                            />
                            <ErrorMessage
                                errors={errors}
                                name='address'
                                render={ErrorsComponent}
                            />
                        </>
                    ) : null}
                    {event.is_number_shoe ? (
                        <>
                            <InputHookForm
                                errors={errors.numberShoe}
                                name='numberShoe'
                                control={control}
                                label='Número da sandália'
                                type='number'
                                placeholder='Digite o número da sandália'
                            />
                            <ErrorMessage
                                errors={errors}
                                name='numberShoe'
                                render={ErrorsComponent}
                            />
                        </>
                    ) : null}
                    {event.is_profession ? (
                        <>
                            <InputHookForm
                                errors={errors.profession}
                                name='profession'
                                control={control}
                                label='Profissão'
                                type='text'
                                placeholder='Qual sua profissão?'
                            />
                            <ErrorMessage
                                errors={errors}
                                name='profession'
                                render={ErrorsComponent}
                            />
                        </>
                    ) : null}
                    {event.is_office ? (
                        <>
                            <InputHookForm
                                errors={errors.office}
                                name='office'
                                control={control}
                                label='Cargo'
                                type='text'
                                placeholder='Qual seu cargo?'
                            />
                            <ErrorMessage
                                errors={errors}
                                name='office'
                                render={ErrorsComponent}
                            />
                        </>
                    ) : null}
                    {event.is_business ? (
                        <>
                            <InputHookForm
                                errors={errors.business}
                                name='business'
                                control={control}
                                label='Nome da empresa'
                                type='text'
                                placeholder='Qual nome da empresa?'
                            />
                            <ErrorMessage
                                errors={errors}
                                name='business'
                                render={ErrorsComponent}
                            />
                        </>
                    ) : null}
                    <S.CheckboxContent>
                        {/* <S.CheckboxText>
                            Ao clicar no botão "Continuar" abaixo, você aceita os Termos e Condições e reconhece que seus dados serão utilizados conforme descrito na
                            <S.CheckboxLink
                                href='https://www.puzli.com.br/lgpd'
                                target='_blank'
                            >
                                {' Política de Privacidade '}
                            </S.CheckboxLink>
                            da Puzli.
                        </S.CheckboxText> */}
                        <S.CheckboxText>
                            Ao clicar no botão "Continuar" abaixo, declaro a veracidade das informações prestadas e tenho ciência dos termos e condições na
                            <S.CheckboxLink
                                href='https://puzli.com.br/lgpd'
                                target='_blank'
                            >
                                {' Política de Privacidade '}
                            </S.CheckboxLink>
                            da Puzli, prestando meu consentimento inequívoco em conformidade com o teor da Lei n 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados).
                        </S.CheckboxText>
                    </S.CheckboxContent>
                </S.Inputs>
                {isWeb && windowWidth > 470 && (
                    <S.ContainerButton>
                        <Button
                            loading={loading}
                            title='CONTINUAR'
                            onClick={handleSubmit(handleContinue)}
                        />
                    </S.ContainerButton>
                )}
            </S.Form>
            {isWeb && windowWidth < 470 && (
                <S.ContainerButton>
                    <Button
                        loading={loading}
                        title='CONTINUAR'
                        onClick={handleSubmit(handleContinue)}
                    />
                </S.ContainerButton>
            )}
        </S.Container>
    );
}
