import styled from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';
import { FlatList, Platform, StatusBar, Dimensions } from 'react-native';

import { defaultTheme as theme } from '../../../styles/theme';
import { QrCodeItemProps } from '../../../components/QrCodeItem';

const windowWidth = Dimensions.get('window').width;

const isWeb = Platform.OS === 'web';

export const Shadow = Platform.OS === 'android' && {
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 8,
    },
    shadowOpacity: 0.46,
    shadowRadius: 11.14,

    elevation: 17,
};

export const ContainerEmptyMessage = styled.View`
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
    align-self: center;
`;

export const Safe = styled.SafeAreaView`
    flex: 1;
    z-index: 9;
    background-color:${theme.colors.white};
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Container = styled.View`
    padding: ${isWeb && windowWidth > 650 ? '5.062rem 8.625rem 2.312rem 8.625rem' : `${RFValue(37)}px ${RFValue(27)}px ${RFValue(10)}px ${RFValue(37)}px}`};
    flex:1;
`;

export const Top = styled.TouchableOpacity`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 300px;
    align-self: center;
    margin-top: ${isWeb && windowWidth > 650 ? '-3rem' : '0'};
`;

export const Header = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;
`;

export const ModalContainer = styled.View`
    background-color: rgba(0,0,0, 0.9);
    flex:1;
    padding: 20px;
    align-items: center;
    justify-content: center;
`;

export const InputContainerSelect = styled.View`

    ${isWeb && windowWidth > 650 ? 'width:500px; align-self:center' : 'padding-right: 15px'}

`;

export const Scroll = styled.ScrollView`
    flex: 1;
    width: 100%;
    height: 100%;
    margin-top: 20px;
`;

export const QrCode = styled.Image`
    width: 75px;
    height: 75px;
`;

export const ReadQrCode = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 17px;
`;

export const SmallQrCode = styled.Image`
    width: 15px;
    height: 15px;
    margin-right: 5px;
`;

export const QrCodeBox = styled.View`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 200px;
    overflow: hidden;
    border-radius: 30px;
    margin-bottom: 10px;
`;

export const EventsList = styled(FlatList as new () => FlatList<QrCodeItemProps>).attrs(
    Platform.OS === 'web' && windowWidth > 650 ? {
        numColumns: 2,
        showsVerticalScrollIndicator: false,
        contentContainerStyle: {},
        columnWrapperStyle: {
            flex: 1,
            justifyContent: 'space-between',
        },
    } : {
        showsVerticalScrollIndicator: false,
        numColumns: 1,
        contentContainerStyle: {},
    },
)`
    margin-top: 30px;
    margin-top: 30px;
    padding-right: 15px;
`;

export const BarCodeContainer = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
`;

export const barCode = {
    height: 450,
    width: 400,
    borderRadius: 20,
};

export const PickerContainer = styled.View`
    width: 100%;
    border-width: 1px;
    border-color: ${theme.colors.lightGray};
    border-radius: 8px;
    margin-top: 10px;
`;

export const ReadQrCodeText = styled.Text`
    font-size: 16px;
    font-weight: bold;
    color: ${theme.colors.purpleDark};
`;

export const InputSearch = styled.TextInput`
    font-size: 12px;
    font-family: ${theme.font.regular};
    margin: 10px 0px 20px 0px;
    padding: 17px 12px;
    background-color: ${theme.colors.white};
    border-radius: 5px;
    border: 1px solid ${theme.colors.grayLight};
    outline-width: 0;
`;
