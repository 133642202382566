import { ActivityIndicator } from 'react-native';
import styled from 'styled-components/native';


interface ContainerProps{
    marginRight?:number
}

export const Container = styled.View<ContainerProps>`
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    position: absolute;
    height: 100%;
    width: 100%;
`;

export const Spinner = styled(ActivityIndicator)``;
