import React from 'react';
import * as S from './styles';

interface LoadingProps{
    marginRight?:number
}
export function Loading({ marginRight }:LoadingProps) {
    return (
        <S.Container marginRight={marginRight}>
            <S.Spinner color='#5209A8' size='large' />
        </S.Container>
    );
}
