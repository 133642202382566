import { defaultTheme as theme } from '../../../styles/theme';

export interface DataProps {
    guests: {
        defaulters: number;
        defaultersTotal: number;
        gift: number;
        giftTotal: number;
        total: number;
        awaitingTotal: number;
        awaiting: number;
    };
    scans: {
        correct: number;
        correctTotal: number;
        incorrect: number;
        incorrectTotal: number;
        refused: number;
        refusedTotal: number;
        total: number;
    };
    presence: {
        presence: number;
        noPresence: number;
        nullPresence: number;
        total: number;
        presenceTotal: number;
        noPresenceTotal: number;
        nullPresenceTotal: number;
    };
}

interface SvgProps {
    fill: string;
}

export interface PieProps {
    value: number;
    svg: SvgProps;
}

const chartColor = {
    colorEmpty: theme.colors.chart.empty,
    guests: [
        theme.colors.chart.guests.defaulters,
        theme.colors.chart.guests.gift,
        theme.colors.chart.guests.awaiting,
        theme.colors.chart.empty,
    ],
    scans: [
        theme.colors.chart.scans.correct,
        theme.colors.chart.scans.incorrect,
        theme.colors.chart.scans.refused,
        theme.colors.chart.empty,
    ],
    presence: [
        theme.colors.chart.presence.presence,
        theme.colors.chart.presence.noPresence,
        theme.colors.chart.presence.nullPresence,
        theme.colors.chart.empty,
    ],
};

export function graphicsGeneration(data: any, type: 'guests' | 'scans' | 'presence') {
    const pieChartInfo = data.map((el, index) => {
        const colors = chartColor[type];
        const infos = { value: el, svg: { fill: colors[index] } };
        return infos;
    });

    return pieChartInfo;
}

export const calculePercentage = (total: number, value: number) => {
    const valueTotal = total || 0;
    const valueCalc = value || 0;
    const percentage = (valueCalc / valueTotal) * 100;
    return Number(percentage.toFixed(2));
};
