import styled from 'styled-components/native';
import { Platform, StatusBar, Dimensions } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { defaultTheme as theme } from '../../../styles/theme';

const isWeb = Platform.OS === 'web';
const windowWidth = Dimensions.get('window').width;

export const Safe = styled.SafeAreaView`
    flex: 1;
    ${StatusBar.currentHeight && `padding-top:${StatusBar.currentHeight}px`};
`;

export const Container = styled.View`
    flex: 1;
    max-width: 100%;
    width: 100%;
`;

export const Content = styled.View`
    flex: 1;
    display: flex;
    /* padding: ${isWeb && windowWidth > 650 ? '4.875rem 0px 0px 8.265rem' : `${RFValue(40)}px 0px 0px ${RFValue(37)}px`} ; */
`;

export const GalleryContainer = styled.View``;

export const Header = styled.View`
     display: flex;
    flex-direction: row;
    align-items: center;
    ${windowWidth < 650 && 'justify-content: space-between'}
    padding: ${isWeb && windowWidth > 650 ? '4.875rem 0px 0px 8.265rem' : `${RFValue(40)}px 0px 0px ${RFValue(37)}px`} ;
`;

export const Exit = styled(MaterialIcons)`
    font-size: 26px;
    color: ${theme.colors.grayLight};
    margin-right: ${isWeb && windowWidth > 650 ? '4.875rem' : `${RFValue(20)}px`};
`;

export const Title = styled.Text`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.gray};
    margin-top: ${theme.spacings.small};
    margin-bottom: ${theme.spacings.medium};
    font-family: ${theme.font.regular};
    margin-left: ${isWeb && windowWidth > 650 ? '8.265rem' : `${RFValue(37)}px`} ;

`;

export const TitleWithFilter = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: ${Platform.OS === 'web' ? 'flex-start' : 'space-between'};
    padding-right: 15px;
`;

export const IconFilter = styled.TouchableOpacity`
    padding-left: ${theme.spacings.small};
`;

export const AddButton = styled.TouchableOpacity`
    position: absolute;
    right: ${isWeb && windowWidth > 650 ? '140px' : '37px'};
    bottom: ${isWeb && windowWidth > 650 ? '62px' : '20px'};
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: ${theme.colors.purpleDark};
    display: flex;
    justify-content: center;
    align-items: center;
    elevation: 3;
`;

export const BoxFilterButton = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const ButtonFilterContainer = styled.View`
   ${windowWidth > 650 ? 'align-self: flex-start' : 'align-self: flex-end'}
   /* top: -5px; */
   /* position: absolute;
   z-index: 1; */
`;

export const ButtonFilter = styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    /* ${windowWidth > 650 && 'margin-left: 27px;'} */
    margin-right: ${isWeb && windowWidth > 650 ? '4.875rem' : `${RFValue(20)}px`};
`;
export const ButtonFilterIcon = styled(Feather)`
    font-size: 26px;
    color: ${theme.colors.grayLight};
`;


export const EventHeader = styled.View`
    flex-direction: row;
    align-items: center;
    ${windowWidth < 650 && 'justify-content: space-between;'}
    margin-bottom: 20px;
    padding-left: ${isWeb && windowWidth > 650 ? '8.265rem' : `${RFValue(37)}px`} ;
`;
